import {
  Card,
  CardContent,
  Typography,
  Avatar,
  responsiveFontSizes,
} from "@mui/material";
import Grid from "@mui/material/Grid";

import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import React, { useState, useEffect } from "react";

import FormControlLabel from "@mui/material/FormControlLabel";

import Button from "@mui/material/Button";

import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import config from "../../Common/config";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const AddNewContact = ({ addTagname, addTagID,cusdetails,onClose }) => {
  useEffect(() => {
    debugger;
    loadCustomer();
  }, []);
  const [checkedItems, setCheckedItems] = useState([]);

  const [customeridaddnew, setcustomeridaddnew] = useState([]);
  const [Tagname, setTagname] = useState(addTagname);
  const [TagID, setTagID] = useState(addTagID);
  const [cusName, setcusName] = useState([null]);
  const [Demp, setDemp] = useState([]);
  const [stateidaddnew, setstateidaddnew] = useState();
  const[addstatus,setaddstatus]=useState("ADD");
  const [prevCustomerIds, setPrevCustomerIds] = useState([]);
  const [SelectedFirstNames, setSelectedFirstNames] = useState([]);

  const [CustomerDeatils, setCustomerDetails] = useState([]);
  // const [Firstname, setFirstname] = useState([]);

  const [selectedaddcustomer, setselectedaddcustomer] = useState([]);

  const [CustomerIds, setCustomerIds] = useState([]);
  const loadCustomer = () => {
    debugger;

    axios.post(config.apiLink + "Dashboard/SearchCustomer", {}, {}).then(
      (response) => {
        debugger;
        var Temp = response.data;
        setcusName([Temp][0]["Search-Customer"]);
      },

      (error) => {
        console.log(error);
      }
    );
  };

  const addcustomerdeatils = (customerIds) => {
    debugger;
    let customerId = customerIds.CUSTOMERID;
    axios
      .post(config.apiLink + "Dashboard/CustomerDetailsnew", {
        customerID: customerId,
      })
      .then((response) => {
        debugger;
        console.log(response.data);
        var temp = response.data;
        const updatedCustomerDetails = temp["CustomerDetailsnew"].map(customer => {
          const match = cusdetails.find(cus => cus.CUST_CONTACTID === customer.CUST_CONTACTID  && cus.CONTACTID === customer.CONTACTID );
          return {
            ...customer,
            ChekboxStatus: match ? true : false
          };
        });
        setCustomerDetails(updatedCustomerDetails);
      })
      .catch((error) => {});
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const handlechangecustdd = (newValue) => {
    debugger;
    if (newValue !== null) {
      // Extract the value you need from the selected option object
      const newValueId = newValue.CUSTOMERID;
      setselectedaddcustomer(newValue); // Update the state directly with the newValue
      console.log(newValue);
      // if (newValueId.length !== 0) {
      //   debugger;
      //   var demp = [];
      //   debugger;
      //   demp.push({ customerid: newValueId });}
      addcustomerdeatils(newValue);
    }
  };

  // const filterCustomerDetail = () => {
  //   debugger;

  //   return CustomerDeatils;
  // };

  // Initialize an empty array

  const handleCheckboxChange = (e, index, contact) => {
    debugger;
    setaddstatus('ADD')
    var newArr = [...CustomerDeatils];
    newArr[index].ChekboxStatus = e.target.checked;
    setCustomerDetails(newArr);



    setcustomeridaddnew(contact.CUSTOMERID);
    setstateidaddnew(contact.STATEID);
    let CUSTOMERID = contact.CUST_CONTACTID;
    console.log("Current CUSTOMERID:", CUSTOMERID);
    console.log("Previous IDs:", prevCustomerIds);



    // Create a copy of the previous IDs array
    let updatedIds = [...prevCustomerIds];

    if (prevCustomerIds.includes(CUSTOMERID)) {
      // If the CUSTOMERID is already in the array, remove it
      updatedIds = updatedIds.filter((id) => id !== CUSTOMERID);
    } else {
      // If the CUSTOMERID is not in the array, add it
      updatedIds.push(CUSTOMERID);
    }

    // Update the state with the modified array
    setPrevCustomerIds(updatedIds);

    console.log("Updated IDs:", updatedIds);
  };

  const handleADD = () => {
    debugger;
    console.log("Selected IDs:", prevCustomerIds);
    let cusidadd = customeridaddnew;

    console.log("Tagname:", Tagname);
    
    setaddstatus('save successful')
    axios
      .post(config.apiLink + "Dashboard/CustomerDetailsPopupAddDelete", {
        CUSTOMERIDADD: cusidadd,
        CustomerIdsToAdd: prevCustomerIds,
        Tagid: TagID,
        STATEID: stateidaddnew,
      })
      .then((response) => {
        debugger;
        console.log(response.data);
      });
  };

  return (
    <div>
      <div
        className="flexCenterCenter"
        style={{ width: "100%", height: "50px", background: "blue" }}
      >
        <h3 >ADD NEW Customer</h3>
        <h2  style={{
      position: 'absolute', // Position the "x" element absolutely
      right: '10px', // Adjust right position as needed
      top: '10px', // Adjust top position as needed
      margin: 0,
      cursor: 'pointer', // Optional: change cursor to pointer to indicate it's clickable
    }}  onClick={()=>onClose()}>x</h2>
      </div>
      <Grid container lg={{ padding: "10px" }}>
        <Grid item lg={12} style={{ width: "100%", height: "100%" }}>
          <h3 style={{ width: "65%", margin: "3px auto" }}>
            Tagname:{Tagname}
          </h3>
          <Autocomplete
            style={{ margin: "10px auto", display: "flex" }}
            id="multiple-limit-tags"
            value={selectedaddcustomer}
            onChange={(e, newvalue) => {
              handlechangecustdd(newvalue);
            }}
            options={cusName}
            getOptionLabel={(option) => option.CUSTOMERNAME}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Add new Customer"
                placeholder="Add new Customer"
              />
            )}
            sx={{ width: "600px" }}
          />
          <Card
            style={{
              borderRadius: "10px",
              margin: "0px auto",
              boxShadow: "gray 0px 0px 4px",
              padding: "10px",
              margin: "10px",
          
              maxHeight: "300px",
            }}
          >
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>CUSTOMERNAME</StyledTableCell>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>Mobile</StyledTableCell>
                      <StyledTableCell>Email</StyledTableCell>
                      <StyledTableCell>Department</StyledTableCell>
                      <StyledTableCell>Designation</StyledTableCell>
                      <StyledTableCell>Edit</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {CustomerDeatils.map((contact, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="centre" scope="row">
                          {contact.FIRSTNAME}
                        </StyledTableCell>
                        <StyledTableCell align="centre">
                          {contact.CONTACTNAME}
                        </StyledTableCell>
                        <StyledTableCell align="centre">
                          {contact.CONTACTMOBILE}
                        </StyledTableCell>
                        <StyledTableCell align="centre">
                          {contact.CONTACTEMAIL}
                        </StyledTableCell>
                        <StyledTableCell align="centre">
                          {contact.CONTACTDEPT}
                        </StyledTableCell>
                        <StyledTableCell align="centre">
                          {contact.CONTACTDESGN}
                        </StyledTableCell>
                        <StyledTableCell align="centre">
                          <FormControlLabel
                            required
                            control={<Checkbox checked={contact.ChekboxStatus} />}
                            label="Required"
                            onClick={(e) => handleCheckboxChange(e, index, contact)}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

            {/* <Grid container spacing={2}>
              {CustomerDeatils.map((contact, index) => (
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <div
                    key={index}
                    style={{
                      borderRadius: "10px",
                      margin: "0px 0px",
                      boxShadow: "gray 0px 0px 4px",
                      padding: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        float: "right",
                        top: "0",
                        cursor: "pointer",
                      }}
                    >
                      X
                    </div>
                    {index}
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      CUSTOMERNAME: {contact.FIRSTNAME}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Name: {contact.CONTACTNAME}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      Mobile: {contact.CONTACTMOBILE}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      Email: {contact.CONTACTEMAIL}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      Department: {contact.CONTACTDEPT}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      Designation: {contact.CONTACTDESGN}
                    </Typography>
                    <FormControlLabel
                      required
                      control={<Checkbox />}
                      label="Required"
                      onChange={() => handleCheckboxChange(contact)}
                    />*/}
            {/* <CardActions>
              <Button size="small">Learn More</Button>
            </CardActions> */}
          </Card>
          <div style={{ display: "flex" }}>
            <Button
              style={{ display: "flex", width: "30%", margin: "10px auto" }}
              variant="contained"
              onClick={handleADD}
            >
              {addstatus}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddNewContact;

// <Dialog
// open={addopen}
// onClose={handleClose}
// aria-labelledby="alert-dialog-title"
// aria-describedby="alert-dialog-description"
// fullWidth
// maxWidth="md"
// >

// </Dialog>
