// Login.js
import React, { useState } from "react";
import "../Common/Styles.scss"; // Import your SCSS file
import {
  Alert,
  Button,
  Card,
  Collapse,
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import axios from "axios";
import config from "../Common/config";
import { CircularProgress } from "@mui/material";
import clsx from "clsx";
import "../Common/SaasStyles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
    "& .MuiDialog-paper": {
      borderRadius: "20px", // This changes the border radius of the dialog paper
    },
  }));

const EnquiryLogin = () => {
  const [Email, setEmail] = useState("");
  const [EmailErr, setEmailErr] = useState(false);
  const [OTP, setOTP] = useState("");
  const [OTPErr, setOTPErr] = useState(false);
  const [OTPShow, setOTPShow] = useState(false);
  const [OTPSentDialog, setOTPSentDialog] = useState(false);
  const [alertOpen, setalertOpen] = useState(false);
  const [alertLSeverity, setalertLSeverity] = useState("");
  const [AlertMsg, setAlertMsg] = useState("");

  //New button
  const [Buttontext, setButtontext] = useState("Login");
  const [buttonColor, setbuttonColor] = useState(true);
  const [buttonloading, setbuttonloading] = useState(false);
  const [buttonSuccess, setbuttonSuccess] = useState(false);
  const [failure, setfailure] = useState(false);
  const [buttondisabled, setbuttondisabled] = useState(false);

  const buttonClassname = clsx({
    login_btn: buttonColor,
    login_btn_failure: failure,
  });

  const validate = () => {
    let formIsValid = true;
    if (Email === "") {
      formIsValid = false;
      setEmailErr(true);
    } else {
      setEmailErr(false);
    }
    return formIsValid;
  };

  const LoginSubmit = (e) => {
    if (validate()) {
      setbuttonloading(true);
      setbuttondisabled(true);
      LoginN();
    }
  };

  const KeyLoginSubmit = (e) => {
    if (e.key === "Enter") {
      if (validate()) {
        setbuttonloading(true);
        setbuttondisabled(true);
        LoginN();
      }
    }
  };

  const validateOTP = () => {
    let formIsValid = true;
    if (OTP === "") {
      formIsValid = false;
      setOTPErr(true);
    } else {
      setOTPErr(false);
    }
    return formIsValid;
  };

  const OTPSubmit = (e) => {
    if (validateOTP()) {
      setbuttonloading(true);
      setbuttondisabled(true);
      OTPValidate();
    }
  };

  const KeyOTPSubmit = (e) => {
    if (e.key === "Enter") {
      if (validateOTP()) {
        setbuttonloading(true);
        setbuttondisabled(true);
        OTPValidate();
      }
    }
  };

  const LoginN = () => {
    console.log(config.apiLink);
    axios
      .post(config.apiLink + "Alucast/ALUCASTEnquiryLoginOTPGenerate", {
        Email: Email,
      })
      .then(
        (response) => {
          var Temp = response.data;
          if (Temp["LoginDetails"][0].STATUS === "S") {
            setbuttonColor(false);
            setfailure(false);
            setTimeout(function () {
              setbuttonloading(false);
              setbuttonSuccess(true);
              setButtontext("OTP Sent Successfully");
            }, 1000);
            setTimeout(() => {
                setbuttondisabled(false);
                setbuttonSuccess(false);
                setButtontext("Validate");
                setOTPSentDialog(true);
                setOTPShow(true);
            }, 2000);
          } else {
            setbuttonColor(false);
            setfailure(false);
            setbuttonloading(false);
            setbuttondisabled(false);
            setbuttonSuccess(false);
            setButtontext("Login");
            setalertOpen(true);
            setalertLSeverity("error");
            setAlertMsg("Invalid Email");
            setTimeout(function () {
              setalertOpen(false);
            }, 2000);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const OTPValidate = () => {
    axios
      .post(config.apiLink + "Alucast/ALUCASTEnquiryLoginOTPValidate", {
        Email: Email,
        OTP: OTP,
      })
      .then(
        (response) => {
          debugger;
          var Temp = response.data;
          if (Temp["LoginValidateDetails"][0].STATUS === "S") {
            localStorage.setItem("EMPID", Temp["LoginValidateDetails"][0].EMPID);
            localStorage.setItem("USERID", Temp["LoginValidateDetails"][0].USERID);
            localStorage.setItem("USERNAME", Temp["LoginValidateDetails"][0].EMPLOYEENAME);
            localStorage.setItem("BRANCHNAME", Temp["LoginValidateDetails"][0].BRANCHNAME);
            // localStorage.setItem("EMPID", 43);
            // localStorage.setItem("USERID", 2);
            // localStorage.setItem("USERNAME", "Abishek");
            // localStorage.setItem("BRANCHNAME", "Bangalore");
            setbuttonColor(false);
            setfailure(false);
            setTimeout(function () {
              setbuttonloading(false);
              setbuttonSuccess(true);
              setButtontext("OTP Validated Successfully");
            }, 1000);
            setTimeout(() => {
                window.location.href = "/EnquiryFromCardList";
            }, 2000);
          } else {
            setbuttonColor(false);
            setfailure(false);
            setbuttonloading(false);
            setbuttondisabled(false);
            setbuttonSuccess(false);
            setButtontext("Login");
            setalertOpen(true);
            setalertLSeverity("error");
            setAlertMsg("Invalid OTP");
            setTimeout(function () {
              setalertOpen(false);
            }, 2000);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleOTPSentDialogClose = () => {
    setOTPSentDialog(false);
  }
  
  return (
    <div className="login_container">
      <Grid container>
        <Grid item xs={12} sm={12} md={3} lg={3}></Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography align="center" className="login_title_name">
            ALUCAST - ENQUIRY
          </Typography>
          <div className="flexCenterCenter">
            <Typography className="login_caption_name">Powered By</Typography>
            <Typography className="login_sub_title_name">TVENKA</Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}></Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Card className="login_card">
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="login_card_title_div">
                  <Typography
                    variant="h4"
                    align="center"
                    className="login_card_title"
                  >
                    Enquiry Login
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  variant="h4"
                  align="center"
                  className="login_card_divider_div"
                ></Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ marginTop: "60px" }}
              >
                <div style={{ padding: "0px 40px" }}>
                {!OTPShow && (
                  <TextField
                    variant="outlined"
                    // label="User Name"
                    fullWidth
                    placeholder="Enter your email"
                    id="outlined-size-normal"
                    sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#054b4b",
                          },
                          "&:hover fieldset": {
                            borderColor: "#054b4b",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#054b4b",
                          },
                          backgroundColor: "transparent",
                        },
                      }}
                    value={Email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailErr(false);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutlineIcon />
                        </InputAdornment>
                      ),
                    }}
                    error={Email.length === 0 && EmailErr ? true : false}
                    autoFocus
                    onKeyDown={KeyLoginSubmit}
                  />
                )}
                {OTPShow && (
                  <TextField
                    variant="outlined"
                    // label="User Name"
                    fullWidth
                    placeholder="Enter your email OTP"
                    id="outlined-size-normal"
                    sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#054b4b",
                          },
                          "&:hover fieldset": {
                            borderColor: "#054b4b",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#054b4b",
                          },
                          backgroundColor: "transparent",
                        },
                      }}
                    value={OTP}
                    onChange={(e) => {
                      setOTP(e.target.value);
                      setOTPErr(false);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutlineIcon />
                        </InputAdornment>
                      ),
                    }}
                    error={OTP.length === 0 && OTPErr ? true : false}
                    autoFocus
                    onKeyDown={KeyOTPSubmit}
                  />
                )}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ margin: "20px 0px 10px 0px" }}
              >
                <div style={{ padding: "0px 40px" }}>
                  <Collapse in={alertOpen}>
                    <Alert severity={alertLSeverity} style={{ color: "red" }}>
                      {AlertMsg}
                    </Alert>
                  </Collapse>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div
                  className="flexCenterCenter"
                  style={{ padding: "0px 40px" }}
                >
                  <div style={{ position: "relative", width: "100%" }}>
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={buttondisabled}
                      //   className={failure ? "login_btn_failure" : "login_btn"}
                      className={buttonClassname}
                      color="primary"
                      onClick={() => {
                        if(!OTPShow){
                            LoginSubmit();
                        }
                        else{
                            OTPSubmit();
                        }
                    }}
                      style={{
                        backgroundColor:
                          buttondisabled === true && buttonSuccess === true
                            ? "#4CAF50"
                            : "#054b4b",
                        color:
                          buttondisabled === true && buttonSuccess === true
                            ? "white"
                            : "",
                        marginTop: "10px",
                        marginBottom: "60px",
                        padding: "10px",
                        borderRadius: "16px",
                      }}
                    >
                      {Buttontext}
                      {buttonloading && (
                        <CircularProgress
                          size={24}
                          className="login_btn_loader"
                        />
                      )}
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
      </Grid>
      <BootstrapDialog
        onClose={handleOTPSentDialogClose}
        aria-labelledby="customized-dialog-title"
        open={OTPSentDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent style={{ padding: "0px" }}>
          <Card
            sx={{
              border: "2px solid #bee3ff",
              bgcolor: "#f0f8ff",
              padding: "32px 16px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  align="center"
                  className="InterMedium font_size18 color-161616"
                >
                  Your OTP has been sent to you email ID.<br></br> Please, Verify your OTP to login.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                <div className="flexCenterCenterHeight">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleOTPSentDialogClose}
                    sx={{
                      pl: 5,
                      pr: 5,
                      textTransform: "capitalize",
                      bgcolor: "#fab005",
                      border: "1px solid #df9d05",
                      "&:hover": {
                        bgcolor: "#e6a504", // Slightly darker shade
                        opacity: 0.8, // Decrease opacity
                      },
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Card>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default EnquiryLogin;
