var CryptoJS = require("crypto-js");

const helpers = {
  EncryptVal: function (param1) {
    const InputEncry = CryptoJS.AES.encrypt(
      JSON.stringify(param1),
      "my-secret-key@123"
    ).toString();
    return InputEncry;
  },
  DecryptVal: function (param1) {
    var bytes = CryptoJS.AES.decrypt(param1, "my-secret-key@123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  },
};
export default helpers;
