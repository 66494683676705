import React from "react";
import { motion } from "framer-motion";
import { Typography } from "@mui/material";

const Welcome = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // height: "80vh",
        background: "transparent",
        // color: "#fff",
        textAlign: "center",
        fontFamily: "'Arial', sans-serif",
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <motion.h1
          style={{
            fontSize: "2rem",
            marginBottom: "1rem",
            textAlign: "left",
          }}
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{
            duration: 0.6,
            delay: 0.5,
            type: "spring",
            stiffness: 100,
          }}
        >
          <Typography
            align="center"
            className="InterMedium font_size35 color-b082ba"
            sx={{fontWeight: "bolder"}}
          >
            Welcome to Quantum Access
          </Typography>
        </motion.h1>
        <motion.p
          style={{
            fontSize: "1.5rem",
            textAlign: "left",
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.5 }}
        >
          <Typography
            align="left"
            className="InterMedium font_size20 color-b082ba"
          >
            We’re delighted to have you here. Explore and enjoy!
          </Typography>
        </motion.p>
      </motion.div>
    </div>
  );
};

export default Welcome;
