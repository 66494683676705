import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Grid,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";

import "../Common/Styles.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import config from "../Common/config";
import helpers from "../Encryption/Encrypt";

export default function LoginDashboard() {
  const location = useLocation();
  var url = "localhost:3001/PageRedirect";
  const history = useNavigate();
  const [Applications, setApplications] = useState([]);
  const isMobileOrTablet = useMediaQuery({
    query: "(max-width: 959px)",
  });

  useEffect(() => {
    debugger;
    sessionStorage.setItem("CurrentPageTitle", "Pending Invoice");
    if (location.state !== undefined) {
      setApplications(location.state.ApplicationOBJ);
    }
    console.log(location.state.ApplicationOBJ);
  }, []);

  const btnclkloginas = (obj) => {
    debugger;
    if (obj.APPLICATIONID === 1001) {
      //loadSales();
    } else if (obj.APPLICATIONID === 1002) {
      //SI
      window.open(
        "https://demo4.tvenka.com/PageRedirect?APPUSERID=" + obj.APPUSERID
      );
    } else if (obj.APPLICATIONID === 1003) {
      //Internal
    } else if (obj.APPLICATIONID === 1004) {
      //ZitaiService
      window.open(
        "http://localhost:3001/PageRedirect?APPUSERID=" + obj.APPUSERID
      );
    }
  };

  return (
    <div className="body_margin">
      <div>
        <Grid container className="">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="flexStartCenterHeight">
              <Tooltip title="Back">
                <IconButton>
                  <ArrowBackIcon style={{ color: "#013d54" }} />
                </IconButton>
              </Tooltip>
              <Typography align="left" className="List_Screen_Heading">
                Application Details
              </Typography>
            </div>
          </Grid>
        </Grid>
        <hr className="List_Screen_Heading_Divider" />
        <Grid container className="margin20_0">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {Applications.length !== 0 &&
              Applications.map((elem, index) => (
                <Card className="list_card" key={index}>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        flex: isMobileOrTablet ? "6%" : "3%",
                        backgroundColor: "#fcb900",
                      }}
                    >
                      <div className="flexCenterStartHeight">
                        <Typography variant="body1" className="List_Index">
                          {index + 1}
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        flex: "100%",
                      }}
                    >
                      <Grid container spacing={2} className="list_main_div">
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <Typography
                            variant="body2"
                            gutterBottom
                            align="left"
                            className="List_Label"
                          >
                            APPLICATIONID
                          </Typography>
                          <Typography
                            variant="body1"
                            align="left"
                            className="List_Value_Type2"
                          >
                            {elem.APPLICATIONID}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <Typography
                            variant="body2"
                            gutterBottom
                            align="left"
                            className="List_Label"
                          >
                            APPLICATIONNAME
                          </Typography>
                          <Typography
                            variant="body1"
                            align="left"
                            className="List_Value_Type2"
                          >
                            {elem.APPLICATIONNAME}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <Typography
                            variant="body2"
                            gutterBottom
                            align="left"
                            className="List_Label"
                          >
                            APPUSERID
                          </Typography>
                          <Typography
                            variant="body1"
                            align="left"
                            className="List_Value_Type2"
                          >
                            {elem.APPUSERID}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <Button
                            variant="contained"
                            className="inbox1"
                            onClick={() => btnclkloginas(elem)}
                          >
                            <strong>Login as</strong>
                            {/* <AddCircleOutlineIcon /> */}
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Card>
              ))}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
