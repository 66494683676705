import {
  AppBar,
  Box,
  Grid,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ProfileIcon from "../Images/ProfileIcon.png";
import "../Common/SaasStyles.scss";
import { useLocation } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { useMediaQuery } from "react-responsive";

function MenuNavBar() {
  const location = useLocation();
  var UserName = localStorage.getItem("USERNAME");
  var BranchName = localStorage.getItem("BRANCHNAME");
  const [anchorEl, setAnchorEl] = useState(null);

  const isMobile = useMediaQuery({
    query: "(max-width: 599px)",
  });

  useEffect(() => {
    if((UserName === null || UserName === undefined) && window.location.pathname !== "/Member" 
    && window.location.pathname !== "/EnquiryCreation"){
      window.location.href = "/";
    }
  }, [UserName]);

  const handleClick = (event) => {
    if(window.location.pathname !== "/Member" && window.location.pathname !== "/EnquiryCreation") {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    window.location.href = "/";
    localStorage.clear();
  };

  return (
    <div>
      <AppBar position="fixed" sx={{ bgcolor: "#054b4b" }}>
        <Toolbar>
          <Grid container>
            <Grid item xs={2} sm={12} md={4} lg={4}>
              <div className="flexStartCenterHeight">
                <Typography
                  align="left"
                  className={`JustSansBold ${isMobile ? "font_size20" : "font_size30"} color-fdd41c`}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    if(window.location.pathname !== "/Member" && window.location.pathname !== "/EnquiryCreation") {
                      window.location.href = "/EnquiryFromCardList";
                    }
                  }}
                >
                  TVENKA
                </Typography>
              </div>
            </Grid>
            <Grid item xs={8} sm={12} md={4} lg={4}>
              <div className="flexCenterCenterHeight">
                {location.pathname === "/EnquiryFromCardList" && (
                  <Typography
                    align="center"
                    className="font_weight_bolder helvetica font_size20 color-ffffff"
                  >
                    Enquiry List From Business Card
                  </Typography>
                )}
                {location.pathname === "/EnquiryFromCardDetails" && (
                  <Typography
                    align="center"
                    className="font_weight_bolder helvetica font_size20 color-ffffff"
                  >
                    Enquiry Details From Business Card
                  </Typography>
                )}
                {location.pathname === "/CustomerMapping" && (
                  <Typography
                    align="center"
                    className="font_weight_bolder helvetica font_size20 color-ffffff"
                  >
                    Customer Mapping For an Enquiry
                  </Typography>
                )}
                {location.pathname === "/EnquiryCreation" && (
                  <Typography
                    align="center"
                    className="font_weight_bolder helvetica font_size20 color-ffffff"
                  >
                    Alucast Enquiry
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid item xs={2} sm={12} md={4} lg={4}>
                <Box onClick={handleClick} className="flexEndCenterHeight">
                  <img
                    src={ProfileIcon}
                    alt="ProfileIcon"
                    width={isMobile ? "30" : "40"}
                    height={isMobile ? "30" : "40"}
                  />
                  <div className="gridStart margin_left10">
                    <Typography
                      align="left"
                      // className={isMobile?"InterBold font_size10 color-ffffff":"InterBold font_size18 color-ffffff"}
                      className={`InterBold ${isMobile ? "font_size15" : "font_size18"} color-ffffff`}
                    >
                      {UserName}
                    </Typography>
                    <Typography
                      align="left"
                      className="InterRegular font_size13 color-ffffff"
                    >
                      {BranchName}
                    </Typography>
                  </div>
                </Box>
            </Grid>
          </Grid>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogout}>
              <div
                style={{
                  padding: "10px 50px 10px 0px",
                  display: "flex",
                  alignItems: "Center",
                  height: "100%",
                }}
              >
                <LogoutIcon fontSize="lg" color="primary" />
                <span style={{ marginLeft: "10px" }}>Logout</span>
              </div>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default MenuNavBar;
