import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  InputAdornment,
  ListItem,
  ListItemText,
  TextField,
  Typography, CardContent
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DividerWithText from "./Components/DividerWithText";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import config from "../Common/config";
import CustomSpinner from "../Common/CustomSpinner";
import EnquiryCardProductCustomerDisplay from "./Components/EnquiryCardProductCustomerDisplay.js";
import PersonIcon from "@mui/icons-material/Person";
import WifiCalling3OutlinedIcon from "@mui/icons-material/WifiCalling3Outlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ClassIcon from '@mui/icons-material/Class';
import DesignServicesIcon from '@mui/icons-material/DesignServices';



function CustomerMapping() {
  var EmpID = localStorage.getItem("EMPID");
  const location = useLocation();
  const history = useNavigate();
  const [loadingCommon, setLoadingCommon] = useState(false);
  const [afterLoadingCommon, setAfterLoadingCommon] = useState(false);
  const [txtvalue, settxtvalue] = useState();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputvalue, setinputvalue] = useState("");
  const [loading, setloading] = useState(false);
  const [OnFocusVal, setOnFocusVal] = useState(true);
  const [customerID, setCustomerID] = useState("");
  const [customerDet, setCustomerDet] = useState({});
  const [customerIDErr, setCustomerIDErr] = useState(false);
  const [timer, setTimer] = useState(null);
  const [ContactDet, setContactDet] = useState([]);
  const [Company, setCompany] = useState("");
  const [DelPopupShow, setDelPopupShow] = useState(false);
  const [cTitle, setCTitle] = useState("");
  const [PinCode, setPinCode] = useState("");
  const [CusContactId, setCusContactId] = useState("");
  const [hideDelMapCustomer, sethideDelMapCustomer] = useState(true);
  
  //Save New Contact Btn
  const [SaveBtnName, setSaveBtnName] = useState("Save Customer");
  const [SaveBtnLoading, setSaveBtnLoading] = useState(false);
  const [SaveBtnSuccess, setSaveBtnSuccess] = useState(false);
  const [SaveBtnFailure, setSaveBtnFailure] = useState(false);
  const [SaveBtnDisabled, setSaveBtnDisabled] = useState(false);
  
  // const [cTitleDet, setCTitleDet] = useState([]);
  const [cTitleErr, setCTitleErr] = useState(false);
  const cTitleDet = [
    { ID: 1, TITLE: "Mr." },
    { ID: 2, TITLE: "Ms." },
    { ID: 3, TITLE: "Dr." },
    { ID: 4, TITLE: "M/s" },
  ];
  const [selectedValue, setSelectedValue] = React.useState("");
  const [NameErr, setNameErr] = useState("");
  const [Name, setName] = useState("");
const [State, setState] = useState([]);
const [SeletedState, setSeletedState] = useState("");
const [City, setCity] = useState([]);
const [SeletedCity, setSeletedCity] = useState("");
const [BranchMaster, setBranchMaster] = useState([]);
const [SeletedBranchMaster, setSeletedBranchMaster] = useState("");
const [CustomerName, setCustomerName] = useState([]);
const [CusDateils, setCusDateils] = useState([]);
const [CusContactDateils, setCusContactDateils] = useState([]);



  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top-left corner
  }, []);

  const EnquiryCustomerMapSave = () => {
    axios
      .post(config.apiLink + "Alucast/EnquiryCustomerMapSave", {
        EnquiryID: location.state.params.ENQUIRYID,
        CustomerID: customerID,
        EmpID: EmpID,
      })
      .then(
        (response) => {
          var Temp = response.data;
          if (Temp === "Success") {
            history("/EnquiryFromCardList");
          }
        },
        (error) => {
          console.log(error);
          setAfterLoadingCommon(false);
        }
      );
  };

  const handlecustomerMappingSave = () => {
    if (customerID !== "") {
      setAfterLoadingCommon(true);
      EnquiryCustomerMapSave();
    } else {
      setCustomerIDErr(true);
    }
  };

  function escapeRegExp(value) {
    return value.replace(/[\[\]{}()*+?.,\\^$%#\s]/g, "\\$&");
  }

  const getHighlightedText = (text, highlight) => {
    if (!highlight.trim()) return text;
    const regex = new RegExp(`(${escapeRegExp(highlight)})`, "gi");
    const parts = text.split(regex);
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "#D6F8AD" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const onChangeHandle = async (value) => {
    setOptions([]);
    let response = await fetch(config.apiLink + "Alucast/CustomerNameSearch", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Text: value,
      }),
    });
    if (response.ok) {
      var Data = await response.json();
      console.log(Data["CustomerList"]);
      if (Data["CustomerList"].length === 0) {
        var Temp = [
          {
            CUSTOMERID: 0,
            FIRSTNAME: `No data found for "` + value + `"`,
            VALUE: "",
          },
        ];
        setOptions(Temp);
      } else {
        const countries = Data["CustomerList"];
        setOptions(countries);
      }
      setloading(false);
    } else {
      Temp = [
        {
          CUSTOMERID: 0,
          FIRSTNAME: `No data found for "` + value + `"`,
          VALUE: "",
        },
      ];
      setOptions(Temp);
      setloading(false);
      console.log("HTTP-Error: " + response.status);
    }
  };

  const CustomerContact = (customerID) => {
    axios
      .post(config.apiLink + "Alucast/CustomerContact", {
        CustomerId: customerID,
      })
      .then(
        (response) => {
          var Temp = response.data;
          setContactDet(Temp["ContactList"]);
        })
  };

  const btnclkClear = () => {
    setinputvalue("");
    setOptions([]);
    settxtvalue("");
    setCustomerID("");
    setContactDet([]);
  };

  const handleContactDet = (newContact) => {
    debugger;
    setContactDet((prevContacts) => [...prevContacts, newContact]);
  };

  const addHandleClose = () => {
    setDelPopupShow(false)

  };
  const onChangeName = (e) => {
    setCustomerName(e.target.value);
  };
  const EnquiryCustomerDetails = () => {
    axios
      .post(config.apiLink + "Alucast/GetCustomerDetails", {
         EnquiryID: location.state.params.ENQUIRYID,
        // CustomerID: customerID,
        // EmpID: EmpID,
      })
      .then(
        (response) => {
          debugger;
          var Temp = response.data;
          setCusDateils(Temp["CusDateils"])
          setCusContactDateils(Temp["CusContactDateils"])
          // setCusContactId(Temp["CusContactDateils"][0]["CUSTOMERID"])
          // setState(Temp["STATE"]);
          // setCity(Temp["CITY"]);
          // setBranchMaster(Temp["BRANCH_MASTER"]);
          // setCustomerName(Temp["CustomerName"][0]["COMPANYNAME"]);
          
          // if (Temp === "Success") {
          //    history("/EnquiryFromCardList");
          // }
        },
        (error) => {
          console.log(error);
          // setAfterLoadingCommon(false);
        }
      );
  };
  const btnSaveCustomer = () => {

    debugger;
    var demp =[...CusContactDateils];
    axios
      .post(config.apiLink + "Alucast/SaveCustomerDetails", {
         EnquiryID: location.state.params.ENQUIRYID,
         FIRSTNAME: CusDateils[0].COMPANYNAME,
         BRANCHID: CusDateils[0].BRANCHID,
         ADDRESSLINE1: CusDateils[0].ADDRESS,
         STATE: CusContactDateils[0].STATE,
         CITY: CusContactDateils[0].CITY,
         PINCODE: CusContactDateils[0].PINCODE,
         selectedSaveCus: CusContactDateils
        //  NAME: CusContactDateils[0].NAME,
        //  EMAIL: CusContactDateils[0].EMAIL,
        //  MOBILENO: CusContactDateils[0].MOBILENO,
        //  DESIGNATION: CusContactDateils[0].DESIGNATION,
        
        // EmpID: EmpID,
      })
      .then(
        (response) => {
          debugger;
          var Temp = response.data;
           // var Temp = response.data;
          
          //  setAfterLoadingCommon(false);
           setLoadingCommon(false);
           setSaveBtnName("Customer contact added successfully");
           setSaveBtnLoading(false);
           setSaveBtnSuccess(true);
          //  setTimeout(() => {
          //    onOk();
          //  }, 1000);
           setTimeout(() => {
             setSaveBtnName("Add Customer");
             setAfterLoadingCommon(false);
             setSaveBtnFailure(false);
             setSaveBtnLoading(false);
             setSaveBtnDisabled(false);
            //  setSaveBtnSuccess(false);
           }, 4000);
         
        },
        (error) => {
          console.log(error);
        }
      );
  };
  const openUpdateContactBox = () => {
    setDelPopupShow(true);
    sethideDelMapCustomer(false)
    EnquiryCustomerDetails();
  }
  const handleSave = () => {
    // if (validate()) {
      // setDelPopupShow(true);
      // sethideDelMapCustomer(false)
      // EnquiryCustomerDetails();
      setLoadingCommon(true);
      setAfterLoadingCommon(true);
      setSaveBtnName("Updating...");
      setSaveBtnFailure(false);
      setSaveBtnLoading(true);
      setSaveBtnDisabled(true);
      btnSaveCustomer();
    // }
  };


  return (
    <div className="body_margin_Enquiry">
      <>
        <Card
          // data-aos="fade-up"
          sx={{
            padding: "32px 24px 24px",
            borderRadius: "10px",
            border: "1px solid #d4e6e6",
            boxShadow: 0,
            mt: 1,
            mb: 2,
          }}
        >
          <Grid container alignItems={"center"}>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <div className="gridStart">
                <Typography
                  align="left"
                  className="InterRegular font_size16 color-636363"
                  gutterBottom
                >
                  Enquiry No
                </Typography>
                <Typography
                  align="left"
                  className="InterMedium font_size18 color-161616"
                >
                  {location.state.params.ENQUIRYNO}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <div className="gridStart">
                <Typography
                  align="left"
                  className="InterRegular font_size16 color-636363"
                  gutterBottom
                >
                  Lead Name
                </Typography>
                <Typography
                  align="left"
                  className="InterMedium font_size18 color-161616"
                >
                  {location.state.params.LEADNAME}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <div className="gridStart">
                <Typography
                  align="left"
                  className="InterRegular font_size16 color-636363"
                  gutterBottom
                >
                  Follow Up Date
                </Typography>
                <Typography
                  align="left"
                  className="InterMedium font_size18 color-161616"
                >
                  {moment(location.state.params.FOLLOWUPDATE).format(
                    "DD MMM hh:mm a"
                  )}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <div className="gridStart">
                <Typography
                  align="left"
                  className="InterRegular font_size16 color-636363"
                  gutterBottom
                >
                  Created Time
                </Typography>
                <Typography
                  align="left"
                  className="InterMedium font_size18 color-161616"
                >
                  {moment(location.state.params.CREATEDTIME).format(
                    "DD MMM hh:mm a"
                  )}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <div className="gridStart">
                <Typography
                  align="left"
                  className="InterRegular font_size16 color-636363"
                  gutterBottom
                >
                  Created By
                </Typography>
                <Typography
                  align="left"
                  className="InterMedium font_size18 color-161616"
                >
                  {location.state.params.CREATEDBY}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="gridStart">
                <Typography
                  align="left"
                  className="InterRegular font_size16 color-636363"
                  gutterBottom
                >
                  Enquiry Description
                </Typography>
                <Typography
                  align="left"
                  className="InterMedium font_size18 color-161616"
                >
                  {location.state.params.ENQUIRYDESCRIPTION}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="gridStart">
                <Typography
                  align="left"
                  className="InterRegular font_size16 color-636363"
                  gutterBottom
                >
                  Lead Remarks
                </Typography>
                <Typography
                  align="left"
                  className="InterMedium font_size18 color-161616"
                >
                  {location.state.params.LEADREMARKS}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Card>

        <EnquiryCardProductCustomerDisplay
          EnquiryID={location.state.params.ENQUIRYID}
          ContactDet={handleContactDet}
          customerID={customerID}
          Company={Company}
          ContactDetList={ContactDet}
        />

        <Box>
          <DividerWithText>Customer&nbsp;Mapping</DividerWithText>

          <Box sx={{ ml: 2, mr: 2, mt: 2 }}>
            <Grid container justifyContent={"center"}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Autocomplete
                  freeSolo
                  disableClearable
                  value={txtvalue}
                  inputValue={inputvalue}
                  id="asynchronous-demo"
                  open={open}
                  onOpen={() => {
                    setOpen(inputvalue !== "");
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  getoptionselected={(option, value) =>
                    option.FIRSTNAME === value.FIRSTNAME
                  }
                  filterOptions={(options) => options}
                  getOptionLabel={(option) =>
                    option.FIRSTNAME ? option.FIRSTNAME : ""
                  }
                  options={options}
                  loading={loading}
                  onChange={(event, option) => {
                    if (option !== null) {
                      var SelectOtion = option;
                      setCustomerDet(SelectOtion);
                      setCustomerID(SelectOtion.CUSTOMERID);
                      setOnFocusVal(false);
                      setinputvalue(SelectOtion.FIRSTNAME);
                      settxtvalue(SelectOtion.FIRSTNAME);
                      CustomerContact(SelectOtion.CUSTOMERID);
                      setCompany(SelectOtion.FIRSTNAME);
                      setCustomerIDErr(false);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      autoFocus={OnFocusVal}
                      inputProps={{ maxLength: 500 }}
                      {...params}
                      variant="outlined"
                      error={inputvalue.length === 0 && customerIDErr === true}
                      placeholder="Enter Customer Name"
                      size="small"
                      onChange={(ev) => {
                        setloading(true);
                        if (
                          ev.target.value !== "" ||
                          ev.target.value !== null
                        ) {
                          setinputvalue(ev.target.value);
                          if (ev.target.value.length > 1) {
                            if (timer) {
                              clearTimeout(timer);
                              setTimer(null);
                            }
                            setTimer(
                              setTimeout(() => {
                                onChangeHandle(ev.target.value);
                              }, 1500)
                            );
                          } else {
                            setOptions([]);
                            setloading(false);
                            setOpen(false);
                          }
                        }
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "20px", // Set the border radius
                          "& fieldset": {
                            borderColor: "#dbeaf5",
                          },
                          "&:hover fieldset": {
                            borderColor: "#dbeaf5",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#101f33",
                          },
                          backgroundColor: "transparent",
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading === true && (
                              <CircularProgress color="inherit" size={20} />
                            )}
                            {params.InputProps.endAdornment}
                            <InputAdornment
                              position="end"
                              style={{ cursor: "pointer" }}
                            >
                              <div style={{ margin: "4px" }}></div>
                              <div className="DivSearchCloseIcon">
                                <CloseIcon
                                  fontSize="small"
                                  className="SearchCloseIcon"
                                  onClick={btnclkClear}
                                />
                              </div>
                            </InputAdornment>
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                  renderOption={(props, option, { inputValue }) => (
                    <ListItem {...props} component="div">
                      <ListItemText
                        primary={getHighlightedText(
                          option.FIRSTNAME,
                          inputValue
                        )}
                      />
                    </ListItem>
                  )}
                />
              </Grid>
              {customerID !== "" && (
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                  <Card
                    sx={{
                      padding: "8px 16px",
                      bgcolor: "#fefefe",
                      border: "2px solid #005251",
                      borderRadius: "10px",
                      boxShadow:
                        "0px 1px 8px rgba(0, 255, 255, 0.6)" /* Adjust the color to match */,
                    }}
                    className="flexCenterCenterHeight"
                  >
                    <Grid container>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <div className="gridStart">
                          <Typography
                            align="left"
                            className="InterRegular font_size16 color-636363"
                            gutterBottom
                          >
                            Customer Name
                          </Typography>
                          <Typography
                            align="left"
                            className="InterMedium font_size18 color-161616"
                          >
                            {customerDet.FIRSTNAME}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <div className="gridStart">
                          <Typography
                            align="left"
                            className="InterRegular font_size16 color-636363"
                            gutterBottom
                          >
                            Address
                          </Typography>
                          <Typography
                            align="left"
                            className="InterMedium font_size18 color-161616"
                          >
                            {customerDet.ADDRESSLINE1}, {customerDet.CityName}, {customerDet.StateName}-{customerDet.PINCODE}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <div className="gridStart">
                          <Typography
                            align="left"
                            className="InterRegular font_size16 color-636363"
                            gutterBottom
                          >
                            Branch Name
                          </Typography>
                          <Typography
                            align="left"
                            className="InterMedium font_size18 color-161616"
                          >
                            {customerDet.BRANCHNAME}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <div className="gridStart">
                          <Typography
                            align="left"
                            className="InterRegular font_size16 color-636363"
                            gutterBottom
                          >
                            Customer Code
                          </Typography>
                          <Typography
                            align="left"
                            className="InterMedium font_size18 color-161616"
                          >
                            {customerDet.CUSTCODE}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <div className="gridStart">
                          <Typography
                            align="left"
                            className="InterRegular font_size16 color-636363"
                            gutterBottom
                          >
                            GSTIN
                          </Typography>
                          <Typography
                            align="left"
                            className="InterMedium font_size18 color-161616"
                          >
                            {customerDet.GSTIN}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Box>

          <Box sx={{ ml: 2, mr: 2, mt: 6 }}>
            <Grid container spacing={2}>
              {ContactDet.length !== 0 && ContactDet.map((elem, index) => (
                <Grid item xs={12} md={12} sm={12} lg={3}>
                  <Card sx={{
                    // padding: "8px 16px",
                    bgcolor: "#fefefe",
                    border: "2px solid #005251",
                    borderRadius: "10px",
                    boxShadow:
                      "0px 1px 8px rgba(0, 255, 255, 0.6)" /* Adjust the color to match */,
                  }}>
                    <CardContent style={{ margin: "3px" }}>
                      <Grid container>
                        <Grid
                          item
                          xs={10}
                          md={10}
                          sm={11}
                          lg={11}
                          style={{ marginBottom: "10px" }}
                        >
                          <div className="flexStartCenter">
                            <PersonIcon className="ContactIcon" />
                            <Typography
                              variant="body1"
                              style={{
                                fontWeight: "bolder",
                                paddingLeft: "10px",
                              }}
                            >
                              {elem.NAME}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          sm={12}
                          lg={12}
                          style={{ marginBottom: "10px" }}
                        >
                          <div className="flexStartCenter">
                            <EmailOutlinedIcon className="ContactIcon" />
                            <Typography
                              variant="body1"
                              style={{ paddingLeft: "10px" }}
                            >
                              {elem.CONTACTEMAIL}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={12} sm={12} lg={12}>
                          <div className="flexStartCenter">
                            <WifiCalling3OutlinedIcon className="ContactIcon" />
                            <Typography
                              variant="body1"
                              style={{ paddingLeft: "10px" }}
                            >
                              {elem.CONTACTMOBILE}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={12} sm={12} lg={12}>
                          <div className="flexStartCenter">
                            <ClassIcon className="ContactIcon" />
                            <Typography
                              variant="body1"
                              style={{ paddingLeft: "10px" }}
                            >
                              {elem.CONTACTDEPT}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={12} sm={12} lg={12}>
                          <div className="flexStartCenter">
                            <DesignServicesIcon className="ContactIcon" />
                            <Typography
                              variant="body1"
                              style={{ paddingLeft: "10px" }}
                            >
                              {elem.CONTACTDESGN}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box sx={{ ml: 2, mr: 2, mt: 6 }}>
            <Grid container justifyContent={"center"}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="flexCenterCenterHeight">
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      pl: 5,
                      pr: 5,
                      textTransform: "capitalize",
                      bgcolor: "#fab005",
                      border: "1px solid #df9d05",
                      "&:hover": {
                        bgcolor: "#e6a504", // Slightly darker shade
                        opacity: 0.8, // Decrease opacity
                      },
                    }}
                    onClick={handlecustomerMappingSave}
                  >
                    <PrintOutlinedIcon sx={{ mr: 0.5 }} /> Map to customer
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </>



      <Backdrop className="useStylesBackDrop" open={afterLoadingCommon}>
        <CustomSpinner />
      </Backdrop>
    </div>
  );
}

export default CustomerMapping;
