import React, { useState } from "react";

const VoiceToTextTranslate = () => {
  const [text, setText] = useState(""); // To handle combined translated text
  const [language, setLanguage] = useState("en-US"); // Default language is English
  const [isRecording, setIsRecording] = useState(false);
  const [audioFiles, setAudioFiles] = useState([]); // To store list of audio files and their translated text
  const [mediaRecorder, setMediaRecorder] = useState(null); // To store MediaRecorder instance

  const apiKey = "AIzaSyBW6wtYv91SN7bOoMEyXK4Nmjv6S_6nLBc"; // Replace with your Google API key

  // Available languages
  const languages = [
    { code: "en-US", label: "English" },
    { code: "hi-IN", label: "Hindi" },
    { code: "te-IN", label: "Telugu" },
    { code: "ta-IN", label: "Tamil" },
    { code: "ml-IN", label: "Malayalam" },
    { code: "kn-IN", label: "Kannada" },
    { code: "ur-IN", label: "Urdu" },
    { code: "zh-TW", label: "Taiwanese Mandarin" },
  ];

  // Start voice recording
  const startRecording = async () => {
    if (!window.SpeechRecognition && !window.webkitSpeechRecognition) {
      alert("Your browser does not support Speech Recognition.");
      return;
    }

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();
    recognition.lang = language; // Set language dynamically
    recognition.interimResults = false;
    recognition.continuous = true; // Continue listening until stopped

    recognition.onstart = () => {
      setIsRecording(true);
    };

    recognition.onresult = (event) => {
      let transcript = "";
      for (let i = event.resultIndex; i < event.results.length; i++) {
        transcript += event.results[i][0].transcript + " ";
      }
      translateText(transcript.trim()); // Call translation function for the transcribed text
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
    };

    recognition.onend = () => {
      setIsRecording(false);
    };

    recognition.start();

    // Record audio as well
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const recorder = new MediaRecorder(stream);
    setMediaRecorder(recorder); // Store the media recorder instance

    const chunks = [];
    recorder.ondataavailable = (e) => chunks.push(e.data);
    recorder.onstop = () => {
      const blob = new Blob(chunks, { type: "audio/wav" });
      const audioUrl = URL.createObjectURL(blob); // Create URL for the audio blob
      const newAudioFile = {
        url: audioUrl,
        id: Date.now(), // Unique ID for each file
        translatedText: "", // Initial empty translated text
      };
      setAudioFiles((prevFiles) => [...prevFiles, newAudioFile]); // Add new audio file to the list
    };
    recorder.start();
  };

  // Stop voice recording
  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop(); // Stop the media recorder
    }
    setIsRecording(false);
  };

  // Translation function

const translateText = async (text) => {
  const url = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        q: text,
        target: "en", // Translate to English
        format: "text",
      }),
    });

    if (response.ok) {
      const data = await response.json();
      const translated = data.data.translations[0].translatedText;

      // Update the translated text for the current audio file
      setAudioFiles((prevFiles) => {
        const updatedFiles = prevFiles.map((file) =>
          file.id === prevFiles[prevFiles.length - 1].id
            ? { ...file, translatedText: translated }
            : file
        );

        // Combine all translated texts in the textarea
        const combinedText = updatedFiles
          .map((file) => file.translatedText)
          .join("\n"); // Adding newline for each entry
        setText(combinedText);

        return updatedFiles;
      });
    } else {
      console.error("Translation API Error:", response.statusText);
    }
  } catch (error) {
    console.error("Error:", error);
  }
};


  // Delete an audio file
  const deleteAudioFile = (fileId) => {
    setAudioFiles(audioFiles.filter((file) => file.id !== fileId)); // Remove file with matching ID
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Voice-to-Text Translation</h1>

      {/* Language Selector */}
      <div>
        <label htmlFor="language">Select Language: </label>
        <select
          id="language"
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
        >
          {languages.map((lang) => (
            <option key={lang.code} value={lang.code}>
              {lang.label}
            </option>
          ))}
        </select>
      </div>

      {/* Editable TextField for combined translated text */}
      <textarea
        rows={5}
        value={text}
        onChange={(e) => setText(e.target.value)} // Allow manual editing of the text
        placeholder="Your combined translated text will appear here"
        style={{
          width: "100%",
          marginTop: "20px",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      />

      {/* Record/Stop Button */}
      <button
        onClick={isRecording ? stopRecording : startRecording}
        style={{
          backgroundColor: isRecording ? "red" : "green",
          color: "white",
          padding: "10px 20px",
          margin: "10px 0",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        {isRecording ? "Stop Recording" : "Start Recording"}
      </button>

      {/* Audio Playback, Delete List, and Display Translated Text */}
      <div>
        <h3>Recorded Audios</h3>
        {audioFiles.length === 0 ? (
          <p>No recorded audio files</p>
        ) : (
          audioFiles.map((audioFile) => (
            <div key={audioFile.id} style={{ marginBottom: "10px" }}>
              <audio controls>
                <source src={audioFile.url} type="audio/wav" />
                Your browser does not support the audio element.
              </audio>
              <button
                onClick={() => deleteAudioFile(audioFile.id)}
                style={{
                  marginLeft: "10px",
                  backgroundColor: "red",
                  color: "white",
                  padding: "5px 10px",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Delete
              </button>

              {/* Display Translated Text */}
              {audioFile.translatedText && (
                <div>
                  <strong>Translated Text:</strong>
                  <p>{audioFile.translatedText}</p>
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default VoiceToTextTranslate;
