import {
  Backdrop, Box, Button, Card, Dialog, DialogActions, DialogContent, Grid, TextField, Typography, Alert,
  CardContent, MenuItem, Select, FormControl, Tooltip, IconButton, DialogTitle, InputLabel
} from "@mui/material";

import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../Common/config";
import CustomSpinner from "../../Common/CustomSpinner";
import DividerWithText from "./DividerWithText";
import moment from "moment";
import ImageWithBorders from "./ImageWithBorders";
import EditEnquiryCardMember from "./EditEnquiryCardMember";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import ProductCommentsList from "./ProductCommentsList";

function EnquiryCardProductCustomerDisplay(props) {
  // debugger;
  const { EnquiryID, EnquiryCardMemberID } = props;
  var EmpID = localStorage.getItem("EMPID");
  const history = useNavigate();
  const [loadingCommon, setLoadingCommon] = useState(true);
  const [enquiryCardCustomerDet, setEnquiryCardCustomerDet] = useState([]);
  const [enquiryDet, setEnquiryDet] = useState([]);
  const [brandDet, setBrandDet] = useState([]);
  const [productDet, setProductDet] = useState([]);
  const [modelDet, setModelDet] = useState([]);
  const [addProductDialog, setAddProductDialog] = useState(false);
  const [edtProductDialog, setEdtProductDialog] = useState(false);
  const [ProductId, setProductId] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedModels, setSelectedModels] = useState([]);
  const [modelNotes, setModelNotes] = useState({});
  const [productNotes, setProductNotes] = useState({});
  const [brandhovered, setBrandHovered] = useState(null);
  const [producthovered, setProductHovered] = useState(null);
  const [modelhovered, setModelHovered] = useState(null);
  const [enquiryCardEditDet, setEnquiryCardEditDet] = useState({});
  const [enquiryCardEditDialog, setEnquiryCardEditDialog] = useState(false);
  const [ShowAddContact, setShowAddContact] = useState(false);
  const [Title, setTitle] = useState("");
  const [Name, setName] = useState("");
  const [Design, setDesign] = useState("");
  const [Department, setDepartment] = useState("");
  const [Company, setCompany] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [Email, setEmail] = useState("");
  const [ContactExistsShow, setContactExistsShow] = useState(false);
  const [TitleErr, setTitleErr] = useState(false);
  const [NameErr, setNameErr] = useState(false);
  // const [DesignErr, setDesignErr] = useState(false);
  const [DepartmentErr, setDepartmentErr] = useState(false);
  const [CompanyErr, setCompanyErr] = useState(false);
  const [MobileNoErr, setMobileNoErr] = useState(false);
  const [EmailErr, setEmailErr] = useState(false);
  const [btnName, setbtnName] = useState("Save");
  const [addCommentDialog, setAddCommentDialog] = useState(false);
  const [hotLeadDialog, setHotLeadDialog] = useState(false);
  const [viewComment, setViewComment] = useState(false);
  const [productComment, setProductComment] = useState(null);

  const EnquiryCardListLoad = useCallback((EnquiryIDParam, MemberIdParam) => {

    var param = {
      EnquiryID: EnquiryIDParam,
      EnquiryCardMembersId: MemberIdParam
    }
    axios
      .post(config.apiLink + "Alucast/GetCustomerDetails", param)
      .then(
        (response) => {
          var Temp = response.data;
          setEnquiryCardCustomerDet(Temp["CustomerDetails"]);
          setEnquiryDet(Temp["EnquiryDetails"]);
          setLoadingCommon(false);
          setBrandDet(Temp["BrandDet"]);
          setProductDet(Temp["ProductDet"]);
          setModelDet(Temp["ModelDet"]);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  useEffect(() => {
    EnquiryCardListLoad(EnquiryID, EnquiryCardMemberID);
  }, [EnquiryCardListLoad, history, EnquiryID, EnquiryCardMemberID]);

  const handleProductEdit = (data) => {
    setProductId(data.PRODUCTID);
    setEdtProductDialog(true);
  }
  const handleAddComment = (data) => {
    debugger;
    setProductId(data.PRODUCTID);
    setAddCommentDialog(true);
  }
  const handleHotLead = (data) => {
    debugger;
    setProductId(data.PRODUCTID);
    setHotLeadDialog(true);
  }
  const handleViewComment = (data) => {
    debugger;
    setProductId(data.PRODUCTID);
    setViewComment(true);
  }
  const handleProductAdd = () => {
    setAddProductDialog(true);
  }
  const handleViewCommentClose = () => {
    setViewComment(false);
    setProductId(null);
  }
  const handleEdtDialogClose = () => {
    setEdtProductDialog(false);
  }
  const handleCommentClose = () => {
    setAddCommentDialog(false);
    setProductComment(null);
  }
  const handleHotLeadClose = () => {
    setHotLeadDialog(false);
    // setProductComment(null);
  }
  const handleAddDialogClose = () => {
    setAddProductDialog(false);
    setSelectedBrands([]);
    setSelectedModels([]);
    setSelectedProducts([]);
  }

  const handleDeleteProduct = () => {
    debugger;
    if (ProductId !== null) {
      axios.post(config.apiLink + "Alucast/DeleteEnquiryProduct", {
        ProductId: ProductId,
        EnquiryId: EnquiryID,
      }).then(
        (response) => {
          var Temp = response.data;
          setEdtProductDialog(false);
          setProductId(null);
          EnquiryCardListLoad(EnquiryID, EnquiryCardMemberID);
        },
        (error) => {
          console.log(error);
        }
      )
    }

  };
  const handleHotLeadProduct = () => {
    debugger;
    if (ProductId !== null) {
      axios.post(config.apiLink + "Alucast/AddHotLeadForProduct", {
        ProductId: ProductId,
        EnquiryId: EnquiryID,
      }).then(
        (response) => {
          var Temp = response.data;
          setHotLeadDialog(false);
          setProductId(null);
          EnquiryCardListLoad(EnquiryID, EnquiryCardMemberID);
        },
        (error) => {
          console.log(error);
        }
      )
    }

  };

  const handleClose = () => {
    setShowAddContact(false);
  };

  function AddContactValidation() {
    let Status,
      TittleE = 0,
      NameE = 0,
      DepartmentE = 0,
      EmailE = 0,
      MobileNoE = 0;
    if (Name === "" || Name === null || Name === undefined) {
      setNameErr(true);
      NameE = 0;
    } else {
      NameE = 1;
      setNameErr(false);
    }

    if (Title === "" || Title === null || Title === undefined) {
      setTitleErr(true);
      TittleE = 0;
    } else {
      TittleE = 1;
      setTitleErr(false);
    }

    if (Department === "" || Department === null || Department === undefined) {
      setDepartmentErr(true);
      DepartmentE = 0;
    } else {
      DepartmentE = 1;
      setDepartmentErr(false);
    }

    if (
      Email === "" ||
      Email === null ||
      Email === undefined
    ) {
      setEmailErr(true);
      EmailE = 0;
    } else {
      EmailE = 1;
      setEmailErr(false);
    }

    // if (Design === "" || Design === null || Design === undefined) {
    //   setDesignErr(true);
    //   DesignE = 0;
    // } else {
    //   DesignE = 1;
    //   setDesignErr(false);
    // }
    if (
      MobileNo === "" ||
      MobileNo === null ||
      MobileNo === undefined ||
      MobileNo.match("[0-9]{10}" === null)
    ) {
      setMobileNoErr(true);
      MobileNoE = 0;
    } else {
      MobileNoE = 1;
      setMobileNoErr(false);
    }
    if (
      TittleE === 1 &&
      DepartmentE === 1 &&
      EmailE === 1 &&
      //DesignE === 1 &&
      MobileNoE === 1 &&
      NameE === 1
    ) {
      Status = true;
    } else {
      Status = false;
    }
    return Status;
  };

  const btnclkSave = () => {
    if (AddContactValidation()) {
      let Designp;
      if (
        Design === null ||
        Design === "" ||
        Design === undefined ||
        Design === 0
      ) {
        Designp = "0";
      } else {
        Designp = Design;
      }

      axios
        .post(config.apiLink + "Alucast/AddContact", {
          Customerid: props.customerID.toString(),
          Tittle: Title,
          Name: Name,
          dept: Department,
          Design: Designp,
          email: Email,
          mobile: MobileNo,
        },)
        .then(
          (response) => {
            var Temp = response.data;
            var objSts = Temp["Status"][0];
            if (objSts.STATUS === "T") {
              Temp["ContactDet"].forEach(function (item, index) {
                Object.assign(item, {
                  BackgroungColor: "#fafafa",
                  ColorType: "",
                  ToHide: false,
                });
              });
              let newData = Temp["ContactDet"][0];
              props.ContactDet(newData);
              setShowAddContact(false);
              setTitle("");
              setName("");
              setDepartment("");
              setEmail("");
              setDesign("");
              setMobileNo("");
              // setLoadingCommon(false);
            } else {
              setContactExistsShow(true);
              // setLoadingCommon(false);
              setTimeout(function () {
                setContactExistsShow(false);
              }, 3000);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    };
  };

  const handleAddProductSave = () => {
    debugger;
    if (EnquiryID !== null) {

      const selectedbranddata = combinedArray.map((brand) => {
        return {
          BrandId: brand.BRANDID,
          ProductId: brand.PRODUCTID,
          ModelId: brand.MODELID,
          ModelRemarks: brand.REMARKS,
          ProductComments: brand.PRODUCT_REMARKS
        };
      });
      var param = {
        Brand: selectedbranddata,
        EnquiryId: EnquiryID,
        CreatedBy: EmpID,
      }

      axios.post(config.apiLink + "Alucast/AddEnquiryProduct", param).then(
        (response) => {
          var Temp = response.data;
          setAddProductDialog(false);
          setSelectedBrands([]);
          setSelectedModels([]);
          setSelectedModels([]);
          EnquiryCardListLoad(EnquiryID, EnquiryCardMemberID);
        },
        (error) => {
          console.log(error);
        }
      )
    }

  };
  const handleAddCmtSave = () => {
    debugger;
    if (productComment !== null && ProductId !== null) {


      var param = {
        Comment: productComment,
        ProductId: ProductId,
        CreatedBy: EmpID,
      }

      axios.post(config.apiLink + "Alucast/AddEnquiryProductComment", param).then(
        (response) => {
          var Temp = response.data;
          setAddCommentDialog(false);
          setProductId(null);
          setProductComment(null);
          EnquiryCardListLoad(EnquiryID, EnquiryCardMemberID);
        },
        (error) => {
          console.log(error);
        }
      )
    }

  };

  const handleNoteChange = (modelId, value) => {
    setModelNotes((prev) => ({
      ...prev,
      [modelId]: value,
    }));
  };

  const toggleSelection = (id, selectedList, setSelectedList) => {
    setSelectedList(
      (prevSelected) =>
        prevSelected.includes(id)
          ? prevSelected.filter((selectedId) => selectedId !== id) // Remove if already selected
          : [...prevSelected, id] // Add if not selected
    );
  };

  // Filtered Products based on Selected Brands
  const filteredProducts = useMemo(() => {
    return productDet.filter((product) =>
      selectedBrands.includes(product.BRANDID)
    );
  }, [selectedBrands, productDet]);

  // Filtered Models based on Selected Brands and Products
  const filteredModels = useMemo(() => {
    return modelDet.filter(
      (model) =>
        selectedBrands.includes(model.BRANDID) &&
        (selectedProducts.includes(model.PRODUCTID) || model.PRODUCTID === null)
    );
  }, [selectedBrands, selectedProducts, modelDet]);

  const groupedModels = useMemo(() => {
    return filteredModels.reduce((acc, model) => {
      const associatedBrand =
        brandDet.find((brand) => brand.BRANDID === model.BRANDID)?.BRANDNAME ||
        "Unknown Brand";
      const associatedProduct =
        productDet.find((product) => product.PRODUCTID === model.PRODUCTID)
          ?.PRODUCTNAME || "Unknown Product";
      const key = `${associatedBrand} | Product: ${associatedProduct}`;

      if (!acc[key]) {
        acc[key] = { associatedBrand, associatedProduct, models: [] };
      }
      acc[key].models.push(model);
      return acc;
    }, {});
  }, [filteredModels, brandDet, productDet]);

  const combinedArray = useMemo(() => {
    return selectedBrands.flatMap((brandId) => {
      const relatedProducts = selectedProducts.filter((productId) =>
        filteredProducts.some(
          (product) =>
            product.PRODUCTID === productId && product.BRANDID === brandId
        )
      );

      if (relatedProducts.length === 0) {
        const relatedModels = selectedModels.filter((modelId) =>
          filteredModels.some(
            (model) =>
              model.MODELID === modelId &&
              model.BRANDID === brandId &&
              (model.PRODUCTID === null || model.PRODUCTID === 0)
          )
        );

        if (relatedModels.length > 0) {
          return relatedModels.map((modelId) => ({
            BRANDID: brandId,
            PRODUCTID: 0,
            MODELID: modelId,
            REMARKS: modelNotes[modelId] || "",
            PRODUCT_REMARKS: "",
          }));
        }

        return [{ BRANDID: brandId, PRODUCTID: 0, MODELID: 0, REMARKS: "", PRODUCT_REMARKS: "" }];
      }

      return relatedProducts.flatMap((productId) => {
        const relatedModels = selectedModels.filter((modelId) =>
          filteredModels.some(
            (model) =>
              model.MODELID === modelId &&
              model.PRODUCTID === productId &&
              model.BRANDID === brandId
          )
        );

        const productRemarks = productNotes[productId] || "";

        if (relatedModels.length === 0) {
          return [
            {
              BRANDID: brandId,
              PRODUCTID: productId,
              MODELID: 0,
              REMARKS: "",
              PRODUCT_REMARKS: productRemarks,
            },
          ];
        }

        return relatedModels.map((modelId) => ({
          BRANDID: brandId,
          PRODUCTID: productId,
          MODELID: modelId,
          REMARKS: modelNotes[modelId] || "",
          PRODUCT_REMARKS: productRemarks,
        }));
      });
    });
  }, [
    selectedBrands,
    selectedProducts,
    selectedModels,
    filteredProducts,
    filteredModels,
    modelNotes,
    productNotes, // Add dependency
  ]);


  const handleMembershipCardDetailsEdit = (data) => {
    debugger;
    setEnquiryCardEditDet(data);
    setEnquiryCardEditDialog(true);
  };

  const handleEnquiryCardEditDialogClose = () => {
    setEnquiryCardEditDialog(false);
    setTimeout(() => {
      setEnquiryCardEditDet({});
    }, 1000);
  };

  const handleEnquiryCardEditResponse = () => {
    setEnquiryCardEditDialog(false);
    setLoadingCommon(true);
    setTimeout(() => {
      setEnquiryCardEditDet({});
      EnquiryCardListLoad(EnquiryID, EnquiryCardMemberID);
    }, 1000);
  };

  const btnAddConact = () => {
    // setMobileNoErr(false);
    // // setDesignErr(false);
    // setEmailErr(false);
    // setDepartmentErr(false);
    // setTitleErr(false);
    // setNameErr(false);
    // setTitle("");
    setName(enquiryCardCustomerDet[0]["OriginalData"].NAME);
    setCompany(props.Company);
    setEmail(enquiryCardCustomerDet[0]["OriginalData"].EMAIL);
    setDesign(enquiryCardCustomerDet[0]["OriginalData"].DESIGNATION);
    setMobileNo(enquiryCardCustomerDet[0]["OriginalData"].MOBILENO);
    // setbtnName("Save");
    setShowAddContact(true);
  };

  return (
    <div className="margin_top20">
      {!loadingCommon && (
        <>
          <DividerWithText>Customer&nbsp;Details</DividerWithText>
          <Card
            sx={{
              mb: 2,
              mt: 2,
              padding: "8px 16px",
              bgcolor: "#fefefe",
              border: "2px solid #005251",
              borderRadius: "10px",
              boxShadow:
                "0px 1px 8px rgba(0, 255, 255, 0.6)" /* Adjust the color to match */,
            }}
            className="flexCenterCenterHeight"
          >
            <Grid container>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Company Name
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {enquiryCardCustomerDet[0]["OriginalData"].COMPANYNAME}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    STATE
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {enquiryCardCustomerDet[0]["OriginalData"].STATE
                      ? enquiryCardCustomerDet[0]["OriginalData"].STATE
                      : "N/A"}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Address
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {enquiryCardCustomerDet[0]["OriginalData"].ADDRESS}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Card>
          <DividerWithText>Customer&nbsp;Contact&nbsp;Details</DividerWithText>
          <Grid container>
            {enquiryCardCustomerDet.map((row, index) => (
              <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                <Card
                  sx={{
                    padding: "8px 16px",
                    bgcolor: "#fefefe",
                    border: "2px solid #005251",
                    borderRadius: "10px",
                    boxShadow:
                      "0px 1px 8px rgba(0, 255, 255, 0.6)" /* Adjust the color to match */,
                  }}
                  className="flexCenterCenterHeight"
                >
                  <Grid container>
                    {row.FrontSideImage !== null && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography
                          align="left"
                          className="InterBold font_size18 color-616161"
                          sx={{ mb: 2 }}
                        >
                          Front Side
                        </Typography>
                        <div data-aos="flip-down">
                          <ImageWithBorders FileURL={row.FrontSideImage} />
                        </div>
                      </Grid>
                    )}
                    {row.BackSideImage !== null && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography
                          align="left"
                          className="InterBold font_size18 color-616161"
                          sx={{ mb: 2 }}
                        >
                          Back Side
                        </Typography>
                        <div data-aos="flip-down">
                          <ImageWithBorders FileURL={row.BackSideImage} />
                        </div>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 4 }}>
                      <Grid container>
                        <Grid item xs={12} sm={12} md={1} lg={1}>
                          <div className="gridStart">
                            <Typography
                              align="left"
                              className="InterRegular font_size16 color-636363"
                              gutterBottom
                            >
                              Sl No
                            </Typography>
                            <Typography
                              align="left"
                              className="InterMedium font_size18 color-161616"
                            >
                              {index + 1}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={3}>
                          <div className="gridStart">
                            <Typography
                              align="left"
                              className="InterRegular font_size16 color-636363"
                              gutterBottom
                            >
                              Customer Name
                            </Typography>
                            <Typography
                              align="left"
                              className="InterMedium font_size18 color-161616"
                            >
                              {row["OriginalData"].NAME}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <div className="gridStart">
                            <Typography
                              align="left"
                              className="InterRegular font_size16 color-636363"
                              gutterBottom
                            >
                              Designation
                            </Typography>
                            <Typography
                              align="left"
                              className="InterMedium font_size18 color-161616"
                            >
                              {row["OriginalData"].DESIGNATION}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={4}>
                          <div className="gridStart">
                            <Typography
                              align="left"
                              className="InterRegular font_size16 color-636363"
                              gutterBottom
                            >
                              Email
                            </Typography>
                            <Typography
                              align="left"
                              className="InterMedium font_size18 color-161616"
                            >
                              {row["OriginalData"].EMAIL}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <div className="gridStart">
                            <Typography
                              align="left"
                              className="InterRegular font_size16 color-636363"
                              gutterBottom
                            >
                              MobileNo
                            </Typography>
                            <Typography
                              align="left"
                              className="InterMedium font_size18 color-161616"
                            >
                              {row["OriginalData"].MOBILENO}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <div className="gridStart">
                            <Typography
                              align="left"
                              className="InterRegular font_size16 color-636363"
                              gutterBottom
                            >
                              Website
                            </Typography>
                            <Typography
                              align="left"
                              className="InterMedium font_size18 color-161616"
                            >
                              {row["OriginalData"].WEBSITE}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <div className="gridStart">
                            <Typography
                              align="left"
                              className="InterRegular font_size16 color-636363"
                              gutterBottom
                            >
                              Address
                            </Typography>
                            <Typography
                              align="left"
                              className="InterMedium font_size18 color-161616"
                            >
                              {row["OriginalData"].ADDRESS}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <div className="gridStart">
                            <Typography
                              align="left"
                              className="InterRegular font_size16 color-636363"
                              gutterBottom
                            >
                              Membership Card No
                            </Typography>
                            <Typography
                              align="left"
                              className="InterMedium font_size18 color-161616"
                            >
                              {row["OriginalData"].MEMBERSCARDNO}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={2}
                          lg={2}
                          sx={{ mt: 2 }}
                        >
                          <div className="flexEndCenterHeight">
                            <Button
                              variant="contained"
                              size="small"
                              sx={{
                                pl: 2,
                                pr: 2,
                                mr: 1,
                                textTransform: "capitalize",
                                bgcolor: "#fab005",
                                border: "1px solid #df9d05",
                                "&:hover": {
                                  bgcolor: "#e6a504", // Slightly darker shade
                                  opacity: 0.8, // Decrease opacity
                                },
                              }}
                              onClick={() =>
                                handleMembershipCardDetailsEdit(row)
                              }
                            >
                              <EditNoteOutlinedIcon sx={{ mr: 0.5 }} />{" "}
                              Edit
                            </Button>
                          </div>
                        </Grid>
                        {props.ContactDetList.length !== 0 && (
                          <Grid item xs={12} md={12} sm={12} lg={3}>
                            <div className="flexStartCenterHeight" style={{ marginTop: "7px" }}>
                              <Button
                                variant="contained"
                                size="small"
                                sx={{
                                  pl: 5,
                                  pr: 5,
                                  textTransform: "capitalize",
                                  bgcolor: "#fab005",
                                  border: "1px solid #df9d05",
                                  "&:hover": {
                                    bgcolor: "#e6a504", // Slightly darker shade
                                    opacity: 0.8, // Decrease opacity
                                  },
                                }}
                                onClick={btnAddConact}
                              >
                                <strong>Add New Contacts</strong>
                                <AddCircleOutlineIcon
                                  color="primary"
                                  style={{ marginLeft: "5px", color: "white" }}
                                />
                              </Button>
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ mt: 5 }}>
            <DividerWithText>Product&nbsp;Details</DividerWithText>

            <Box>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                  {enquiryDet.length !== 0 &&
                    enquiryDet.map((elem, ind) => (
                      <Grid container>
                        <Grid item xs={12} sm={12} md={9} lg={9}>
                          <Card
                            sx={{
                              mb: 2,
                              padding: "8px 16px",
                              bgcolor: elem.STATUS === 1 ? "#fefefe" : "#F87477",
                              border: "2px solid #005251",
                              borderRadius: "10px",
                              boxShadow:
                                "0px 1px 8px rgba(0, 255, 255, 0.6)" /* Adjust the color to match */,
                            }}
                          >
                            <Grid container>
                              <Grid item xs={12} sm={12} md={1} lg={1}>
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Sl No
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {ind + 1}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={2}>
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Brand Name
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {elem.BRANDNAME}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={2}>
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Model No
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {elem.MODELNO}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={2}>
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Product Name
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {elem.PRODUCTNAME}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Enquiry Description
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {elem.ENQUIRYDESCRIPTION}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                lg={3}
                                sx={{ mt: 0.5 }}
                              >
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Model Remarks
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {elem.MODELREMARKS}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                lg={3}
                                sx={{ mt: 0.5 }}
                              >
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Follow Up Date
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {moment(elem.FOLLOWUPDATE).format(
                                      "DD MMM YYYY hh:mm a"
                                    )}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={4}
                                lg={4}
                                sx={{ mt: 0.5 }}
                              >
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Lead Remarks
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {elem.LEADREMARKS}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={2}
                                lg={2}
                                sx={{ mt: 0.5 }}
                              >
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Created Date
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {moment(elem.CREATEDDATE).format(
                                      "DD MMM hh:mm a"
                                    )}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                lg={3}
                                sx={{ mt: 0.5 }}
                              >
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Applicable To
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {elem.APPLICABLETO}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={2}
                                lg={2}
                                sx={{ mt: 0.5 }}
                              >
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Status
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {elem.STATUS === 1 ? "Active" : "InActive"}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={2}
                                lg={2}
                                sx={{ mt: 0.5 }}
                              >
                                <div className="gridStart">
                                <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    
                                  </Typography>
                                <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                   {elem.HOTLEAD ===1 &&(
                                   <div className='glow-effect'>
                                   <Typography
                                     align="left"
                                     className="InterMedium font_size18 blink-effect"
                                   >
                                     Hot Lead
                                   </Typography>
                                 </div>
                                   )} 
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} key={elem.ind}>
                          {elem.STATUS === 1 && (
                            <div className="flexCenterCenterHeight">

                              <Button
                                variant="contained"
                                size="large"
                                sx={{
                                  pl: 2,
                                  pr: 2,
                                  ml: 1,
                                  textTransform: "capitalize",
                                  bgcolor: "#fab005",
                                  border: "1px solid #df9d05",
                                  "&:hover": {
                                    bgcolor: "#e6a504", // Slightly darker shade
                                    opacity: 0.8, // Decrease opacity
                                  },
                                }}
                                onClick={() => handleProductEdit(elem)}
                              >
                                <Typography className="InterRegular font_size12 color-ffffff">

                                  Edit Product
                                </Typography>
                              </Button>
                              <Button
                                variant="contained"
                                size="large"
                                sx={{
                                  pl: 2,
                                  pr: 2,
                                  ml: 1,
                                  textTransform: "capitalize",
                                  bgcolor: "#fab005",
                                  border: "1px solid #df9d05",
                                  "&:hover": {
                                    bgcolor: "#e6a504", // Slightly darker shade
                                    opacity: 0.8, // Decrease opacity
                                  },
                                }}
                                onClick={() => handleHotLead(elem)}
                              >
                                <Typography className="InterRegular font_size12 color-ffffff">
                                  Hot Lead
                                </Typography>
                              </Button>
                              <Button
                                variant="contained"
                                size="large"
                                sx={{
                                  pl: 2,
                                  pr: 2,
                                  ml: 1,
                                  textTransform: "capitalize",
                                  bgcolor: "#fab005",
                                  border: "1px solid #df9d05",
                                  "&:hover": {
                                    bgcolor: "#e6a504", // Slightly darker shade
                                    opacity: 0.8, // Decrease opacity
                                  },
                                }}
                                onClick={() => handleAddComment(elem)}
                              >
                                <Typography className="InterRegular font_size12 color-ffffff">
                                  Add Comment
                                </Typography>
                              </Button>
                              <Button
                                variant="contained"
                                size="large"
                                sx={{
                                  pl: 2,
                                  pr: 2,
                                  ml: 1,
                                  textTransform: "capitalize",
                                  bgcolor: "#fab005",
                                  border: "1px solid #df9d05",
                                  "&:hover": {
                                    bgcolor: "#e6a504", // Slightly darker shade
                                    opacity: 0.8, // Decrease opacity
                                  },
                                }}
                                onClick={() => handleViewComment(elem)}
                              >
                                <Typography className="InterRegular font_size12 color-ffffff">

                                  View Comment({elem.PRODUCTCOMMENTCOUNT})
                                </Typography>
                              </Button>

                            </div>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <div className="flexCenterCenterHeight">
                        <Button variant="contained"
                          size="large"
                          sx={{
                            pl: 2,
                            pr: 2,
                            ml: 2,
                            textTransform: "capitalize",
                            bgcolor: "#fab005",
                            border: "1px solid #df9d05",
                            "&:hover": {
                              bgcolor: "#e6a504", // Slightly darker shade
                              opacity: 0.8, // Decrease opacity
                            },
                          }}
                          onClick={() => handleProductAdd()}
                        >
                          Add Product
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      )}
      <Backdrop className="useStylesBackDrop" open={loadingCommon}>
        <CustomSpinner />
      </Backdrop>
      <div>
        <Dialog open={edtProductDialog} onClose={handleEdtDialogClose}
          PaperProps={{
            style: {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              maxWidth: '500px', // You can adjust the width as needed
            },
          }}>
          <DialogContent>
            Are you sure you want to Inactive this product?.
          </DialogContent>
          <DialogActions>
            <Button variant="contained"
              size="large"
              sx={{
                pl: 2,
                pr: 2,
                ml: 2,
                textTransform: "capitalize",
                bgcolor: "#fab005",
                border: "1px solid #df9d05",
                "&:hover": {
                  bgcolor: "#e6a504", // Slightly darker shade
                  opacity: 0.8, // Decrease opacity
                },
              }}
              onClick={() => handleEdtDialogClose()}
            >
              CANCEL
            </Button>
            <Button variant="contained"
              size="large"
              sx={{
                pl: 2,
                pr: 2,
                ml: 2,
                textTransform: "capitalize",
                bgcolor: "RED",
                border: "1px solid #df9d05",
                "&:hover": {
                  bgcolor: "DARKRED", // Slightly darker shade
                  opacity: 0.8, // Decrease opacity
                },
              }}
              onClick={() => handleDeleteProduct()}
            >
              INACTVE
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <Dialog open={addProductDialog} onClose={handleAddDialogClose}
          PaperProps={{
            style: {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              maxWidth: '800px', // You can adjust the width as needed
            },
          }}>
          <DialogContent>
            <Box sx={{ mt: 3 }}>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                style={{ marginTop: "20px" }}
              >
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"center"}
                  style={{ marginTop: "20px" }}
                >
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography
                          align="left"
                          className="InterMedium font_size18 color-fdd41c margin_left10"
                        >
                          Brand
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8} lg={8}>
                        <div>
                          {brandDet.map((brand) => (
                            <Button
                              key={brand.BRANDID}
                              style={{
                                backgroundColor: selectedBrands.includes(
                                  brand.BRANDID
                                )
                                  ? brandhovered === brand.BRANDID
                                    ? "darkred" // Hover effect
                                    : "red"
                                  : brandhovered === brand.BRANDID
                                    ? "darkgray" // Hover effect
                                    : "gray",
                                margin: "5px",
                                color: "white",
                              }}
                              onClick={() =>
                                toggleSelection(
                                  brand.BRANDID,
                                  selectedBrands,
                                  setSelectedBrands
                                )
                              }
                              onMouseEnter={() =>
                                setBrandHovered(brand.BRANDID)
                              }
                              onMouseLeave={() => setBrandHovered(null)}
                            >
                              {brand.BRANDNAME}
                            </Button>
                          ))}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Product Selection */}
                {selectedBrands.length > 0 && (
                  <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"center"}
                    style={{ marginTop: "20px" }}
                  >
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Typography
                            align="left"
                            className="InterMedium font_size18 color-fdd41c margin_left10"
                          >
                            Products
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                          <div>
                            {filteredProducts.map((product) => (
                              <div key={product.PRODUCTID} style={{ marginBottom: "10px" }}>
                                {/* Product Button */}
                                <Button
                                  style={{
                                    backgroundColor: selectedProducts.includes(product.PRODUCTID)
                                      ? producthovered === product.PRODUCTID
                                        ? "darkblue" // Hover effect
                                        : "blue"
                                      : producthovered === product.PRODUCTID
                                        ? "darkgray" // Hover effect
                                        : "gray",
                                    margin: "5px",
                                    color: "white",
                                  }}
                                  onClick={() =>
                                    toggleSelection(
                                      product.PRODUCTID,
                                      selectedProducts,
                                      setSelectedProducts
                                    )
                                  }
                                  onMouseEnter={() => setProductHovered(product.PRODUCTID)}
                                  onMouseLeave={() => setProductHovered(null)}
                                >
                                  {product.PRODUCTNAME}
                                </Button>

                                {/* Product TextField for Remarks */}
                                {selectedProducts.includes(product.PRODUCTID) && (
                                  <TextField
                                    multiline
                                    rows={2}
                                    variant="outlined"
                                    fullWidth
                                    placeholder={`Enter comments for ${product.PRODUCTNAME}`}
                                    value={productNotes[product.PRODUCTID] || ""}
                                    onChange={(e) =>
                                      setProductNotes((prev) => ({
                                        ...prev,
                                        [product.PRODUCTID]: e.target.value,
                                      }))
                                    }
                                    InputProps={{
                                      sx: {
                                        color: "Black", // Text color inside the input
                                      },
                                    }}
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: "#000000",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: "#000000",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#000000",
                                        },
                                        backgroundColor:
                                          "transparent",
                                      },
                                    }}
                                  />
                                )}
                              </div>
                            ))}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                )}

                {/* Model Selection */}
                {selectedBrands.length > 0 &&
                  Object.keys(groupedModels).length > 0 && (
                    <Grid
                      container
                      alignItems={"center"}
                      justifyContent={"center"}
                      style={{ marginTop: "20px" }}
                    >
                      <Grid item xs={12} sm={12} md={8} lg={8}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={4} lg={4}>
                            <Typography
                              align="left"
                              className="InterMedium font_size18 color-fdd41c margin_left10"
                            >
                              Models
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={8} lg={8}>
                            {Object.entries(groupedModels).map(
                              ([groupLabel, groupData]) => (
                                <div
                                  key={groupLabel}
                                  style={{ marginBottom: "20px" }}
                                >
                                  {/* Shared Label */}
                                  <Typography
                                    variant="body2"
                                    style={{
                                      fontWeight: "bold",
                                      marginBottom: "10px",
                                      color: "black",
                                    }}
                                  >
                                    Brand: {groupData.associatedBrand} |
                                    Product: {groupData.associatedProduct}
                                  </Typography>

                                  {/* Buttons for Models in the Group */}
                                  {groupData.models.map((model) => (
                                    <div
                                      key={model.MODELID}
                                      style={{ marginBottom: "15px" }}
                                    >
                                      <Button
                                        style={{
                                          backgroundColor:
                                            selectedModels.includes(
                                              model.MODELID
                                            )
                                              ? modelhovered ===
                                                model.MODELID
                                                ? "darkgreen" // Hover effect
                                                : "green"
                                              : modelhovered ===
                                                model.MODELID
                                                ? "darkgray" // Hover effect
                                                : "gray",
                                          margin: "5px",
                                          color: "white",
                                        }}
                                        onClick={() =>
                                          toggleSelection(
                                            model.MODELID,
                                            selectedModels,
                                            setSelectedModels
                                          )
                                        }
                                        onMouseEnter={() =>
                                          setModelHovered(model.MODELID)
                                        }
                                        onMouseLeave={() =>
                                          setModelHovered(null)
                                        }
                                      >
                                        {model.MODELNO}
                                      </Button>

                                      {/* TextField for Selected Model */}
                                      {selectedModels.includes(
                                        model.MODELID
                                      ) && (
                                          <TextField
                                            multiline
                                            rows={2}
                                            variant="outlined"
                                            fullWidth
                                            placeholder={`Enter notes for ${model.MODELNO}`}
                                            value={
                                              modelNotes[model.MODELID] ||
                                              ""
                                            }
                                            onChange={(e) =>
                                              handleNoteChange(
                                                model.MODELID,
                                                e.target.value
                                              )
                                            }
                                            InputProps={{
                                              sx: {
                                                color: "Black", // Text color inside the input
                                              },
                                            }}
                                            sx={{
                                              "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                  borderColor: "#000000",
                                                },
                                                "&:hover fieldset": {
                                                  borderColor: "#000000",
                                                },
                                                "&.Mui-focused fieldset": {
                                                  borderColor: "#000000",
                                                },
                                                backgroundColor:
                                                  "transparent",
                                              },
                                            }}
                                          />
                                        )}
                                    </div>
                                  ))}
                                </div>
                              )
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="contained"
              size="large"
              sx={{
                pl: 2,
                pr: 2,
                ml: 2,
                textTransform: "capitalize",
                bgcolor: "#fab005",
                border: "1px solid #df9d05",
                "&:hover": {
                  bgcolor: "#e6a504", // Slightly darker shade
                  opacity: 0.8, // Decrease opacity
                },
              }}
              onClick={() => handleAddProductSave()}
            >
              SAVE
            </Button>

          </DialogActions>
        </Dialog>
      </div>

      {ShowAddContact === true && (
        <Dialog
          //onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={ShowAddContact}
          className="modalBG"
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog_title_bg"
          >
            Add Contact
          </DialogTitle>
          <Tooltip title="Close">
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CancelIcon fontSize="large" style={{ color: "#ffffff" }} />
            </IconButton>
          </Tooltip>
          <DialogContent dividers>
            <Card>
              <CardContent>
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12} sm={12} lg={2}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel
                              required
                              id="demo-simple-select-outlined-label"
                            >
                              Title
                            </InputLabel>
                            <Select
                              fullWidth
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              required
                              value={Title}
                              error={TitleErr === true ? true : false}
                              label="Status"
                              onChange={(e) => {
                                setTitle(e.target.value);
                                setTitleErr(false);
                              }}
                            >
                              <MenuItem value="Mr.">Mr.</MenuItem>
                              <MenuItem value="Ms.">Ms.</MenuItem>
                              <MenuItem value="Dr.">Dr.</MenuItem>
                              <MenuItem value="M/s.">M/s.</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12} sm={12} lg={10}>
                          <TextField
                            label="Name"
                            variant="outlined"
                            required
                            value={Name}
                            error={NameErr === true ? true : false}
                            onChange={(e) => {
                              setName(e.target.value);
                              setNameErr(false);
                            }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12} sm={12} lg={6}>
                          <TextField
                            label="Designation"
                            variant="outlined"
                            //error={DesignErr === true ? true : false}
                            value={Design}
                            onChange={(e) => setDesign(e.target.value)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={12} sm={12} lg={6}>
                          <TextField
                            label="Department"
                            variant="outlined"
                            error={DepartmentErr === true ? true : false}
                            required
                            value={Department}
                            onChange={(e) => {
                              setDepartment(e.target.value);
                              setDepartmentErr(false);
                            }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                      <TextField
                        label="Company"
                        variant="outlined"
                        required
                        value={Company}
                        error={CompanyErr === true ? true : false}
                        disabled={true}
                        onChange={(e) => {
                          setCompany(e.target.value);
                          setCompanyErr(false);
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                      <TextField
                        label="MobileNo"
                        variant="outlined"
                        type="number"
                        required
                        inputProps={{ maxLength: 10 }}
                        error={MobileNoErr === true ? true : false}
                        value={MobileNo}
                        onChange={(e) => {
                          setMobileNo(e.target.value);
                          setMobileNoErr(false);
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                      <TextField
                        label="Email"
                        variant="outlined"
                        required
                        value={Email}
                        error={EmailErr === true ? true : false}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setEmailErr(false);
                        }}
                        fullWidth
                      />
                    </Grid>

                    {/* {btnName === "Update" && (
                      <Grid item xs={12} md={12} sm={12} lg={12}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel
                            required
                            id="demo-simple-select-outlined-label"
                          >
                            Status
                          </InputLabel>
                          <Select
                            fullWidth
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            required
                            value={ActStatus}
                            label="Status"
                            onChange={(e) => {
                              setActStatus(e.target.value);
                            }}
                          >
                            <MenuItem value="1">Active</MenuItem>
                            <MenuItem value="4">Inactive</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    )} */}
                  </Grid>
                </>
                {ContactExistsShow === true && (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    style={{ marginTop: "10px" }}
                  >
                    <Alert severity="error">
                      The above ContactDetails already exist, please add
                      something new
                    </Alert>
                  </Grid>
                )}
              </CardContent>
            </Card>
            <div
              className="flexEndCenterHeightCenter"
              style={{ padding: "20px" }}
            >
              <Button
                variant="contained"
                color="primary"
                className="schedule_btn"
                onClick={btnclkSave}
              >
                {btnName}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className="reject_btn"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}

      <Dialog open={addCommentDialog} onClose={handleCommentClose}
        PaperProps={{
          style: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "800px", // Adjust width as needed
            height: "500px", // Adjust height as needed
            maxWidth: "90%", // Ensure responsiveness
            maxHeight: "90%", // Ensure responsiveness
          },
        }}>
        <DialogTitle>
          <Typography align="left"
            className="InterBold font_size18 color-616161"
            sx={{ mb: 2 }}>

            Product Comment
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 3 }}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  multiline
                  rows={5}
                  variant="outlined"
                  fullWidth
                  placeholder="Enter your comments"
                  value={productComment}
                  onChange={(e) =>
                    setProductComment(e.target.value)
                  }
                  inputProps={{
                    maxLength: 500, // Limit to 500 characters
                  }}
                  InputProps={{
                    sx: {
                      color: "Black", // Text color inside the input

                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#000000",
                      },
                      "&:hover fieldset": {
                        borderColor: "#000000",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#000000",
                      },
                      backgroundColor:
                        "transparent",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained"
            size="large"
            sx={{
              pl: 2,
              pr: 2,
              ml: 2,
              textTransform: "capitalize",
              bgcolor: "#fab005",
              border: "1px solid #df9d05",
              "&:hover": {
                bgcolor: "#e6a504", // Slightly darker shade
                opacity: 0.8, // Decrease opacity
              },
            }}
            onClick={() => handleAddCmtSave()}
          >
            ADD
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={hotLeadDialog} onClose={handleHotLeadClose}
        PaperProps={{
          style: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "500px", // Adjust width as needed

          },
        }}>
        <DialogContent>
          Are you sure you want to add this product as Hot Lead?.
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>

              <div className="flexCenterCenterHeight">
                <Button variant="contained"
                  size="large"
                  sx={{
                    pl: 2,
                    pr: 2,
                    ml: 2,
                    textTransform: "capitalize",
                    bgcolor: "#fab005",
                    border: "1px solid #df9d05",
                    "&:hover": {
                      bgcolor: "#e6a504", // Slightly darker shade
                      opacity: 0.8, // Decrease opacity
                    },
                  }}
                  onClick={() => handleHotLeadProduct()}
                >
                  Yes
                </Button>
                <Button variant="contained"
                  size="large"
                  sx={{
                    pl: 2,
                    pr: 2,
                    ml: 2,
                    textTransform: "capitalize",
                    bgcolor: "#fab005",
                    border: "1px solid #df9d05",
                    "&:hover": {
                      bgcolor: "#e6a504", // Slightly darker shade
                      opacity: 0.8, // Decrease opacity
                    },
                  }}
                  onClick={() => handleHotLeadClose()}
                >
                  No
                </Button>
              </div>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {enquiryCardEditDialog && (
        <EditEnquiryCardMember
          BusinessCardDetails={enquiryCardEditDet}
          onClose={handleEnquiryCardEditDialogClose}
          onOk={handleEnquiryCardEditResponse}
          PageName="Reprint"
        />
      )}

      {viewComment && (
        <div>
          <Dialog open={viewComment} onClose={handleViewCommentClose}
            PaperProps={{
              style: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: "800px", // Adjust width as needed
                height: "500px", // Adjust height as needed
                maxWidth: "90%", // Ensure responsiveness
                maxHeight: "90%", // Ensure responsiveness
              },
            }}>
            <DialogTitle>
              <Typography align="left"
                className="InterBold font_size18 color-616161"
                sx={{ mb: 2 }}>

                Product Comment List
              </Typography>
            </DialogTitle>
            <DialogContent>
              <ProductCommentsList ProductId={ProductId} />
            </DialogContent>

          </Dialog>
        </div>

      )}

    </div>

  );
}

export default EnquiryCardProductCustomerDisplay;
