import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios'; // Import Axios for making HTTP requests

function QuillEditor({onchangecontent}) {
  const [value, setValue] = useState('');

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['link', 'image', 'video', 'formula']
  ];

  const modules = {
    toolbar: toolbarOptions,
  };

  const quillStyle = {
    border: 'none',
    height: '270px',
    width: '100%'
  };

  const handleChange = (content, delta, source, editor) => {
    setValue(content);
   onchangecontent(content)
    
  };

  // const handleSubmit = async () => {
  //   try {
  //     // Make an HTTP POST request to your API endpoint
  //     const response = await axios.post('https://example.com/api/content', {
  //       content: value
  //     });

  //     console.log('Response:', response.data);
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };

  return (
    <div>
      <ReactQuill
        theme="snow"
        style={quillStyle}
        value={value}
        onChange={handleChange}
        modules={modules}
        placeholder="Enter the text..."
      />
      {/* <button onClick={handleSubmit}>Submit</button> */}
    </div>
  );
}

export default QuillEditor;
