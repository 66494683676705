import { Card, CardContent, Typography, Avatar } from '@mui/material';
import Grid from '@mui/material/Grid';
// import useStyles from '../features/styles'
import Checkbox from '@mui/material/Checkbox';
import React, { useState, useEffect } from "react";
import { useLocation,useNavigate} from "react-router-dom";

import Link from '@mui/material/Link';
import AlertSelectTagBox from '../../Components/Mailer/AlertSelectTagBox';
import axios from "axios";




const CustomerTag = (props) => {

    // const classes = useStyles();
    const location = useLocation();
    const [region, setregion] = useState([])
    const [openin,setopenin] = useState(false);
    const [ShowAlertSelect, setShowAlertSelect] = useState(false);
    const [ShowAlertSelectData, setShowAlertSelectData] = useState("");
    const [Statetag, setStatetag] = useState([]);
    const [Customertag, setCustomertag] =React.useState([]);
    const [TagName, setTagName] =React.useState("");
    const [TagID, setTagID] =React.useState("");


    useEffect(() => {
        debugger;
        setregion(props.CustomerObj);
        console.log(region)

    }, [props.CustomerObj]);
    const handleOnChangeRegion = (event, index) => {
        debugger;
        let GetCustomerDetails = [...region];
        region.map((data) => {
            GetCustomerDetails[index].Status = event.target.checked;
        });
        console.log(GetCustomerDetails)
        setregion(GetCustomerDetails);
        props.getdata(GetCustomerDetails);
    };
    const OpenTagList = (e, elem) =>{
       
        console.log(elem);
        setTagName(elem.TAGNAME)
        setTagID(elem.TAGID)
        //setopenin(true);
        axios.post("http://localhost:6720/Dashboard/SelectCustomerStateTag",
            //  axios.post(config.apiLink +'Dashboard/SearchCustomer',
            {
                
                TagId:elem.TAGID,
            },
            {
            }).then(
                (response) => {
                var Temp = response.data;
                setShowAlertSelect(true);
                //setCustomertagId(Temp)
                setStatetag(Temp['StateTable'])
                setCustomertag(Temp['Customertable'])
                
                },
                //Incase failure the function, identify the What type of error shown
                (error) => {
                console.log(error);
                }
            );
        
        
        
        // region.map((elem) => {
           
        // });
      
    }
    const handlePopup = () => {
        setShowAlertSelect(false);
    }
    return (
        <div style={{overflowY:'auto',maxHeight:'300px'}}>

            {region.map((elem, index) => (<Grid item lg={12}>
                <Card  style={{margin:'10px'}} >
                    <Grid container>
                        <Grid md={3} lg={4} className='flexCenterCenter'>
                        <Avatar src="/images.png" />
                        </Grid>
                        <Grid md={3} lg={4} className='flexCenterCenter'>
                        <div >
                        <Link  onClick={(e) => OpenTagList(e, elem)} style={{font:'bold'}}
                        variant="h7"
                        component="h5"
                    >{elem.TAGNAME}</Link>
                        </div>
                        </Grid>
                       <Grid md={3} lg={4} className='flexCenterCenter'>
                       <CardContent>
                        
                      
                        <div  >
                            <Checkbox
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                value={elem.TAGID}
                                onChange={(e) =>  handleOnChangeRegion(e, index)}
                            />
                        </div>
                    </CardContent>
                       </Grid>
                        
                    </Grid>
                   
                </Card>

            </Grid>))}
            {ShowAlertSelect &&
                <AlertSelectTagBox 
                open={ShowAlertSelect} 
                onClose={handlePopup}
                 Data={TagName} 
                 
                 Data1={Statetag} 
                 Data2={Customertag} 
                 TagID={TagID}
                />
             } 
        </div>
    );
}
export default CustomerTag