import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import config from "../../Common/config";
import ImageWithBorders from "./ImageWithBorders";
import CancelIcon from "@mui/icons-material/Cancel";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    borderRadius: "20px", // This changes the border radius of the dialog paper
  },
}));

export default function EditEnquiryCardMember(props) {
  var EmpID = localStorage.getItem("EMPID");
  const { onClose, BusinessCardDetails, onOk, PageName } = props;
  var MemberDetails = BusinessCardDetails["OriginalData"];
  var NameParam = PageName === "Reprint" ? MemberDetails.NAME === null ? "" : MemberDetails.NAME : 
  MemberDetails.CONTACTNAME === null ? "" : MemberDetails.CONTACTNAME;
  
  const [Name, setName] = useState(NameParam);
  const [MobileNo, setMobileNo] = useState(
    MemberDetails.MOBILENO === null ? "" : MemberDetails.MOBILENO
  );
  const [Email, setEmail] = useState(
    MemberDetails.EMAIL === null ? "" : MemberDetails.EMAIL
  );
  const [CompanyName, setCompanyName] = useState(
    MemberDetails.COMPANYNAME === null ? "" : MemberDetails.COMPANYNAME
  );
  const [Designation, setDesignation] = useState(
    MemberDetails.DESIGNATION === null ? "" : MemberDetails.DESIGNATION
  );
  const [Address, setAddress] = useState(
    MemberDetails.ADDRESS === null ? "" : MemberDetails.ADDRESS
  );
  const [Website, setWebsite] = useState(
    MemberDetails.WEBSITE === null ? "" : MemberDetails.WEBSITE
  );
  const [NameErr, setNameErr] = useState("");
  const [MobileNoErr, setMobileNoErr] = useState("");
  const [EmailErr, setEmailErr] = useState("");
  const [CompanyNameErr, setCompanyNameErr] = useState("");
  const [DesignationErr, setDesignationErr] = useState("");
  const [AddressErr, setAddressErr] = useState("");
  const [WebsiteErr, setWebsiteErr] = useState("");

  //Save New Contact Btn
  const [SaveBtnName, setSaveBtnName] = useState("Update");
  const [SaveBtnLoading, setSaveBtnLoading] = useState(false);
  const [SaveBtnSuccess, setSaveBtnSuccess] = useState(false);
  const [SaveBtnFailure, setSaveBtnFailure] = useState(false);
  const [SaveBtnDisabled, setSaveBtnDisabled] = useState(false);

  const handleClose = () => {
    onClose("Close");
  };

  const EnquiryCardDetailsUpdate = () => {
    debugger;
    var param = {
      Name: Name,
      MobileNo: MobileNo,
      email: Email,
      CompanyName: CompanyName,
      Designation: Designation,
      Address: Address,
      Website: Website,
      EnquiryCardMemberID: MemberDetails.ENQUIRYCARDMEMBERSID,
      EmpID: EmpID
    };
    if(PageName === "Reprint"){
      param = {
        ...param,
        MembershipCardID: MemberDetails.MEMBERSHIPCARDID
      }
    }
    axios
      .post(config.apiLink + "Alucast/EnquiryCardDetailsUpdate", param)
      .then(
        (response) => {
          debugger;
           var Temp = response.data;
          setSaveBtnName("Updated");
          setSaveBtnLoading(false);
          setSaveBtnSuccess(true);
          setTimeout(() => {
            onOk();
          }, 1000);
          setTimeout(() => {
            setSaveBtnName("Update");
            setSaveBtnFailure(false);
            setSaveBtnLoading(false);
            setSaveBtnDisabled(false);
            setSaveBtnSuccess(false);
          }, 4000);
        },
        (error) => {
          console.log(error);
          setSaveBtnSuccess(false);
          setSaveBtnFailure(true);
          setSaveBtnLoading(false);
          setSaveBtnDisabled(false);
          setSaveBtnName("Error Occured - Resubmit");
        }
      );
  };

  const validate = () => {
    let formIsValid = true;
    if (Name === "") {
      formIsValid = false;
      setNameErr(true);
    } else {
      setNameErr(false);
    }
    if (MobileNo === "") {
      formIsValid = false;
      setMobileNoErr(true);
    } else {
      setMobileNoErr(false);
    }
    // const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    // || regex.test(Email) === false
    if (Email === "") {
      formIsValid = false;
      setEmailErr(true);
    } else {
      setEmailErr(false);
    }
    if (CompanyName === "") {
      formIsValid = false;
      setCompanyNameErr(true);
    } else {
      setCompanyNameErr(false);
    }
    if (Designation === "") {
      formIsValid = false;
      setDesignationErr(true);
    } else {
      setDesignationErr(false);
    }
    if (Address === "") {
      formIsValid = false;
      setAddressErr(true);
    } else {
        setAddressErr(false);
    }
    if (Website === "") {
      formIsValid = false;
      setWebsiteErr(true);
    } else {
      setWebsiteErr(false);
    }
    return formIsValid;
  };

  const handleSave = () => {
    if (validate()) {
      setSaveBtnName("Updating...");
      setSaveBtnFailure(false);
      setSaveBtnLoading(true);
      setSaveBtnDisabled(true);
      EnquiryCardDetailsUpdate();
    }
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#054b4b",
            padding: "0px 0px 0px 10px",
          }}
        >
          <Grid container>
          <Grid item xs={2} sm={2} lg={2} md={2}></Grid>
            <Grid item xs={8} sm={8} lg={8} md={8}>
              <div className="flexCenterCenterHeight">
                <Typography
                  align="center"
                  className="font_size16 JustSansBold color-ffffff padding10"
                >
                  Edit Business Card Details
                </Typography>
              </div>
            </Grid>
            <Grid item xs={2} sm={2} lg={2} md={2}>
            <div className="flexEndCenterHeight">
                <Tooltip title="Close">
                  <IconButton onClick={handleClose} sx={{color: "#ffffff"}}>
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ padding: "0px" }}>
          <Card
            sx={{
              border: "2px solid #bee3ff",
              bgcolor: "#f0f8ff",
              pb: 0.5,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box sx={{ bgcolor: "#f0f8ff", p: 1, overflowX: "auto" }}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <div className="gridStart padding5_10">
                        <Typography
                          align="left"
                          className="InterRegular font_size10 color-8f8f8f"
                          gutterBottom
                        >
                          Name
                        </Typography>
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-multiline-static"
                            // inputProps={{ maxLength: 10 }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#dbeaf5",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#dbeaf5",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#101f33",
                                },
                                backgroundColor: "transparent",
                              },
                            }}
                            value={Name}
                            error={NameErr}
                            onChange={(e) => {
                              const value = e.target.value;
                              setName(value);
                              setNameErr(false);
                            }}
                            placeholder="Enter Name"
                            variant="standard"
                          />
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 1 }}>
                      <div className="gridStart padding5_10">
                        <Typography
                          align="left"
                          className="InterRegular font_size10 color-8f8f8f"
                          gutterBottom
                        >
                          Mobile Number
                        </Typography>
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-multiline-static"
                            // inputProps={{ maxLength: 10 }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#dbeaf5",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#dbeaf5",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#101f33",
                                },
                                backgroundColor: "transparent",
                              },
                            }}
                            value={MobileNo}
                            error={MobileNoErr}
                            onChange={(e) => {
                              setMobileNo(e.target.value);
                              setMobileNoErr(false);
                            }}
                            placeholder="Enter Mobile Number"
                            variant="standard"
                          />
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 1 }}>
                      <div className="gridStart padding5_10">
                        <Typography
                          align="left"
                          className="InterRegular font_size10 color-8f8f8f"
                          gutterBottom
                        >
                          Email
                        </Typography>
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-multiline-static"
                            // inputProps={{ maxLength: 10 }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#dbeaf5",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#dbeaf5",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#101f33",
                                },
                                backgroundColor: "transparent",
                              },
                            }}
                            value={Email}
                            error={EmailErr}
                            onChange={(e) => {
                              const value = e.target.value;
                              setEmail(value);
                              setEmailErr(false);
                            }}
                            placeholder="Enter Email"
                            variant="standard"
                          />
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 1 }}>
                      <div className="gridStart padding5_10">
                        <Typography
                          align="left"
                          className="InterRegular font_size10 color-8f8f8f"
                          gutterBottom
                        >
                          Designation
                        </Typography>
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-multiline-static"
                            // inputProps={{ maxLength: 10 }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#dbeaf5",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#dbeaf5",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#101f33",
                                },
                                backgroundColor: "transparent",
                              },
                            }}
                            value={Designation}
                            error={DesignationErr}
                            onChange={(e) => {
                              const value = e.target.value;
                              setDesignation(value);
                              setDesignationErr(false);
                            }}
                            placeholder="Enter Designation"
                            variant="standard"
                          />
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 1 }}>
                      <div className="gridStart padding5_10">
                        <Typography
                          align="left"
                          className="InterRegular font_size10 color-8f8f8f"
                          gutterBottom
                        >
                          CompanyName
                        </Typography>
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-multiline-static"
                            // inputProps={{ maxLength: 10 }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#dbeaf5",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#dbeaf5",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#101f33",
                                },
                                backgroundColor: "transparent",
                              },
                            }}
                            value={CompanyName}
                            error={CompanyNameErr}
                            onChange={(e) => {
                              const value = e.target.value;
                              setCompanyName(value);
                              setCompanyNameErr(false);
                            }}
                            placeholder="Enter CompanyName"
                            variant="standard"
                          />
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 1 }}>
                      <div className="gridStart padding5_10">
                        <Typography
                          align="left"
                          className="InterRegular font_size10 color-8f8f8f"
                          gutterBottom
                        >
                          Website
                        </Typography>
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-multiline-static"
                            // inputProps={{ maxLength: 10 }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#dbeaf5",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#dbeaf5",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#101f33",
                                },
                                backgroundColor: "transparent",
                              },
                            }}
                            value={Website}
                            error={WebsiteErr}
                            onChange={(e) => {
                              const value = e.target.value;
                              setWebsite(value);
                              setWebsiteErr(false);
                            }}
                            placeholder="Enter Website"
                            variant="standard"
                          />
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 1 }}>
                      <div className="gridStart padding5_10">
                        <Typography
                          align="left"
                          className="InterRegular font_size10 color-8f8f8f"
                          gutterBottom
                        >
                          Address
                        </Typography>
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-multiline-static"
                            // inputProps={{ maxLength: 10 }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#dbeaf5",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#dbeaf5",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#101f33",
                                },
                                backgroundColor: "transparent",
                              },
                            }}
                            multiline
                            rows={2}
                            value={Address}
                            error={AddressErr}
                            onChange={(e) => {
                              const value = e.target.value;
                              setAddress(value);
                              setAddressErr(false);
                            }}
                            placeholder="Enter Address"
                            variant="standard"
                          />
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                      <div className="Wrapper">
                        <Button
                          variant="contained"
                          className="SaveBtn"
                          fullWidth
                          onClick={handleSave}
                          disabled={SaveBtnDisabled}
                          style={{
                            backgroundColor: SaveBtnFailure
                              ? "#ce532d"
                              : SaveBtnDisabled
                              ? SaveBtnSuccess
                                ? "#4CAF50"
                                : ""
                              : "#f7b22c",
                          }}
                        >
                          {SaveBtnName}
                        </Button>
                        {SaveBtnLoading && (
                          <CircularProgress
                            size={24}
                            // className={classes.buttonProgress}
                            style={{
                              color: "green",
                              position: "absolute",
                              top: "60%",
                              left: "50%",
                              marginTop: -16,
                              marginLeft: -10,
                            }}
                          />
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                      <Button
                        variant="text"
                        sx={{ color: "#054b4b" }}
                        fullWidth
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Divider
                orientation="vertical"
                flexItem
                sx={{ margin: "0 8px", backgroundColor: "#e0e0e0" }}
              />
              <Grid item xs={12} sm={12} md={5} lg={5}>
                <Box sx={{ pt: 2, pb: 2 }}>
                  <Grid container>
                    {BusinessCardDetails.FrontSideImage !== null && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography
                          align="left"
                          className="InterBold font_size18 color-616161"
                          sx={{ mb: 2 }}
                        >
                          Front Side
                        </Typography>
                        <div data-aos="flip-down">
                          <ImageWithBorders
                            FileURL={BusinessCardDetails.FrontSideImage}
                          />
                        </div>
                      </Grid>
                    )}
                    {BusinessCardDetails.BackSideImage !== null && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography
                          align="left"
                          className="InterBold font_size18 color-616161"
                          sx={{ mb: 2 }}
                        >
                          Back Side
                        </Typography>
                        <div data-aos="flip-down">
                          <ImageWithBorders
                            FileURL={BusinessCardDetails.BackSideImage}
                          />
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
