import React, { useState, useRef, useEffect } from 'react';
import { Grid, Typography, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Close, Delete } from '@mui/icons-material';
import axios from 'axios';
import config from '../Common/config';
import { isMobile } from 'react-device-detect';

const SelfieUploader = (props) => {
  // debugger;
  const{EnquiryId}=props;
  const [hasPermission, setHasPermission] = useState(false);
  const [images, setImages] = useState([]); // Renamed for clarity
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [cameraStream, setCameraStream] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null); // For dialog
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    // Request access to the front camera
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: 'user' } })
      .then((stream) => {
        setHasPermission(true);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          setCameraStream(stream);
          setIsCameraActive(true);
        }
      })
      .catch((err) => {
        console.error('Error accessing the camera: ', err);
        setHasPermission(false);
      });

    // Cleanup on unmount
    return () => {
      const stream = videoRef.current?.srcObject;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, []);

  const captureSelfie = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    // Draw the current frame of the video onto the canvas
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Get the image data as a base64 string
    const dataUrl = canvas.toDataURL('image/png');
    setImages((prevImages) => [...prevImages, dataUrl]);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const maxSize = 5 * 1024 * 1024; // 5 MB
  
    files.forEach((file) => {
      if (file.size > maxSize) {
        alert(`${file.name} is too large. Max file size is 5 MB.`);
        return;
      }
  
      const reader = new FileReader();
      reader.onload = () => {
        setImages((prevImages) => [...prevImages, reader.result]);
      };
      reader.readAsDataURL(file);
    });
  };
  

  const handleRemoveImage = (imageToRemove) => {
    setImages((prevImages) => prevImages.filter((img) => img !== imageToRemove));
    setSelectedImage(null); // Close the dialog after deleting
  };

  const handleImageClick = (image) => {
    debugger;
    setSelectedImage(image); // Open dialog with the selected image
  };

  const handleDialogClose = () => {
    setSelectedImage(null); // Close the dialog
  };

  const handleEnquiryFinalSave = () => {
    EnquiryFinalSave();
  }
      

  const EnquiryFinalSave=()=>{
    // debugger;
    var param = {
       EnquiryId:EnquiryId,
       Images: images.map((img) => img.split(",")[1]), 
   }

    axios.post(config.apiLink+"Alucast/EnquiryFinalSave",param).then(
      (response) => {
        // debugger;
        var Temp = response.data;
        setImages([]);
    },
    (error) => {
        console.log(error);
    }
    )
}

  return (
    <div>
      <Grid container alignItems="center" justifyContent="center" style={{ marginTop: '20px' }}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography>Selfie</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              {hasPermission ? (
                <div>
                  <div style={{ display: 'inline-block' }}>
                    <video
                      ref={videoRef}
                      autoPlay
                      playsInline
                      width="100%"
                      height="auto"
                      style={{
                        border: '2px solid black',
                        borderRadius: '8px',
                        marginTop:isMobile?"15px":"",
                        display: isCameraActive ? 'block' : 'none',
                      }}
                    />
                    {/* Capture button */}
                    {isCameraActive && (
                      <div>
                        <Button
                          onClick={captureSelfie}
                          style={{
                            backgroundColor: "#054b4b",
                            color: "white",
                            margin: "10px 0",
                            cursor: "pointer",
                        }}
                        >
                          Capture
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <p>Camera access denied. Please grant permission to use the front camera.</p>
              )}

              <Button variant="contained" component="label" className="margin_top20" style={{
                            backgroundColor: "#054b4b",
                            color: "white",
                            margin: "10px 0",
                            cursor: "pointer",
                        }}>
                Upload from Gallery
                <input type="file" accept="image/*" multiple onChange={handleFileChange} hidden />
              </Button>

              {/* Display captured or uploaded images */}
              {images.length > 0 && (
                <Grid container spacing={0} style={{ marginTop: '20px' }} justifyContent="flex-start">
                  {/* Display captured or uploaded images */}
                  {images.length > 0 && (
                    <Grid
                      container
                      style={{ marginTop: '20px' }}
                      justifyContent="flex-start"
                      spacing={0} // Removes extra gaps from Material-UI Grid
                    >
                      {images.map((img, index) => (
                        <Grid
                          item
                          xs={4} // Adjust this to control how many images per row
                          sm={4}
                          md={4}
                          lg={4}
                          key={index}
                          style={{ padding: '10px' }} // Add a consistent gap between items
                        >
                          <div style={{ position: 'relative' }}>
                            <img
                              src={img}
                              alt={`Captured or uploaded ${index}`}
                              style={{
                                width: '90px', // Takes the full width of its container
                                height: '100px', // Ensure consistent height
                                objectFit: 'cover', // Crop images to fill the space
                                borderRadius: '8px',
                              }}
                              onClick={() => handleImageClick(img)}
                            />
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  )}

                </Grid>
              )}

              {/* Hidden canvas for capturing the selfie */}
              <canvas ref={canvasRef} style={{ display: 'none' }} />

              <Grid container alignItems={"center"} justifyContent={"center"} style={{ marginTop: "20px" }}>
                <div>
                    <Button
                        onClick={() => handleEnquiryFinalSave()}
                        style={{
                            backgroundColor: "#054b4b",
                            color: "white",
                            margin: "10px 0",
                            cursor: "pointer",
                        }}
                    >
                        Save
                    </Button>
                </div>
            </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div>
      <Dialog open={!!selectedImage} onClose={handleDialogClose}>
        <DialogTitle>
          Image preview
          <IconButton
            style={{ position: "absolute", top: 8, right: 8 }}
            onClick={handleDialogClose}
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <img
            src={selectedImage}
            alt="Selected"
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "400px",
              objectFit: "contain",
              borderRadius: "8px",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleRemoveImage(selectedImage)}
            color="secondary"
            variant="contained"
            style={{
              backgroundColor: "#054b4b",
              color: "white",
              margin: "10px 0",
              cursor: "pointer",
          }}
            startIcon={<Delete />}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      </div>
    </div>
  );
};

export default SelfieUploader;
