import {
  Backdrop,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import DividerWithText from "./Components/DividerWithText";
import UpdateIcon from "@mui/icons-material/Update";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import config from "../Common/config";
import CustomSpinner from "../Common/CustomSpinner";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import EnquiryCardProductCustomerDisplay from "./Components/EnquiryCardProductCustomerDisplay";

function QuoteStatusUpdate() {
  var EmpID = localStorage.getItem("EMPID");
  const location = useLocation();
  const history = useNavigate();
  const [loadingCommon, setLoadingCommon] = useState(true);
  const [afterLoadingCommon, setAfterLoadingCommon] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [remarksErr, setRemarksErr] = useState(false);
  const [quoteStatus, setQuoteStatus] = useState(false);
  const [customerDet, setCustomerDet] = useState({});
  const [enquiryDet, setEnquiryDet] = useState([]);
  const [ContactDet, setContactDet] = useState([]);
  const [Company, setCompany] = useState("");

  React.useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-in-out", // Easing function
      once: true, // Whether animation should happen only once or every time you scroll
      offset: 100, // Trigger animation when 200px from the element
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top-left corner
  }, []);

  const CustomerDetailsLoad = useCallback((ID) => {
    axios
      .post(config.apiLink + "Alucast/CustomerDetailsLoad", {
        CustomerID: ID,
        EnquiryID: location.state.params.ENQUIRYID,
      })
      .then(
        (response) => {
          var Temp = response.data;
          setCustomerDet(Temp["CustomerDet"][0]);
          setEnquiryDet(Temp["EnquiryDet"]);
          setLoadingCommon(false);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  useEffect(() => {
    CustomerDetailsLoad(location.state.params.CUSTOMERID);
  }, [CustomerDetailsLoad, location.state]);

  const EnquiryQuoteStatusSave = () => {
    axios
      .post(config.apiLink + "Alucast/EnquiryQuoteStatusSave", {
        EnquiryID: location.state.params.ENQUIRYID,
        QuoteStatus: quoteStatus ? 1 : 0,
        Remarks: remarks,
        EmpID: EmpID,
      })
      .then(
        (response) => {
          var Temp = response.data;
          if (Temp === "Success") {
            history("/EnquiryFromCardList");
          }
        },
        (error) => {
          console.log(error);
          setAfterLoadingCommon(false);
        }
      );
  };

  const validate = () => {
    let formIsValid = true;
    if (remarks === "") {
      formIsValid = false;
      setRemarksErr(true);
    } else {
      setRemarksErr(false);
    }
    return formIsValid;
  };

  const handleQuoteStatusSave = () => {
    if (validate()) {
      setAfterLoadingCommon(true);
      EnquiryQuoteStatusSave();
    }
  };

  const handleContactDet = (newContact) => {
    debugger;
    setContactDet((prevContacts) => [...prevContacts, newContact]);
  };

  return (
    <div className="body_margin_Enquiry">
      {!loadingCommon && (
        <>
          <Card
            // data-aos="fade-up"
            sx={{
              padding: "32px 24px 24px",
              borderRadius: "10px",
              border: "1px solid #d4e6e6",
              boxShadow: 0,
              mt: 1,
              mb: 2,
            }}
          >
            <Grid container alignItems={"center"}>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Enquiry No
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {location.state.params.ENQUIRYNO}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Mapped Customer Name
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {location.state.params.CUSTOMERNAME}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Lead Name
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {location.state.params.LEADNAME}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Follow Up Date
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {moment(location.state.params.FOLLOWUPDATE).format(
                      "DD MMM hh:mm a"
                    )}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Created Time
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {moment(location.state.params.CREATEDTIME).format(
                      "DD MMM hh:mm a"
                    )}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Enquiry Description
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {location.state.params.ENQUIRYDESCRIPTION}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Lead Remarks
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {location.state.params.LEADREMARKS}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Created By
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {location.state.params.CREATEDBY}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Card>
          <EnquiryCardProductCustomerDisplay
            EnquiryID={location.state.params.ENQUIRYID}
            ContactDet={handleContactDet}
            customerID={location.state.params.CUSTOMERID}
            Company={Company}
            ContactDetList={ContactDet}
          />

          <DividerWithText>Mapped&nbsp;Customer&nbsp;Details</DividerWithText>

          <Box sx={{ mb: 4 }}>
            <Grid container justifyContent={"center"}>
              <Grid item xs={12} sm={12} md={2} lg={2}></Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                <Card
                  sx={{
                    padding: "8px 16px",
                    bgcolor: "#fefefe",
                    border: "2px solid #005251",
                    borderRadius: "10px",
                    boxShadow:
                      "0px 1px 8px rgba(0, 255, 255, 0.6)" /* Adjust the color to match */,
                  }}
                  className="flexCenterCenterHeight"
                >
                  <Grid container>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <div className="gridStart">
                        <Typography
                          align="left"
                          className="InterRegular font_size16 color-636363"
                          gutterBottom
                        >
                          Customer Name
                        </Typography>
                        <Typography
                          align="left"
                          className="InterMedium font_size18 color-161616"
                        >
                          {customerDet.FIRSTNAME}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <div className="gridStart">
                        <Typography
                          align="left"
                          className="InterRegular font_size16 color-636363"
                          gutterBottom
                        >
                          Branch Name
                        </Typography>
                        <Typography
                          align="left"
                          className="InterMedium font_size18 color-161616"
                        >
                          {customerDet.BRANCHNAME}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <div className="gridStart">
                        <Typography
                          align="left"
                          className="InterRegular font_size16 color-636363"
                          gutterBottom
                        >
                          Customer Code
                        </Typography>
                        <Typography
                          align="left"
                          className="InterMedium font_size18 color-161616"
                        >
                          {customerDet.CUSTCODE}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <div className="gridStart">
                        <Typography
                          align="left"
                          className="InterRegular font_size16 color-636363"
                          gutterBottom
                        >
                          GSTIN
                        </Typography>
                        <Typography
                          align="left"
                          className="InterMedium font_size18 color-161616"
                        >
                          {customerDet.GSTIN}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Box>

          <DividerWithText>Quote&nbsp;Status&nbsp;Update</DividerWithText>

          <Box sx={{ mb: 4, mt: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <div className="flexStartStartHeight">
                  <Checkbox
                    checked={quoteStatus}
                    onChange={(event) => setQuoteStatus(event.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{
                      m: 0,
                      "&.Mui-checked": {
                        color: "#fab005", // Change the checked color here
                      },
                    }}
                  />
                  <Typography
                    align="left"
                    sx={{ mt: 1 }}
                    className="InterMedium font_size18 color-161616"
                  >
                    Quote Sent
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <FormControl fullWidth>
                  <TextField
                    id="outlined-multiline-static"
                    // inputProps={{ maxLength: 10 }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#dbeaf5",
                        },
                        "&:hover fieldset": {
                          borderColor: "#dbeaf5",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#101f33",
                        },
                        backgroundColor: "transparent",
                      },
                    }}
                    multiline
                    rows={4}
                    value={remarks}
                    error={remarksErr}
                    onChange={(e) => {
                      const value = e.target.value;
                      setRemarks(value);
                      setRemarksErr(false);
                    }}
                    placeholder="Enter your remarks"
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          {/* {enquiryCardDet.length !== 0 && ( */}
          <Box sx={{ ml: 2, mr: 2, mt: 6 }}>
            <Grid container justifyContent={"center"}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="flexCenterCenterHeight">
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      pl: 5,
                      pr: 5,
                      textTransform: "capitalize",
                      bgcolor: "#fab005",
                      border: "1px solid #df9d05",
                      "&:hover": {
                        bgcolor: "#e6a504", // Slightly darker shade
                        opacity: 0.8, // Decrease opacity
                      },
                    }}
                    onClick={handleQuoteStatusSave}
                  >
                    <UpdateIcon sx={{ mr: 0.5 }} /> Update
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Box>
          {/* )} */}
        </>
      )}
      <Backdrop className="useStylesBackDrop" open={loadingCommon}>
        <CustomSpinner />
      </Backdrop>
      <Backdrop className="useStylesBackDrop" open={afterLoadingCommon}>
        <CustomSpinner />
      </Backdrop>
    </div>
  );
}

export default QuoteStatusUpdate;
