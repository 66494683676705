import React from 'react';

const CustomSpinner = () => {
  return (
    <div className="spinner-container">
      <div className="spinner">
        {/* Create multiple lines or spokes for the spinner */}
        {[...Array(20)].map((_, index) => (
          <div key={index} className="spinner-line" />
        ))}
      </div>
    </div>
  );
};

export default CustomSpinner;
