import {
  Backdrop,
  Button,
  Card,
  Dialog,
  DialogContent,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../Common/config";
import moment from "moment";
import CustomSpinner from "../../Common/CustomSpinner";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    borderRadius: "20px", // This changes the border radius of the dialog paper
  },
}));

function ToBeMapped(props) {
  const { TabValue } = props;
  var EmpID = localStorage.getItem("EMPID");
  const history = useNavigate();
  const [loadingCommon, setLoadingCommon] = useState(true);
  const [enquiryCardListDet, setEnquiryCardListDet] = useState([]);
  const [alreadyPickedDialog, setAlreadyPickedDialog] = useState(false);

  const EnquiryCardListLoad = useCallback(() => {
    axios.post(config.apiLink + "Alucast/EnquiryCardMapListLoad", {
      EmpID: EmpID
    }).then(
      (response) => {
        var Temp = response.data;
        setEnquiryCardListDet(Temp["EnquiryCardMapList"]);
        setLoadingCommon(false);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    EnquiryCardListLoad();
  }, [EnquiryCardListLoad, history]);

  const handleEnquiryFromCardDetails = (data) => {
    history("/CustomerMapping", {
      state: {
        params: data,
        TabValue: TabValue,
      },
    });
  };

  const handleAlreadyPickedDialogClose = () => {
    setAlreadyPickedDialog(false);
  };

  return (
    <div className="margin_top20">
      {!loadingCommon &&
        enquiryCardListDet.length !== 0 &&
        enquiryCardListDet.map((elem, ind) => (
          <Card
            key={ind}
            // data-aos="fade-up"
            sx={{
              padding: "32px 24px 24px",
              borderRadius: "10px",
              border: "1px solid #d4e6e6",
              boxShadow: 0,
              mt: 1,
              mb: 1,
            }}
          >
            <Grid container>
              <Grid item xs={2} sm={2} md={1} lg={1}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Sl No
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {ind + 1}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Enquiry No
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {elem.ENQUIRYNO}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Lead Name
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {elem.LEADNAME}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Follow Up Date
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {moment(elem.FOLLOWUPDATE).format("DD MMM hh:mm a")}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Created Time
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {moment(elem.CREATEDTIME).format("DD MMM hh:mm a")}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Created By
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {elem.CREATEDBY}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={5} sx={{mt:1}}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Enquiry Description
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {elem.ENQUIRYDESCRIPTION}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{mt:1}}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Lead Remarks
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {elem.LEADREMARKS}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2} sm={2} md={1} lg={1} sx={{mt:1}}>
                <div className="flexCenterCenterHeight">
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      pl: 5,
                      pr: 5,
                      textTransform: "capitalize",
                      bgcolor: "#fab005",
                      border: "1px solid #df9d05",
                      "&:hover": {
                        bgcolor: "#e6a504", // Slightly darker shade
                        opacity: 0.8, // Decrease opacity
                      },
                    }}
                    onClick={() => handleEnquiryFromCardDetails(elem)}
                  >
                    Details
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Card>
        ))}
      <BootstrapDialog
        onClose={handleAlreadyPickedDialogClose}
        aria-labelledby="customized-dialog-title"
        open={alreadyPickedDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent style={{ padding: "0px" }}>
          <Card
            sx={{
              border: "2px solid #bee3ff",
              bgcolor: "#f0f8ff",
              padding: "32px 16px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  align="center"
                  className="InterMedium font_size18 color-161616"
                >
                  This enquiry has been picked already.<br></br> Try another
                  unpicked enquiry.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                <div className="flexCenterCenterHeight">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleAlreadyPickedDialogClose}
                    sx={{
                      pl: 5,
                      pr: 5,
                      textTransform: "capitalize",
                      bgcolor: "#fab005",
                      border: "1px solid #df9d05",
                      "&:hover": {
                        bgcolor: "#e6a504", // Slightly darker shade
                        opacity: 0.8, // Decrease opacity
                      },
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Card>
        </DialogContent>
      </BootstrapDialog>
      <Backdrop className="useStylesBackDrop" open={loadingCommon}>
        <CustomSpinner />
      </Backdrop>
    </div>
  );
}

export default ToBeMapped;
