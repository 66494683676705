import React, { useState, useEffect } from "react";
import axios from "axios";
export default function TestingIP() {
  useEffect(() => {
    debugger;
    btnclkloginas();
  }, []);

  const btnclkloginas = () => {
    debugger;
    var param = {
      loginName: "IPPP001",
      loginPwd: "Aven@2024",
      pushToken:
        "c3KJmXrrrrUUzM3kWQqeBy:APA91bEvm6Ce45pyLMRKpGc158C0xZn8xtr7xaH7WhQgJUtqGZqC_PPWV79036-qRSC3zgdPaSZcaBbIDCB3h1DEfV5nv732caebhFBbb5m7HOIqPNuhcniIMjT1PzE1sQjFMuPXZ9G0",
      browser: "Chrome",
      os: "Windows",
    };
    axios.post("https://localhost:44359/api/Admin/POSTSignIN", param).then(
      (response) => {
        debugger;
        var Temp = response.data;
        console.log(Temp);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  return (
    <div className="body_margin">
      <h1>demo IP</h1>
    </div>
  );
}
