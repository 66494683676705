import {
  Card,
  CardContent,
  Typography,
  Button,
  IconButton,
  TextField,
  StepConnector,
  Avatar,
} from "@mui/material";
// import MainHomepageNav from "../features/MainHomepageNav";
import Grid from "@mui/material/Grid";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import Autocomplete from "@mui/material/Autocomplete";

import axios from "axios";
import { useDropzone } from "react-dropzone";
import { useMemo } from "react";
import Checkbox from "@mui/material/Checkbox";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import { blue, blueGrey } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CustomerTag from '../../Components/Mailer/CustomerTag.js'
import CustomerList from "../../Components/Mailer/CustomerList";
import RingLoader from "react-spinners/RingLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import config from '../../Common/config.js'
import AlertSelectTagBox from "../../Components/Mailer/AlertSelectTagBox";
import Homepagenav from "../../Components/Mailer/Homepagenav.js";
import { Calculate, Campaign } from "@mui/icons-material";
import Htmltexteditor from "../../Components/Mailer/Htmltexteditor.js";

const NewCampaign = (props) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  // const classes = useStyles();
  const location = useLocation();
  const history = useNavigate();

  const [error, Seterror] = useState("");
  const [cusName, setcusName] = useState([]);
  const [cusEmail, setcusEmail] = useState([]);
  /// tag Customer based on stateid
  const [CusStateid, setCusStateid] = useState([]);
  /// selected Customer based on stateid
  const [selectCus, setselectCus] = useState([]);

  const [tagListall, settagListall] = useState([]);
  const [state, setstate] = useState([]);
  //customerlist
  const [Customerlist, setCustomerlist] = useState([]);
  const [CustomerCount, setCustomerCount] = useState([]);
  const [CustomerContact, setCustomerContact] = useState([]);
  const [CustomerID, setCustomerID] = useState([]);
  const [CustomerMail, setCustomerMail] = useState([]);
  ///popUp
  const [open, setOpen] = React.useState(false);
  const [TagAlert, setTagAlert] = React.useState(false);
  const [cussetid, setcussetid] = useState("");
  //tag
  const [tagcontact, settagcontact] = React.useState(true);

  const [CusstateId, setCusstateId] = React.useState(false);
  // const [openFun, setopenFun] = React.useState(false);
  ///popup form
  const [textTagname, settextTagname] = useState("");
  const [textSusscs, settextSusscs] = useState("");
  const [Statecusidparam, setStatecusidparam] = useState([]);
  const [textSusscsDiv, settextSusscsDiv] = useState(false);
  const [selectstate, setselectstate] = useState([]);
  const [textTagnameErorr, settextTagnameErorr] = useState(false);
  const [StateIdList, setStateIdList] = useState([]);
  const [addtagName, setaddtagName] = useState([]);
  const [ArrayTag, setArrayTag] = useState([]);
  const [selectedValue, setselectedValue] = useState("CUSTOMER");
  const [ArrayTagName, setArrayTagName] = useState([]);

  //FileUpload
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFileErr, setSelectedFileErr] = useState(false);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const [Selectfdoc, setSelectfdoc] = useState({});

  const [GetCustomerDetail, setGetCustomerDetail] = useState([]);
  const [SubDetail, setSubDetail] = useState([]);
  const [CompaignName, setCompaignName] = useState("");
  const [CompaignNameErr, setCompaignNameErr] = useState("");
  const [Loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setselectedValue(event.target.value);
  };
  //Process Begin
  useEffect(() => {
    debugger;
    console.log(config.apiLink + "Dashboard/TagList");
    // setLoading(true);
    loadCustomer();
    loadState();
    TagListGr();
    loadEmail();
    //setLoading(true)
    // setTimeout(() => {
    //   setLoading(false)
    // }, 8000);
    // setopenFun(true)
  }, []);

  const TagListGr = (ListCoustomerId, cussetid) => {
    debugger;
    // setLoading(true);
    // axios.post("http://localhost:6720/Dashboard/TagList",
    axios
      .post(
        config.apiLink + "Dashboard/TagList",
        {
          CusId: ListCoustomerId,
          stateId: cussetid,
        },
        {}
      )
      .then(
        (response) => {
          debugger;
          var Temp = response.data;
          settagListall(Temp["TAGLISTALL"]);
          settagcontact(false);
          setTimeout(() => {
            settagcontact(true);
          }, 100);
          // setLoading(false);
        },
        //Incase failure the function, identify the What type of error shown
        (error) => {
          console.log(error);
        }
      );
  };

  const loadEmail=()=>{
    debugger;
    // setLoading(true);
    // axios.post("http://localhost:6720/Dashboard/SearchCustomer",
    axios.post(config.apiLink + "Dashboard/SearchEmail", {}, {}).then(
      (response) => {  debugger;
        var Temp = response.data;
        setcusEmail([Temp][0]["Search-Email"]);
       
        // setLoading(false);
      },
      //Incase failure the function, identify the What type of error shown
      (error) => {
        console.log(error);
      }
    );
  }
  // Search Customer - API
  const loadCustomer = () => {
    debugger;
    // setLoading(true);
    // axios.post("http://localhost:6720/Dashboard/SearchCustomer",
    axios.post(config.apiLink + "Dashboard/SearchCustomer", {}, {}).then(
      (response) => {
        debugger;
        var Temp = response.data;
        setcusName([Temp][0]["Search-Customer"]);
        setCusStateid([Temp][0]["Search-Customer"]);
        // setLoading(false);
      },
      //Incase failure the function, identify the What type of error shown
      (error) => {
        console.log(error);
      }
    );
  };

  // Search State-API
  const loadState = () => {
    debugger;
    // setLoading(true);   debugger;
    // axios.post("http://localhost:6720/Dashboard/SearchState",
    axios.post(config.apiLink + "Dashboard/SearchState", {}, {}).then(
      (response) => {
        var Temp = response.data;
        setstate([Temp][0]["Search-State"]);
        // setLoading(false);
      },
      //Incase failure the function, identify the What type of error shown
      (error) => {
        console.log(error);
      }
    );
  };

  // GetValues For Customer - API

  const onChangeHandlecusname = (id) => {
    debugger;
    // axios.post("http://localhost:6720/Dashboard/GetCustomerDetail",
    axios
      .post(
        config.apiLink + "Dashboard/GetCustomerDetail",
        {
          CustomerId: id,
        },
        {}
      )
      .then(
        (response) => {
          debugger;
          var valid = response.data;
          setCustomerlist(valid["Customer"][0]["CUSTOMERNAME"]);
          setCustomerCount(valid["CustomerCount"][0]["TOTALCONTACTS"]);
          setCustomerContact(valid["CustomerContact"]);
          console.log(CustomerID);
          console.log(CustomerMail);
        },
        //Incase failure the function, identify the What type of error shown
        (error) => {
          console.log(error);
        }
      );
  };

  // Drag 'n' drop some files here, or click to select files

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    width: 400,
    height: 150,
    marginLeft: -16,
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  // const {

  //   getRootProps,
  //   getInputProps,
  //   isFocused,
  //   isDragAccept,
  //   isDragReject,
  //   acceptedFiles,
  //   //  openDialog
  // } = useDropzone({ accept: { 'image/*': [] } });

  // const style = useMemo(() => ({

  //   ...baseStyle,
  //   ...(isFocused ? focusedStyle : {}),
  //   ...(isDragAccept ? acceptStyle : {}),
  //   ...(isDragReject ? rejectStyle : {})
  // }),
  //   [
  //     isFocused,
  //     isDragAccept,
  //     isDragReject
  //   ]);
  // debugger;
  // const files = acceptedFiles.map(file => (

  //   <li key={file.path}>

  //     {file.path} - {file.size} -{file.webkitRelativePath} bytes

  //   </li>

  // ));
  const changeHandler = (event) => {
    debugger;
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  //funtion add template
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    if (selectstate == "") {
      setselectstate(true);
    }
    if (textTagname == "") {
      settextTagnameErorr(true);
    }
    if (textTagname && selectstate) {
      console.log(textTagname, selectstate);
    }
  };
  // const submitCompaign =(e)=>{
  //   debugger;
  //    e.preventDefault();
  //     console.log(CompaignName)

  // }

  // Add TagId and TagName and StateId
  const clickSave = () => {
    debugger;
    let newArr = [...StateIdList];
    let mindArr = [...selectCus];

    //State
    var demp = [];
 
      debugger;
      StateIdList.map((strr1) => demp.push({ StateId: strr1.StateId }));
  
    //Customer
    var CusidArr = [];
 
      debugger;
      selectCus.map((strr2) => CusidArr.push({ CusId: strr2.CUSTOMERID }));
    
    const newParams = [];

    selectCus.forEach((strr2, index) => {
      newParams.push({
        CusId: strr2.CUSTOMERID,
        StateId: StateIdList[index].StateId,
      });
    });
    debugger;
    console.log(newParams);

    if (CusidArr.length > 0) {
      debugger;
      UseTagApi(textTagname, newParams);
      console.log(textTagname, newParams);
    } else {
      debugger;
      UseTagApi(textTagname, newParams);
      console.log(textTagname, newParams);
    }

    console.log(demp);
  };

  //Child To Parant Get The Data
  const childdata = (Details) => {
    // let StrArry = [...Details]
    // setSubDetail(StrArry)
    debugger;
    //TAGID
    if (Details.length > 0) {
      var NewTagArray = [];
      var NewTagNameArray = [];
      Details.map(function (item, index) {
        if (item.Status === true) {
          var x = {
            TAGID: item.TAGID,
          };
          var y = {
            TAGID: item.TAGID,
            TAGNAME: item.TAGNAME,
          };
          NewTagArray.push(x);
          NewTagNameArray.push(y);
        }
        debugger;
        setArrayTag(NewTagArray);
      });
      //TAGNAME
      // var NewTagNameArray = [];
      // Details.map(function (elem, index) {
      //   if (elem.Status === true) {
      //     var x = {
      //       TAGNAME: elem.TAGNAME
      //     };
      //     NewTagNameArray.push(x);
      //   }
      //   debugger;
      //   setArrayTagName(NewTagNameArray);

      // });
      setArrayTagName(NewTagNameArray);
    }
  };

  const ValidateSave = () => {
    var formvaild = true;
    if (CompaignName === "") {
      formvaild = false;
      setCompaignNameErr(true);
    }
    if (selectedFile.length === 0) {
      formvaild = false;
      setSelectedFileErr(true);
    }
    return formvaild;
  };

  const TagSubmit = () => {
    debugger;
    if (ValidateSave()) {
      axios
        .post(
          "http://localhost:6720/Dashboard/CustomerTagGroup",
          {
            tagidList: ArrayTag,
          },
          {}
        )
        .then(
          (response) => {
            var valid = response.data;
            setGetCustomerDetail(valid);
            var param = {
              file: selectedFile,
            };
            //history.push('/CustomerList', { params:  valid});
            history.push("/CustomerList", {
              params: valid,
              Detais: param,
              Sub: CompaignName,
              TagListEmail: ArrayTagName,
              htmltexteditorContent: textContent,
            });

            console.log(GetCustomerDetail);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };

  //State Tage -API
  const UseTagApi = (TagName, StateIDin) => {
    debugger;
    axios
      .post(
        "http://localhost:6720/Dashboard/CustomerTag",
        {
          TAGNAME: TagName,
          CusState: StateIDin,
        },
        {}
      )
      .then(
        (response) => {
          debugger;
          var valid = response.data;
          //  settagListall(valid['TagList'])
          settagListall(valid["TagList"]);
          setOpen(false);
          // settagcontact(false)
          // setopenFun(true);
          //settextSusscs(valid)
          //settextSusscsDiv(true)
        },
        //Incase failure the function, identify the What type of error shown
        (error) => {
          console.log(error);
        }
      );
  };
  const stateidCustomer = (stateid) => {
    if (stateid.length == 0) {
      loadCustomer();
      return false;
    }

    {
      console.log("Stateid ", stateid);
    }
    let newArr = [...stateid];
    var demp = [];
    {
      stateid.map((strr1) => demp.push({ StateId: strr1.StateId }));
    }
    var arry = [];
    {
      demp.map((arry1) => arry.push([arry1.StateId]));
    }
    console.log("one", arry);
    var checkin = arry.toString();

    CustomerState(checkin);
  };

  const filtertagstate = (cussetid, stateid) => {
    // if (stateid.length == 0)
    // {
    //   loadCustomer()
    //   return false
    // }

    {
      console.log("Stateid ", stateid);
    }
    let newArr = [...stateid];
    var demp = [];
    {
      stateid.map((strr1) => demp.push({ StateId: strr1.StateId }));
    }
    var arry = [];
    {
      demp.map((arry1) => arry.push([arry1.StateId]));
    }
    var Statetag = arry.toString();

    TagListGr(cussetid, Statetag);
  };

  const CustomerState = (stateCus) => {
    axios
      .post(
        "http://localhost:6720/Dashboard/SearchCustomer",
        //  axios.post(config.apiLink +'Dashboard/SearchCustomer',
        {
          //["4001,4002,4003"]
          StateId: stateCus,
        },
        {}
      )
      .then(
        (response) => {
          var Temp = response.data;
          setCusStateid([Temp][0]["Search-Customer"]);
          setLoading(false);
        },
        //Incase failure the function, identify the What type of error shown
        (error) => {
          console.log(error);
        }
      );
  };
  const OpenAlret = () => {
    alert("a");
  };

  const [contentType, setContentType] = useState("image");
  const [textContent, setTextContent] = useState("");

  const handleContentTypeChange = (event) => {
    setContentType(event.target.value);
  };

  //   const handleTextContentChange = (event) => {
  //    setTextContent(event.target.value);

  //  };
  const handletexteditor = (newContent) => {
    setTextContent(newContent);
  };
  return (
    <div>
      {/* MainBar */}
      <Grid container>
        <Grid item xs={12} sm={12} lg={9} md={10}>
          <div
          //  className={classes.newcampandiv}
          >
            <Homepagenav />
            <div style={{ margin: "10px" }}>
              <Typography variant="h7" component="h4">
                New Compaign
              </Typography>
              <StepConnector></StepConnector>
            </div>
            <div
            //  className={classes.newcmdbrowcard}
            >
              <Card
                className="Bordercontainer flexCenterCenter"
                style={{ margin: "10px" }}
              >
                <CardContent
                //  className={classes.newcmdbrowcontant}
                >
                  <form noValidate autocomplete="off" className="gridCenter">
                    <Typography variant="h7" component="h4">
                      Compaign Name
                    </Typography>
                    <TextField
                      required
                      error={CompaignNameErr}
                      helperText={
                        CompaignNameErr ? "please entre the name" : ""
                      }
                      // className={classes.sidcardtext}
                      size="small"
                      onChange={(e) => setCompaignName(e.target.value)}
                      // onChange={(e)=>getCompaignName (e.target.value)}
                    ></TextField>
                  </form>
                  <div className="flexCenterCenter" style={{ margin: "10px" }}>
                    <label style={{ margin: "10px" }}>
                      <input
                        type="radio"
                        value="image"
                        checked={contentType === "image"}
                        onChange={handleContentTypeChange}
                      />
                      Image Email Content
                    </label>
                    <label style={{ margin: "10px" }}>
                      <input
                        type="radio"
                        value="text"
                        checked={contentType === "text"}
                        onChange={handleContentTypeChange}
                      />
                      Text Email Content
                    </label>
                  </div>
                  <div className="flexCenterCenter">
                    {contentType == "image" && (
                      <div
                        className="gridCenter "
                        style={{
                          marginTop: "20px",
                          padding: "20px",
                          backgroundColor: "#f2f2f2",
                          borderRadius: "20px",
                        }}
                        //  className={classes.div}
                      >
                        <IconButton sx={{ color: "blue", size: "large" }}>
                          <FileOpenIcon fontSize="large" />
                        </IconButton>
                        <Typography
                          variant="h7"
                          component="h3"
                          style={{ margin: "10px 0px" }}
                        >
                          {" "}
                          Drag Your mailer Template <br></br>here to start
                          uploading
                        </Typography>
                        {selectedFileErr && (
                          <p
                            style={{
                              color: "red",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            Please Upload File
                          </p>
                        )}
                        <div
                          style={{ margin: "20px 0px 0px 70px" }}
                          // className={classes.newcmdbrowbtn}
                        >
                          <input
                            type="file"
                            name="file"
                            variant="contained"
                            size="small"
                            onChange={changeHandler}
                          />
                        </div>
                      </div>
                    )}

                    {contentType === "text" && (
                      <div
                        style={{ padding: "0px 0px 25px 0px" }}
                        className="flexCenterCenter"
                      >
                        <Htmltexteditor onchangecontent={handletexteditor} />
                      </div>
                    )}
                  </div>
                  {/* <div {...getRootProps({ style })}>
                        <IconButton
                          style={{ marginLeft: '10px' }}
                          sx={{ color: 'blue', size: 'large' }}
                        >
                          <FileOpenIcon fontSize='large' />
                        </IconButton>
                       <input {...getInputProps()} />
                        <Typography
                          variant='h7'
                          component='h3'
                        >  Drag Your mailer Template <br></br>here to start uploading
                        </Typography>
                        <div className={classes.newcmdbrowbtn}>
                        <input type="file" name="file"   variant="contained" size="small"  onChange={changeHandler} />
                       </div>
                       </div> */}
                  {/* </div>  */}
                  {/* <aside>
                        <h4>Files Name</h4>setOpen
                        <ul>{files}</ul>
                      </aside>  */}
                  {/* <button onClick={handleSubmission}>Submit</button> */}
                </CardContent>
              </Card>
            </div>
          </div>
        </Grid>
        {/* SideBar */}
        <Grid item xs={12} sm={12} lg={3} md={2}>
          <Card
            className="NewcampaignSidebarCard"
            // className={classes.sidcard}
          >
            <CardContent style={{ height: "100%" }}>
              <div>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    Select Categories
                  </FormLabel>
                  <div>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      value={selectedValue}
                      onChange={handleChange}
                      name="radio-buttons-group"
                      style={{ display: "inline" }}
                    >
                      <FormControlLabel
                        value="CUSTOMER"
                        control={<Radio />}
                        label="CUSTOMER"
                      />
                      <FormControlLabel
                        value="CONTACT"
                        control={<Radio />}
                        label="CONTACT"
                      />
                      <FormControlLabel
                        value="STATE"
                        control={<Radio />}
                        label="STATE"
                      />
                    </RadioGroup>
                    {/* Conditional rendering based on selectedValue */}
                    {selectedValue === "CUSTOMER" && (
                      <div>
                        <div>
                          <Typography
                            variant="h7"
                            color="inherit"
                            component="h5"
                          >
                            Search Customer
                          </Typography>
                          <StepConnector></StepConnector>
                        </div>
                        <div style={{ marginTop: "13px" }}>
                          {/* AutoComplete SearchCustomer */}
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={cusName}
                            getOptionLabel={(option) => option.CUSTOMERNAME}
                            size="small"
                            // className={classes.sidcardtext}
                            onChange={(event, option) => {
                              // var SelectOtion1 = option;
                              // var cus1 = SelectOtion1.CUSTOMERID
                              // TagListGr(cus1)
                              if (option !== null) {
                                debugger;
                                var SelectOtion = option;
                                var cus = SelectOtion.CUSTOMERID;
                                onChangeHandlecusname(cus);
                                setcussetid(cus);
                                TagListGr(cus);
                              } else {
                                setcussetid(cus);
                                TagListGr();
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Customer Name..."
                                //   onChange={(e)=>onChangeHandle(e.target.value)}
                              />
                            )}
                          />
                        </div>
                        
                      </div>
                    )}

                    {selectedValue === "CONTACT" && (
                      <div>
                        <div>
                          <Typography
                            variant="h7"
                            color="inherit"
                            component="h5"
                          >
                           Customer Email
                          </Typography>
                          <StepConnector></StepConnector>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                          <Autocomplete
                          multiple
                            disablePortal
                            id="combo-box-demo"
                            size="small"
                            getOptionLabel={(option) => option.CONTACTEMAIL}
                            options={cusEmail}
                            sx={{ width: 300 }}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.CONTACTEMAIL}
                              </li>
                            )} 
                             onChange={(str, option) => {
                              if (option !== null && option.length > 0) {
                                debugger;
                                var SelectOtion = option;
                                filtertagstate(cussetid, SelectOtion);
                              } else {
                                TagListGr();
                              }
                            }}



                            
                            renderInput={(params) => (
                              <TextField {...params} label="Customer Email" />
                            )}
                          />
                        </div>
                      </div>
                    )}

                    {selectedValue === "STATE" && (
                      <div>
                        {/* Content for STATE */}
                        <div><div style={{ marginTop: "8px" }}>
                          <Typography variant="h7" component="h5">
                            Select State
                          </Typography>
                          <StepConnector></StepConnector>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                          {/* AutoComplete select State */}
                          <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={state}
                            disableCloseOnSelect
                            size="small"
                            getOptionLabel={(option) => option.StateName}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.StateName}
                              </li>
                            )}
                            onChange={(str, option) => {
                              if (option !== null && option.length > 0) {
                                debugger;
                                var SelectOtion = option;
                                filtertagstate(cussetid, SelectOtion);
                              } else {
                                TagListGr();
                              }
                            }}
                            // className={classes.sidcardtext

                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select State"
                                placeholder="Select State"
                              />
                            )}
                          />
                        </div></div>
                      </div>
                    )}
                  </div>
                </FormControl>
              </div>
              <div>
                {/* <div style={{ marginTop: '9px' }}    >
                  <Typography
                    variant="h7"
                    color="inherit"
                    component="h5"

                  >Select Make
                  </Typography>
                  <StepConnector></StepConnector>
                </div> */}
                {/* <div style={{ marginTop: '12px' }}>
                
                  <TextField
                    label="Zitai"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    style={{ width: '145px' }}
                    sx={{ fontFamily: 'Monospace' }}
                  />
                  <TextField
                    label=" Non Zitai"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    style={{ width: '145px', marginLeft: '14px' }}
                  />
                </div> */}

                <div style={{ marginTop: "20px" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="h7" component="h5">
                      Select Tag
                    </Typography>

                    <AddCircleTwoToneIcon
                      sx={{ color: blue[900] }}
                      onClick={handleClickOpen}
                    ></AddCircleTwoToneIcon>
                  </div>
                  <StepConnector></StepConnector>
                </div>
                <Grid item lg={12}>
                  {tagcontact == true && (
                    <div>
                      {tagListall.length > 0 && (
                        <CustomerTag
                          CustomerObj={tagListall}
                          getdata={childdata}
                        />
                      )}
                    </div>
                  )}

                  {/* < div >
                  {addtagName.length > 0 &&   <CustomerTag  CustomerObj={addtagName} getdata={childdata} />}
                </div> */}
                </Grid>
              </div>
              <Grid item lg={12}>
                {/* {openFun == true &&  */}

                <div className="flexCenterCenter" style={{ marginTop: "30px" }}>
                  <Button
                    variant="contained"
                    onClick={TagSubmit}
                    style={{ width: "280px" }}
                  >
                    Next
                  </Button>
                </div>

                {/* } */}
                {/* {!open && open && addtagName.length > 0 && <div style={{ marginTop: '30px', marginLeft: '28px', }}>
                  
                  <Button variant="contained" onClick={TagSubmit} style={{ width: '280px' }}>Next</Button>
                </div>} */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* <Dialog></Dialog> */}
      
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="responsive-dialog-title">{"Select Tag"}</DialogTitle>
        <DialogContent
        // className={classes.diolog}
        >
          <DialogContentText>
            <form noValidate autocomplete="off" onSubmit={handlesubmit}>
              <Typography style={{ marginTop: "-5px" }}>
                Enter Tag Name
              </Typography>
              <TextField
                onChange={(e) => settextTagname(e.target.value)}
                style={{ marginTop: "5px" }}
                label="Tag Name"
                variant="outlined"
                color="secondary"
                // multiline
                rows={4}
                fullWidth
                required
                error={textTagnameErorr}
              />
              <Typography style={{ marginTop: "0px" }}>
                Maximum 20 Characters
              </Typography>
              <Typography style={{ marginTop: "10px" }}>
                Select State
              </Typography>

              {/* muliteselect state */}
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={state}
                //setCusStateid
                disableCloseOnSelect
                size="small"
                getOptionLabel={(option) => option.StateName}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.StateName}
                  </li>
                )}
                onChange={(str, option) => {
                  if (option !== null) {
                    debugger;
                    var SelectOtion = option;
                    stateidCustomer(SelectOtion);
                    setStateIdList(SelectOtion);
                  }
                }}
                error={selectstate}
                style={{ marginTop: "10px" }}
                // className={classes.sidcardtext}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select State"
                    placeholder="Select State"
                  />
                )}
              />
              <Typography style={{ marginTop: "10px" }}>
                Select Customers
              </Typography>
              {/* muliteselect Customers */}
              <Autocomplete
                multiple
                disableCloseOnSelect
                id="combo-box-demo"
                options={CusStateid}
                getOptionLabel={(option) => option.CUSTOMERNAME}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.CUSTOMERNAME}
                  </li>
                )}
                size="small"
                // className={classes.sidcardtext}
                onChange={(event, option) => {
                  if (option !== null) {
                    debugger;
                    var SelectOtion = option;
                    var cus = SelectOtion.CUSTOMERID;
                    // setCustID(SelectOtion.CUSTOMERID);
                    // Autoselectcng(SelectOtion.Id);
                    setselectCus(SelectOtion);
                    console.log(SelectOtion);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Customer Name..."
                    //   onChange={(e)=>onChangeHandle(e.target.value)}
                  />
                )}
              />

              {textSusscsDiv == true && (
                <Typography color="primary">{textSusscs}</Typography>
              )}

              <Button
                onclick={() => console.log("you click me")}
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginTop: "30px" }}
                onClick={clickSave}
              >
                SAVE
              </Button>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      
      {Loading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: " 40%",
          }}
        >
          <ScaleLoader color="blue" loading={Loading} size={150} />
        </div>
      )}
    </div>
  );
};
export default NewCampaign;
