import React from "react";
import { Box, Card, CardContent, Typography, IconButton } from "@mui/material";
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';


// import ContactMailIcon from "@mui/icons-material/ContactMail";
// import SummarizeIcon from "@mui/icons-material/Summarize";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
// import useStyles from './styles';

const Homepagenav = () => {   debugger;
  const history = useNavigate();
  const onnewCompaign = () => {
    history.push("/NewCampaign");
  };
  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className="flexCenterCenterHeight " style={{margin:'10px',boxShadow:'0px 0px 4px gray',borderRadius:'10px'}}>
            <div className="gridCenter" style={{margin: "0px 10%"}}>
              <IconButton  onClick={onnewCompaign} sx={{ color: "blue" }}>
                <PeopleOutlineRoundedIcon fontSize="large" />
              </IconButton>

              <Typography variant="h7" component="h4">
                New Compaign
              </Typography>
            </div>
          
            <div className="gridCenter" style={{margin: "0px 10%"}}>
              <IconButton sx={{ color: "blue" }}>
                <PeopleOutlineRoundedIcon fontSize="large" />
              </IconButton>
                <Typography variant="h7" component="h4">
                  Contact
                </Typography>
                </div>
             <div className="gridCenter" style={{margin: "0px 10%"}}>
              <IconButton sx={{ color: "blue" }}>
                <PeopleOutlineRoundedIcon fontSize="large" />
              </IconButton>
                <Typography variant="h7" component="h4">
                  Summary
                </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default Homepagenav;
