import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import config from '../../Common/config';
import { Button, Card, CardContent, Dialog, DialogContent, DialogTitle, FormControl, Grid, TextField, Typography } from '@mui/material';



const BusinessCardUpload = () => {

  const [enquiry, setEnquiry] = useState([]);
  const [enquiryFileShow, setEnquiryFileShow] = useState(false);
  const [extractedDataShow, setExtractedDataShow] = useState(false);
  const [enquiryFileData, setEnquiryFileData] = useState([]);
  const [extractedData, setExtractedData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [contactName, setContactName] = useState(null);
  const [email, setEmail] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [address, setAddress] = useState(null);
  const [designation, setDesignation] = useState(null);
  const [workPhone, setWorkPhone] = useState(null);
  const [website, setWebsite] = useState(null);
  const [imageId, setImageId] = useState(null);


  const EnquiryLoad = useCallback(() => {

    axios.post(config.apiLink + "Alucast/EnquiryLoad", {}).then(
      (response) => {
        var Temp = response.data;
        setEnquiry(Temp["Enquiry"]);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);



  useEffect(() => {
    EnquiryLoad();
  }, [EnquiryLoad]);

  const handleclick = (data) => {
    setEnquiryFileShow(true);
    EnquriyCardFileData(data.ID)
  }

  const handleExtract = (data) => {
    debugger;
    Extract(data.FILENAME,data.ID);
  }


  const Extract = (filename,Id) => {
    axios.post(config.apiLink + "Alucast/EnquiryCardDataExtract", {
      FileName: filename,
      Id:Id
    }).then(
      (response) => {
        debugger;
        var Temp = response.data;
        setExtractedData(Temp["ExtractedData"]);
        setExtractedDataShow(true);
      },
      (error) => {
        console.log(error)
      }
    )
  }

  const EnquriyCardFileData = (Id) => {
    axios.post(config.apiLink + "Alucast/EnquiryCardData", {
      EnquiryId: Id
    }).then(
      (response) => {
        debugger;
        var Temp = response.data;
        setEnquiryFileData(Temp["EnquiryData"]);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const handleDialogClose = () => {
    setExtractedDataShow(false);
  };


  const handleEditClick = (data) => {
    debugger;
    setIsEditMode(true);
    setContactName(data.ContactName);
    setCompanyName(data.CompanyName);
    setEmail(data.Email);
    setWorkPhone(data.WorkPhone);
    setDesignation(data.JobTitle);
    // setCompanyName(data.Compan);
    setWebsite(data.Website);
    setAddress(data.Address);
    setImageId(data.ImageId);
  };

  const handleSaveClick = () => {
    setIsEditMode(false);
    ExtractedDataSave();
    // Here you can send the updated data to the backend if necessary
    console.log('Saved data:', extractedData);
  };

  const ExtractedDataSave=()=>{
    axios.post(config.apiLink+"Alucast/ExtractedDataSave",{
      Name:contactName,
      CompanyName:companyName,
      Email:email,
      Address:address,
      Designation:designation,
      Mobilenumber:workPhone,
      Website:website,
      ImageId:imageId
    }).then(
      (response)=>{
        var Temp=response.data;
        if(Temp==="Success"){
          window.location.reload();
        }
      },
      (error)=>{
        console.log(error)
      }
    )
  }

  return (
    <div style={{ marginTop: "50px" }}>
      <Grid container>
        <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <Card
            style={{
              border: "1px solid #b0b1b2",
              padding: "0px",
            }}
          >
            <CardContent style={{ padding: "0px" }}>
              <Typography
                style={{
                  padding: "10px 0px",
                  backgroundColor: "#fef5da",
                  color: "#013d54",
                }}
              >
                {/* <div className="count">1</div>{" "} */}
                <strong style={{ marginLeft: "15px" }}>
                  Alucast Enquiry
                </strong>
              </Typography>
              <div style={{ marginBottom: "20px" }}>
                {!enquiryFileShow ? (
                  <Grid container>
                    {enquiry.map((elem, index) => (
                      <Grid container key={elem.ID}>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <div className="flexEndCenter" style={{
                            width: "200px",
                            margin: "10px 15px",
                          }}  >
                            {index + 1}
                          </div>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6}>

                          <div
                            className="flexStartCenter"
                            style={{
                              width: "200px",
                              margin: "10px 15px",
                            }}
                          >
                            <Button
                              fullWidth
                              variant='contained'
                              onClick={() => handleclick(elem)}
                            >
                              {elem.ENQUIRYCOUNT}
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Grid container>
                    {enquiryFileData.map((elem, index) => (
                      <Grid container spacing={2}>

                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <div className="flexEndCenter" style={{
                            width: "200px",
                            margin: "10px 15px",
                          }}  >
                            File {index + 1}
                          </div>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6}>

                          <div
                            className="flexStartCenter"
                            style={{
                              width: "200px",
                              margin: "10px 15px",
                            }}
                          >
                            <Button
                              fullWidth
                              variant='contained'
                              onClick={() => handleExtract(elem)}
                            >
                              Extract
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>

        </Grid>
      </Grid>
      <Grid container>
        <div>
          <Dialog open={extractedDataShow} onClose={handleDialogClose}
            PaperProps={{
              style: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '500px', // You can adjust the width as needed
              },
            }}>
            <DialogTitle>
              Extracted Data
            </DialogTitle>
            {extractedData.map((key, index) => (
              <DialogContent>
                {isEditMode ? (
                  <FormControl>

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Typography>
                          ContactName
                        </Typography>
                        <TextField
                          // id="ContactName"
                          // label="ContactName"
                          variant="outlined"
                          fullWidth
                          value={contactName}
                          onChange={(e) => {
                            setContactName(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography>
                          CompanyName
                        </Typography>
                        <TextField
                          // id="ContactName"
                          // label="ContactName"
                          variant="outlined"
                          fullWidth
                          value={companyName}
                          onChange={(e) => {
                            setCompanyName(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography>
                          Email
                        </Typography>
                        <TextField
                          // id="Email"
                          // label="Email"
                          variant="outlined"
                          fullWidth
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography>
                          Designation
                        </Typography>
                        <TextField
                          // id="Designation"
                          // label="Designation"
                          variant="outlined"
                          fullWidth
                          value={designation}
                          onChange={(e) => {
                            setDesignation(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography>
                          Address
                        </Typography>
                        <TextField
                          // id="Address"
                          // label="Address"
                          variant="outlined"
                          fullWidth
                          value={address}
                          onChange={(e) => {
                            setAddress(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography>
                          WorkPhone
                        </Typography>
                        <TextField
                          // id="WorkPhone"
                          // label="WorkPhone"
                          variant="outlined"
                          fullWidth
                          value={workPhone}
                          onChange={(e) => {
                            setWorkPhone(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography>
                          Website
                        </Typography>
                        <TextField
                          // id="Website"
                          // label="Website"
                          variant="outlined"
                          fullWidth
                          value={website}
                          onChange={(e) => {
                            setWebsite(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>


                  </FormControl>
                ) : (

                  <FormControl>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Typography>
                            ContactName
                          </Typography>
                          <Typography>
                            {key.ContactName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography>
                          CompanyName
                          </Typography>
                          <Typography>
                            {key.CompanyName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography>
                            Email
                          </Typography>
                          <Typography>
                            {key.Email}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography>
                            Designation
                          </Typography>
                          <Typography>
                            {key.JobTitle}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography>
                            Address
                          </Typography>
                          <Typography>
                            {key.Address}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography>
                            WorkPhone
                          </Typography>
                          <Typography>
                            {key.WorkPhone}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography>
                            Website
                          </Typography>
                          <Typography>
                            {key.Website}
                          </Typography>
                        </Grid>
                      </Grid>
                  </FormControl>
                )}
                <div className='flexCenterCenter' style={{margin:"20px"}}>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={isEditMode ? ()=>handleSaveClick() :()=> handleEditClick(key)}
                >
                  {isEditMode ? 'Save' : 'Edit'}
                </Button>
                </div>
              </DialogContent>
            ))}
          </Dialog>
        </div>
      </Grid>
    </div>
  )
};

export default BusinessCardUpload