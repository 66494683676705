import helpers from "../Encryption/Encrypt";

const config = () => {};
//*****************don't USE ITS LIVE************************
// config.apiLink = process.env.REACT_APP_API_ENDPOINT; //https://qrcustomerapi.tvenka.com/
 config.apiLink = "https://alucast-api.tvenka.com/";
  // config.apiLink = "https://demoenquiry-api.tvenka.com/";
// config.apiLink = "http://localhost:58155/";
// config.enquiryLink="https://demoenquiry.tvenka.com/"
// config.enquiryLink="http://localhost:3000/"
config.enquiryLink="https://alucast.tvenka.com/"

if (sessionStorage.getItem("Theme") === "blueTheme") {
  config.Menunavbarcolor = "#2979ff";
  config.commonColor = "#2979ff";
  config.dashHeader = "#0000CD";
  config.custextclr = "#2979ff";
  config.singline = "#d0e8fb";
  config.buttonBack = "#2979ff";
  config.buttonColor = "#2979ff";
  config.CustGreenColor = "#228B22";
} else if (sessionStorage.getItem("Theme") === "redTheme") {
  config.Menunavbarcolor = "#ff3d00";
  config.commonColor = "#ff3d00";
  config.dashHeader = "#ff3d00";
  config.comBgColor = "#EFEFEF";
  config.buttonBack = "#ff3d00";
  config.buttonColor = "#ff3d00";
  config.singline = "#fff3f0";
  config.custextclr = "#66da43";
  config.CustGreenColor = "#228B22";
}

config.timeoutToast = 1000;
config.timeout = 1000;
config.timeoutbtn = 1000;

let appdata;
const configuser = function (history) {
  appdata = null;
  if (sessionStorage.getItem("key") != null) {
    appdata = helpers.DecryptVal(sessionStorage.getItem("key"));
  } else {
    //  history.push("/");
  }
  return appdata;
};
let appstate;
const configstate = function (history) {
  appstate = null;
  if (sessionStorage.getItem("mystate") != null) {
    appstate = helpers.DecryptVal(sessionStorage.getItem("mystate"));
  } else {
    //  history.push("/");
  }
  return appstate;
};

const validatephone = function (phone) {
  const regex = /\d{10}/;
  return regex.test(phone);
};

export default config;
export { configuser, configstate, validatephone };
