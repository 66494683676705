import React from "react";
import { Box } from "@mui/material";

const RDLCPreview = (props) => {
  const { FileURL } = props;
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-block",
        width: "350px", // Adjust based on image size
        height: "250px", // Adjust based on image size
        p:2
      }}
    >
      
      {FileURL && (
      <Box
        component="iframe"
        src={`data:application/pdf;base64,${FileURL}`}
        alt="National ID1"
        sx={{
          width: "100%",
          height: "100%",
          display: "block",
        }}
      />
    )}

      {/* Top-left Corner */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "20px",
          height: "20px",
          borderTop: "3px solid #0077b6", // Adjust color and thickness
          borderLeft: "3px solid #0077b6",
        }}
      />

      {/* Top-right Corner */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "20px",
          height: "20px",
          borderTop: "3px solid #0077b6",
          borderRight: "3px solid #0077b6",
        }}
      />

      {/* Bottom-left Corner */}
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "20px",
          height: "20px",
          borderBottom: "3px solid #0077b6",
          borderLeft: "3px solid #0077b6",
        }}
      />

      {/* Bottom-right Corner */}
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          width: "20px",
          height: "20px",
          borderBottom: "3px solid #0077b6",
          borderRight: "3px solid #0077b6",
        }}
      />
    </Box>
  );
};

export default RDLCPreview;
