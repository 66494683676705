import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import config from "../Common/config";
import { Backdrop, Card, Grid, Typography } from "@mui/material";
import CustomSpinner from "../Common/CustomSpinner";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import Welcome from "./Components/Welcome";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import DividerWithTextForMember from "../EnquiryCard/Components/DividerWithTextForMember";
import MemberDashboard_BG from "../Images/MemberDashboard_BG.png";

function MemberDashboard() {
  const [loadingCommon, setLoadingCommon] = useState(true);
  const [memberDet, setMemberDet] = useState([]);
  const [enquiryDet, setEnquiryDet] = useState([]);

  React.useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-in-out", // Easing function
      once: true, // Whether animation should happen only once or every time you scroll
      offset: 20, // Trigger animation when 200px from the element
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top-left corner
  }, []);

  const isMobile = useMediaQuery({
    query: "(max-width: 599px)",
  });

  const MemberDashboardDetailsLoad = useCallback((ID) => {
    axios
      .post(config.apiLink + "Alucast/MemberDashboardDetailsLoad", {
        MemberID: ID,
      })
      .then(
        (response) => {
          var Temp = response.data;
          setMemberDet(Temp["MemberDet"]);
          setEnquiryDet(Temp["EnquiryDet"]);
          setLoadingCommon(false);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const foo = params.get("MembersID");
    MemberDashboardDetailsLoad(foo);
  }, [MemberDashboardDetailsLoad]);

  return (
    <div
      // style={{backgroundImage: "linear-gradient(to top, #7a0b7b, #05102d)"}}
      style={{
        backgroundImage: `url(${MemberDashboard_BG})`,
        backgroundSize: "cover", // Ensures the image covers the entire container
        backgroundRepeat: "repeat", // Prevents the image from repeating
        backgroundPosition: "center", // Centers the image in the container
        height: "100vh", // Full viewport height
        // width: "100vw", // Full viewport width
        overflowY: "hidden",
      }}
      className="Member_Dash_body_margin_Enquiry"
    >
      {!loadingCommon && (
        <div>
          <Welcome />
          {/* <Card
            data-aos="fade-right"
            sx={{
              padding: "16px 24px 16px",
              borderRadius: "10px",
              border: "1px solid #d4e6e6",
              boxShadow: 0,
              mt: isMobile ? 1 : 0,
              mb: 2,
            }}
          > */}
          {/* style={{
        backgroundImage: `url(${MemberDashboard_BG})`,
        backgroundSize: "cover", // Ensures the image covers the entire container
        backgroundRepeat: "no-repeat", // Prevents the image from repeating
        backgroundPosition: "center", // Centers the image in the container
        height: "30vh", // Full viewport height
        width: "100vw", // Full viewport width
        overflow: "hidden",
      }} */}
          {/* <img src={MemberDashboard_BG} alt="MemberDashboard_BG"
          style={{
            height: "30vh", // Full viewport height
            width: "100vw", // Full viewport width
          }} 
          /> */}
          <DividerWithTextForMember>
            Customer&nbsp;Details
          </DividerWithTextForMember>
          <Grid
            container
            alignItems={"center"}
            data-aos="fade-right"
            sx={{ mb: 2, mt: 2 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="gridStart">
                <Typography
                  align="left"
                  className="InterRegular font_size16 color-cb4d85"
                  gutterBottom
                >
                  Name
                </Typography>
                <Typography
                  align="left"
                  className="InterMedium font_size18 color-b082ba"
                >
                  {memberDet[0].NAME}
                </Typography>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ mt: isMobile ? 1 : 0 }}
            >
              <div className="gridStart">
                <Typography
                  align="left"
                  className="InterRegular font_size16 color-cb4d85"
                  gutterBottom
                >
                  Member Card No
                </Typography>
                <Typography
                  align="left"
                  className="InterMedium font_size18 color-b082ba"
                >
                  {memberDet[0].MEMBERSCARDNO}
                </Typography>
              </div>
            </Grid>
          </Grid>
          {/* </Card> */}
          {/* <DividerWithTextForMember>
            Additional&nbsp;Details
          </DividerWithTextForMember> */}
          <Card
            data-aos="fade-left"
            sx={{
              padding: "16px 24px 16px",
              borderRadius: "10px",
              border: "1px solid #d4e6e6",
              boxShadow: 0,
              mt: 2,
              mb: 2,
              backgroundColor: "transparent",
              // "&:hover": {
              //   backgroundColor: "#05102d", // Add a subtle background color change
              //   boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Add a slight shadow on hover
              //   borderColor: "#05102d", // Change border color
              // },
            }}
          >
            <Grid container alignItems={"center"}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="gridCenter">
                  <Typography
                    align="center"
                    className="InterMedium font_size20 color-b082ba"
                    sx={{mb:2}}
                  >
                    Soon...
                  </Typography>
                  <Typography
                    align="center"
                    className="InterMedium font_size20 color-b082ba"
                    gutterBottom
                  >
                    Gateway to the best in<br></br> die casting technology<br></br> information
                  </Typography>
                  {/* <Typography
                    variant="caption"
                    align="center"
                    className="Faded_Text"
                    noWrap
                  >
                    Coming Soon...
                  </Typography> */}
                  {/* <Typography
                    align="left"
                    className="InterRegular font_size16 color-625668"
                  >
                    You can view your created enquiry.
                  </Typography> */}
                </div>
              </Grid>
            </Grid>
          </Card>
          {/* <Card
            data-aos="fade-right"
            sx={{
              padding: "16px 24px 16px",
              borderRadius: "10px",
              border: "1px solid #d4e6e6",
              boxShadow: 0,
              mt: isMobile ? 1 : 0,
              mb: 2,
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "#05102d", // Add a subtle background color change
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Add a slight shadow on hover
                borderColor: "#05102d", // Change border color
              },
            }}
          >
            <Grid container alignItems={"center"}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterMedium font_size20 color-b082ba"
                    gutterBottom
                  >
                    Spare Activities
                  </Typography>
                  <Typography
                    variant="caption"
                    align="center"
                    className="Faded_Text"
                    noWrap
                  >
                    Coming Soon...
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Card>
          <Card
            data-aos="fade-left"
            sx={{
              padding: "16px 24px 16px",
              borderRadius: "10px",
              border: "1px solid #d4e6e6",
              boxShadow: 0,
              mt: isMobile ? 1 : 0,
              mb: 2,
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "#05102d", // Add a subtle background color change
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Add a slight shadow on hover
                borderColor: "#05102d", // Change border color
              },
            }}
          >
            <Grid container alignItems={"center"}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterMedium font_size20 color-b082ba"
                    gutterBottom
                  >
                    Service Activities
                  </Typography>
                  <Typography
                    variant="caption"
                    align="center"
                    className="Faded_Text"
                    noWrap
                  >
                    Coming Soon...
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Card> */}
          {/* <Card
            // data-aos="fade-up"
            sx={{
              padding: "32px 24px 24px",
              borderRadius: "10px",
              border: "1px solid #d4e6e6",
              boxShadow: 0,
              mt: isMobile ? -2 : 0,
              mb: 2,
            }}
          >
            <Grid container alignItems={"center"}>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Member Card No
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {memberDet[0].MEMBERSCARDNO}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Company Name
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {memberDet[0].COMPANYNAME}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Name
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {memberDet[0].NAME}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Email
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {memberDet[0].EMAIL}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Mobile No
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {memberDet[0].MOBILENO}
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                lg={2}
                sx={{ mt: isMobile ? 0 : 1 }}
              >
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Designation
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {memberDet[0].DESIGNATION}
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                lg={2}
                sx={{ mt: isMobile ? 0 : 1 }}
              >
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Website
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {memberDet[0].WEBSITE}
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                sx={{ mt: isMobile ? 0 : 1 }}
              >
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Address
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {memberDet[0].ADDRESS}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Card> */}
          {/* {enquiryDet.length !== 0 &&
            enquiryDet.map((elem, ind) => (
              <Card
                sx={{
                  padding: "32px 24px 24px",
                  borderRadius: "10px",
                  border: "1px solid #d4e6e6",
                  boxShadow: 0,
                  mt: isMobile ? -2 : 0,
                  mb: 2,
                }}
              >
                <DividerWithTextForMember>
                  Mapped&nbsp;Customer&nbsp;Details
                </DividerWithTextForMember> 
                 <Card
                  sx={{
                    mt: 2,
                    mb: 2,
                    padding: "8px 16px",
                    bgcolor: "#fefefe",
                    border: "2px solid #005251",
                    borderRadius: "10px",
                    boxShadow:
                      "0px 1px 8px rgba(0, 255, 255, 0.6)"
                  }}
                  className="flexCenterCenterHeight"
                >
                  <Grid container>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <div className="gridStart">
                        <Typography
                          align="left"
                          className="InterRegular font_size16 color-636363"
                          gutterBottom
                        >
                          Customer Name
                        </Typography>
                        <Typography
                          align="left"
                          className="InterMedium font_size18 color-161616"
                        >
                          {elem.FIRSTNAME}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <div className="gridStart">
                        <Typography
                          align="left"
                          className="InterRegular font_size16 color-636363"
                          gutterBottom
                        >
                          Branch Name
                        </Typography>
                        <Typography
                          align="left"
                          className="InterMedium font_size18 color-161616"
                        >
                          {elem.BRANCHNAME}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <div className="gridStart">
                        <Typography
                          align="left"
                          className="InterRegular font_size16 color-636363"
                          gutterBottom
                        >
                          Customer Code
                        </Typography>
                        <Typography
                          align="left"
                          className="InterMedium font_size18 color-161616"
                        >
                          {elem.CUSTCODE}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <div className="gridStart">
                        <Typography
                          align="left"
                          className="InterRegular font_size16 color-636363"
                          gutterBottom
                        >
                          GSTIN
                        </Typography>
                        <Typography
                          align="left"
                          className="InterMedium font_size18 color-161616"
                        >
                          {elem.GSTIN}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Card> 

                 <DividerWithTextForMember>
                  Quote&nbsp;Details
                </DividerWithTextForMember> 
                 <Card
                  sx={{
                    mt: 2,
                    mb: 2,
                    padding: "8px 16px",
                    bgcolor: "#fefefe",
                    border: "2px solid #005251",
                    borderRadius: "10px",
                    boxShadow:
                      "0px 1px 8px rgba(0, 255, 255, 0.6)"
                  }}
                  className="flexCenterCenterHeight"
                >
                  <Grid container>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <div className="gridStart">
                        <Typography
                          align="left"
                          className="InterRegular font_size16 color-636363"
                          gutterBottom
                        >
                          Quote Sent
                        </Typography>
                        <Typography
                          align="left"
                          className="InterMedium font_size18 color-161616"
                        >
                          {elem.QUOTEREQD === 1 ? "Yes" : "No"}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <div className="gridStart">
                        <Typography
                          align="left"
                          className="InterRegular font_size16 color-636363"
                          gutterBottom
                        >
                          Remarks
                        </Typography>
                        <Typography
                          align="left"
                          className="InterMedium font_size18 color-161616"
                        >
                          {elem.REMARKS}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Card> 
                 <DividerWithTextForMember>
                  Enquiry&nbsp;Details
                </DividerWithTextForMember> 

                 <Card
                  sx={{
                    mt: 2,
                    padding: "8px 16px",
                    bgcolor: "#fefefe",
                    border: "2px solid #005251",
                    borderRadius: "10px",
                    boxShadow:
                      "0px 1px 8px rgba(0, 255, 255, 0.6)" 
                  }}
                  className="flexCenterCenterHeight"
                >
                  <Grid container>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <div className="gridStart">
                        <Typography
                          align="left"
                          className="InterRegular font_size16 color-636363"
                          gutterBottom
                        >
                          Brand Name
                        </Typography>
                        <Typography
                          align="left"
                          className="InterMedium font_size18 color-161616"
                        >
                          {elem.BRANDNAME}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <div className="gridStart">
                        <Typography
                          align="left"
                          className="InterRegular font_size16 color-636363"
                          gutterBottom
                        >
                          Product Name
                        </Typography>
                        <Typography
                          align="left"
                          className="InterMedium font_size18 color-161616"
                        >
                          {elem.PRODUCTNAME}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <div className="gridStart">
                        <Typography
                          align="left"
                          className="InterRegular font_size16 color-636363"
                          gutterBottom
                        >
                          Model Name
                        </Typography>
                        <Typography
                          align="left"
                          className="InterMedium font_size18 color-161616"
                        >
                          {elem.MODELNO}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <div className="gridStart">
                        <Typography
                          align="left"
                          className="InterRegular font_size16 color-636363"
                          gutterBottom
                        >
                          Follow Up Date
                        </Typography>
                        <Typography
                          align="left"
                          className="InterMedium font_size18 color-161616"
                        >
                          {moment(elem.FOLLOWUPDATE).format("DD MMM hh:mm a")}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <div className="gridStart">
                        <Typography
                          align="left"
                          className="InterRegular font_size16 color-636363"
                          gutterBottom
                        >
                          Created Date
                        </Typography>
                        <Typography
                          align="left"
                          className="InterMedium font_size18 color-161616"
                        >
                          {moment(elem.CREATEDDATE).format("DD MMM hh:mm a")}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      sx={{ mt: isMobile ? 0 : 1 }}
                    >
                      <div className="gridStart">
                        <Typography
                          align="left"
                          className="InterRegular font_size16 color-636363"
                          gutterBottom
                        >
                          Enquiry Description
                        </Typography>
                        <Typography
                          align="left"
                          className="InterMedium font_size18 color-161616"
                        >
                          {elem.ENQUIRYDESCRIPTION}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      sx={{ mt: isMobile ? 0 : 1 }}
                    >
                      <div className="gridStart">
                        <Typography
                          align="left"
                          className="InterRegular font_size16 color-636363"
                          gutterBottom
                        >
                          Lead Remarks
                        </Typography>
                        <Typography
                          align="left"
                          className="InterMedium font_size18 color-161616"
                        >
                          {elem.LEADREMARKS}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                      sx={{ mt: isMobile ? 0 : 1 }}
                    >
                      <div className="gridStart">
                        <Typography
                          align="left"
                          className="InterRegular font_size16 color-636363"
                          gutterBottom
                        >
                          Applicable To
                        </Typography>
                        <Typography
                          align="left"
                          className="InterMedium font_size18 color-161616"
                        >
                          {elem.APPLICABLETO}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </Card> 
              </Card>
            ))} */}
        </div>
      )}
      <Backdrop className="useStylesBackDrop" open={loadingCommon}>
        <CustomSpinner />
      </Backdrop>
    </div>
  );
}

export default MemberDashboard;
