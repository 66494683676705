import { useState, useEffect } from "react";
import axios from "axios";
import config from "../../Common/config.js";
import helpers from "../../Encryption/Encrypt.js";
import { Backdrop } from "@mui/material";
import CustomSpinner from "../../Common/CustomSpinner.js";
import BusinessCardUpload from "./BusinessCardUpload.js";

const PageRedirect = () => {
  const [loadingCommon, setLoadingCommon] = useState(true);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("APPUSERID");
    if (id) {
      const decodedData = atob(id);
      const keyval = decodedData.split("&");
      const appUserId = keyval[0].split("=")[1];
      LoginN(appUserId);
    }
  }, []);

  const LoginN = async (appUserId) => {
    setLoadingCommon(true);
    try {
      const response = await axios.post(
        config.apiLink + "Login/AppTokenLoginSales",
        {
          APPUSERID: appUserId,
        }
      );
      const Temp = response.data;
      localStorage.setItem("Theme", Temp["Login"][0].COLORCODE);
      localStorage.setItem("Version", Temp["Login"][0].UPLOADVERSION);
      localStorage.setItem("LSTATUS", Temp["Login"][0].STATUS);
      sessionStorage.setItem("SSTATUS", Temp["Login"][0].STATUS);
      sessionStorage.setItem("LOGID", Temp["Log"][0].LOGID);

      if (Temp["Login"][0].STATUS === "S") {
        if (Temp["Login"][0].IPVALIDATE === "S") {
          localStorage.setItem("LFirstLogin", "Y");
          sessionStorage.setItem("key3", Temp.Token["Token"]);
          localStorage.setItem("Token", Temp.Token["Token"]);
          let encryptedData = helpers.EncryptVal(Temp);
          sessionStorage.setItem("key", encryptedData);
          sessionStorage.setItem("BranchName", Temp["Login"][0].BRANCHNAME);
          sessionStorage.setItem("Username", Temp["Login"][0].USERNAME);
          sessionStorage.setItem("MobileNo", Temp["Login"][0].MOBILENO);
          sessionStorage.setItem("UserID", Temp["Login"][0].USERID);
          sessionStorage.setItem("UserTypeID", Temp["Login"][0].USERTYPEID);
          localStorage.setItem("LUsername", Temp["Login"][0].USERNAME);
          localStorage.setItem("LUserID", Temp["Login"][0].USERID);
          localStorage.setItem("LUserTypeID", Temp["Login"][0].USERTYPEID);
          // window.location.href = "/EnquiryFromCardList";
          setLoadingCommon(false); // Stop loading once API call is done
          setStatus(true);
        }
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setLoadingCommon(false); // Stop loading once API call is done
    } finally {
      setLoadingCommon(false); // Stop loading once API call is done
    }
  };

  return (
    <div>
       {loadingCommon ? (
        <div>Loading...</div>
      ) : status ? (
        <BusinessCardUpload />
      ) : (
        <div>false</div>
      )}
      {loadingCommon && (
        <Backdrop className="useStylesBackDrop" open={loadingCommon}>
          <CustomSpinner />
        </Backdrop>
      )}
    </div>
  )
};

export default PageRedirect;
