import { Close, Delete } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import { id } from "date-fns/locale";
import React, { useState, useCallback, useMemo } from "react";
import ImageCapture from "react-image-data-capture";
import configuser from "../../Common/config";

const App = (props) => {
  const { EnquiryId, onSuccess } = props;
  const [showImgCapture, setShowImgCapture] = useState(false);
  const [capturedImages, setCapturedImages] = useState([]); // Array to store captured images
  const config = useMemo(() => ({ video: true }), []);
  const [selectedImage, setSelectedImage] = useState(null);

  const onCapture = (imageData) => {
    const newImage = {
      webP: imageData.webP, // Image URL for preview
      file: imageData.file, // File object for upload
    };
    setCapturedImages((prev) => [...prev, newImage]); // Add new image to the list
    console.log(imageData.file);
  };


  const onError = useCallback((error) => {
    console.log(error);
  }, []);

  const handleRemoveImage = (index) => {
    setCapturedImages((prev) => prev.filter((_, i) => i !== index));
    setSelectedImage(null); // Close the dialog after deleting
  };

  const handleImageClick = (index) => {
    setSelectedImage(index); // Open dialog with the selected image
  };

  const handleDialogClose = () => {
    setSelectedImage(null); // Close the dialog
  };

  const handleEnquiryFinalSave = () => {
    EnquiryFinalSave();
  };

  

  const EnquiryFinalSave = () => {
    debugger;

    const selfieImages = capturedImages.map((image) => {
      return {
        Image:image.webP
      };
  });
    const param = {
      Images: selfieImages,
      EnquiryId: EnquiryId
    };

    axios.post(configuser.apiLink + "Alucast/EnquiryFinalSave", param).then(
      (response) => {
        console.log(response.data);
        // Optional: Clear images after successful save
        setCapturedImages([]);
        onSuccess();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <div >
      <Grid container alignItems="center" justifyContent="center" style={{ marginTop: "20px" }}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Grid container>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography
                align="left"
                className="InterRegular font_size16 color-fdd41c"
                gutterBottom
              >
                Selfie
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <div className="margin_top20">
                {showImgCapture && (
                  <ImageCapture
                    onCapture={onCapture}
                    onError={onError}
                    width={300}
                    userMediaConfig={config}
                  />
                )}
              </div>

              <div>
                <Button
                  variant="contained"
                  onClick={() => setShowImgCapture(!showImgCapture)}
                >
                  {showImgCapture ? "Stop Capture" : "Start Capture"}
                </Button>
              </div>

              <Grid
                container
                spacing={0}
                style={{ marginTop: "20px" }}
                justifyContent="flex-start"
              >
                {/* Display captured images */}
                {capturedImages.length > 0 && (
                  <Grid
                    container
                    style={{ marginTop: "20px" }}
                    justifyContent="flex-start"
                    spacing={2}
                  >
                    {capturedImages.map((image, index) => (
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={4}
                        lg={4}
                        key={index}
                        style={{ padding: "10px" }}
                      >
                        <img
                          src={image.webP}
                          alt={`Captured ${index}`}
                          style={{
                            width: "90px",
                            height: "100px",
                            objectFit: "cover",
                            borderRadius: "8px",
                          }}
                          onClick={() => handleImageClick(index)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>

              <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                style={{ marginTop: "20px" }}
              >
                <div>
                  <Button
                    onClick={() => handleEnquiryFinalSave()}
                    style={{
                      backgroundColor: "#054b4b",
                      color: "white",
                      margin: "10px 0",
                      cursor: "pointer",
                    }}
                  >
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div>
        {/* Dialog for image preview */}
        <Dialog open={selectedImage !== null} onClose={handleDialogClose}>
          <DialogTitle>
            Image Preview
            <IconButton
              style={{ position: "absolute", top: 8, right: 8 }}
              onClick={handleDialogClose}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {selectedImage !== null && (
              <img
                src={capturedImages[selectedImage]?.webP}
                alt="Selected"
                style={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "400px",
                  objectFit: "contain",
                  borderRadius: "8px",
                }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleRemoveImage(selectedImage)}
              color="secondary"
              variant="contained"
              startIcon={<Delete />}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default App;
