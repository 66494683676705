// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NewCampaign from "./Features/Mailerpage/NewCampaign";
import LoginDashboard from "./Features/LoginDashboard";
// import Login from "./Login/Login";
import Type from "./Features/EstimateMaster/Type";
import Category from "./Features/EstimateMaster/Category";
import Accessory from "./Features/EstimateMaster/Accessory";
import Report from "./Features/EstimateMaster/Report";
import TestingIP from "./Features/TestingIP";
import BusinessCardUpload from "./Components/Mailer/BusinessCardUpload";
import PageRedirect from "./Components/Mailer/PageRedirect";
import VoiceToTextTranslate from "./Components/VoiceToTextTranslate";
import PhotoPicker from "./EnquiryCard/Components/PhotoPicker";
import NotificationWithLibrary from "./Common/NotificationWithLibrary";
import MenuNavBar from "./Common/MenuNavBar";
import EnquiryFromCardList from "./EnquiryCard/EnquiryFromCardList";
import EnquiryFromCardDetails from "./EnquiryCard/EnquiryFromCardDetails";
import EnquiryCreation from "./EnquiryCreation/EnquiryCreation";
import EnquiryLogin from "./EnquiryLogin/EnquiryLogin";
import MemberDashboard from "./Member/MemberDashboard";
import CustomerMapping from "./EnquiryCard/CustomerMapping";
import QuoteStatusUpdate from "./EnquiryCard/QuoteStatusUpdate";
import EnquiryFromCardDetailsRePrint from "./EnquiryCard/EnquiryFromCardDetailsRePrint";
import ProductCommentsList from "./EnquiryCard/Components/ProductCommentsList";

const App = () => {
  return (
    <Router>
      <div>
      {window.location.pathname !== "/" && window.location.pathname !== "/Member" && window.location.pathname !== "/EnquiryCreation"&& <MenuNavBar />}
     
        <Routes>
          {/* <Route path="/" element={<Login />} /> */}
         
          <Route path="/" element={<EnquiryLogin />} />
          <Route path="/Dashboard" element={<LoginDashboard />} />
          <Route path="/NewCampaign" element={<NewCampaign />} />
          <Route path="/Type" element={<Type />} />
          <Route path="/Category" element={<Category />} />
          <Route path="/Accessory" element={<Accessory />} />
          <Route path="/Report" element={<Report />} />
          <Route path="/TestingIP" element={<TestingIP />} />
          <Route path="/BusinessCardExtraction" element={<BusinessCardUpload />} />
          <Route path="/PageRedirect" element={<PageRedirect />} />
          <Route path="/VoiceToTextTranslate" element={<VoiceToTextTranslate />} />
          <Route path="/NotificationWithLibrary" element={<NotificationWithLibrary />} />
          <Route path="/EnquiryFromCardList" element={<EnquiryFromCardList />} />
          <Route path="/EnquiryFromCardDetails" element={<EnquiryFromCardDetails />} />
          <Route path="/EnquiryCreation" element={<EnquiryCreation/>} />
          <Route path="/Member" element={<MemberDashboard />} />
          <Route path="/CustomerMapping" element={<CustomerMapping />} />
          <Route path="/QuoteStatusUpdate" element={<QuoteStatusUpdate />} />
          <Route path="/PhotoPicker" element={<PhotoPicker />} />          
          <Route path="/EnquiryFromCardDetailsRePrint" element={<EnquiryFromCardDetailsRePrint />} />
          <Route path="/ProductCommentsList" element={<ProductCommentsList />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
