import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CallToActionIcon from "@mui/icons-material/CallToAction";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PreviewIcon from "@mui/icons-material/Preview";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import { styled, Box } from "@mui/material";

const StyledTab = styled(Tab)({
  textTransform: "capitalize",
  "& .MuiTab-wrapper": {
    textTransform: "capitalize",
  },
});

export default function EnquiryFromCardListTabs(props) {
  var USERID = localStorage.getItem("USERID");
  const { Tabchange, EnquiryCount } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    debugger;
    setValue(newValue);
    Tabchange(newValue);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="icon position tabs example"
        sx={{ alignItems: "center" }}
      >
        <StyledTab
          icon={<CallToActionIcon />}
          iconPosition="start"
          label={`All (${EnquiryCount.ALLENQUIRYCOUNT})`}
        />
        {(USERID === "9" ||
          USERID === "11") && (
              <StyledTab
                icon={<CallToActionIcon />}
                iconPosition="start"
                label={`New Enquiry (${EnquiryCount.NEWENQUIRYCOUNT})`}
              />
          )}
          {(USERID === "9" ||
          USERID === "11") && (
              <StyledTab
                icon={<PrintOutlinedIcon />}
                iconPosition="start"
                label={`To Be Printed (${EnquiryCount.TOBEPRINTEDCOUNT})`}
              />
          )}
          {(USERID === "9" ||
          USERID === "11") && (
              <StyledTab
                icon={<ContactEmergencyIcon />}
                iconPosition="start"
                label={`Customer To Be Mapped (${EnquiryCount.TOBEMAPPEDCOUNT})`}
              />
          )}
          {(USERID === "9" ||
          USERID === "11") && (
              <StyledTab
                icon={<PendingActionsIcon />}
                iconPosition="start"
                label={`To Be Quoted (${EnquiryCount.TOBQUOTEDCOUNT})`}
              />
          )}
          {(USERID === "9" ||
          USERID === "11") && (
              <StyledTab
                icon={<PreviewIcon />}
                iconPosition="start"
                label="View Quote"
              />
          )}
      </Tabs>
    </Box>
  );
}
