import React, { useState } from "react";
import { Box } from "@mui/material";

const ImageWithBorders = (props) => {
  const { FileURL } = props;
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100; // X position in percentage
    const y = ((e.clientY - rect.top) / rect.height) * 100; // Y position in percentage
    setMousePosition({ x, y });
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "450px", // Set the initial size of the container
        height: "350px",
        overflow: "hidden", // Ensure the image doesn't overflow
      }}
      onMouseMove={handleMouseMove}
    >
      {/* The Image */}
      {FileURL && (
        <Box
          component="img"
          src={`data:image/png;base64,${FileURL}`}
          alt="Zoomable Image"
          sx={{
            width: "100%",
            height: "100%",
            display: "block",
            transformOrigin: `${mousePosition.x}% ${mousePosition.y}%`, // Set the zoom point
            transform: "scale(1)", // Ultra zoom level (adjust as needed)
            transition:
              "transform 0.1s ease-in-out, transform-origin 0.1s ease", // Smooth zoom effect
            "&:hover": {
              transform: "scale(3)",
            },
          }}
        />
      )}

      {/* Overlay border for aesthetics */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          border: "3px solid #0077b6", // Border color and thickness
          pointerEvents: "none", // Ensure the border doesn't interfere with hover
        }}
      />
    </Box>
  );
};

export default ImageWithBorders;
