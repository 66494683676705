import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import SelfieUploader from "./SelfieUploader";
import PhotoPicker from "../EnquiryCard/Components/PhotoPicker";
import config from "../Common/config";
import axios from "axios";
import moment from "moment";
import { CheckBox, Close, Delete } from "@mui/icons-material";
import { Tabs, Tab } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { color } from "framer-motion";
import CustomSpinner from "../Common/CustomSpinner";

const   EnquiryCreation = () => {

  var EmpID = localStorage.getItem("EMPID");

  const [IDCardIndex, setIDCardIndex] = useState(0);
  const [productDet, setProductDet] = useState([]);
  const [brandDet, setBrandDet] = useState([]);
  const [modelDet, setModelDet] = useState([]);
  const [product, setProduct] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedModels, setSelectedModels] = useState([]);
  const [productErr, setProductErr] = useState(false);
  const [customerText, setCustomerText] = useState("");
  const [leadText, setLeadText] = useState("");
  const [customerLanguage, setCustomerLanguage] = useState("en-US"); // Default language is English
  const [leadLanguage, setLeadLanguage] = useState("en-US"); // Default language is English
  const [isCustomerRecording, setIsCustomerRecording] = useState(false);
  const [isLeadRecording, setIsLeadRecording] = useState(false);
  const [customerAudioFiles, setCustomerAudioFiles] = useState([]);
  const [leadAudioFiles, setLeadAudioFiles] = useState([]); // To store list of audio files and their translated text
  const [mediaRecorder, setMediaRecorder] = useState(null); // To store MediaRecorder instance
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [selectedDateTimeErr, setSelectedDateTimeErr] = useState(false);
  const [enquiryList, setEnquiryList] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const [selectedCardID, setSelectedCardID] = useState(null);
  const [applicableTo, setApplicableTo] = useState([]);
  const [applicableToErr, setApplicableToErr] = useState(false);
  const [selectedMember, setSelectedMember] = useState([]);
  const [memberSelecteddProduct, setMemberSelecteddProduct] = useState([]);
  const [recordedCustomerVoice, setRecordedCustomerVoice] = useState([]);
  const [recordedLeadVoice, setRecordedLeadVoice] = useState([]);
  const [discussApplicable, setDiscussApplicable] = useState([]);
  const [leadBy, setLeadBy] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [enquiryId, setEnquiryId] = useState(0);
  const [EnquiryDoneStatus, setEnquiryDoneStatus] = useState(0);
  const [enquiryImages, setEnquiryImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null); // For dialog
  const [modelNotes, setModelNotes] = useState({});
  const [productNotes, setProductNotes] = useState({});
  const [brandhovered, setBrandHovered] = useState(null);
  const [producthovered, setProductHovered] = useState(null);
  const [modelhovered, setModelHovered] = useState(null);
  const [loadingCommon, setLoadingCommon] = useState(true);
  const [afterLoadingCommon, setAfterLoadingCommon] = useState(false);

  const isMobile = useMediaQuery({
    query: "(max-width: 599px)",
  });

  const handleCardClick = (index, data) => {
    setSelectedTab(index); // Change the selected tab to the clicked card
    setEnquiryDoneStatus(data.ENQUIRYDONE);
  };

  const handleNoteChange = (modelId, value) => {
    setModelNotes((prev) => ({
      ...prev,
      [modelId]: value,
    }));
  };

  const handleImageClick = (image) => {
    debugger;
    setSelectedImage(image.IMAGEURL); // Open dialog with the selected image
  };

  const handleDialogClose = () => {
    setSelectedImage(null); // Close the dialog
  };

  // const handleRemoveImage = (imageToRemove) => {
  //     setEnquiryImages((prevImages) => prevImages.filter((img) => img !== imageToRemove));
  //     setSelectedImage(null); // Close the dialog after deleting
  // };

  const EnquiryCreationListLoad = useCallback(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const Id = params.get("id");

    axios
      .post(config.apiLink + "Alucast/EnquiryCreationLoad", {
        MembershipId: Id,
      })
      .then(
        (response) => {
          // debugger;
          var Temp = response.data;
          setEnquiryList(Temp["EnquiryList"]);
          if (Temp["EnquiryList"].length > 0) {
            setLeadBy(Temp["EnquiryList"][0]["LEADBYID"]);
          }
          setMembersList(Temp["MembersDet"]);
          if (Temp["MembersDet"].length > 0) {
            setEnquiryDoneStatus(Temp["MembersDet"][0].ENQUIRYDONE);
          }
          setProductDet(Temp["ProductDet"]);
          setBrandDet(Temp["BrandDet"]);
          setModelDet(Temp["ModelDet"]);
          setLoadingCommon(false);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  const handleMembersDetLoad = () => {
    AfterEnquiryCreationListLoad();
  };

  const MembersEnquiryData = (Id) => {
    // debugger;
    setAfterLoadingCommon(true);
    axios
      .post(config.apiLink + "Alucast/EnquiryMembersData", {
        MemberId: Id,
      })
      .then(
        (response) => {
          // debugger;
          var Temp = response.data;
          setMemberSelecteddProduct(Temp["ProductDet"]);
          setRecordedCustomerVoice(Temp["CustomerVoiceDet"]);
          setRecordedLeadVoice(Temp["LeadVoiceDet"]);
          setDiscussApplicable(Temp["DiscussApplicableTo"]);
          if (Temp["DiscussApplicableTo"].length > 0) {
            setEnquiryId(Temp["DiscussApplicableTo"][0]["ENQUIRYID"]);
          }
          setEnquiryImages(Temp["EnquiryImage"]);
          setAfterLoadingCommon(false);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    EnquiryCreationListLoad();
  }, [EnquiryCreationListLoad]);

  useEffect(() => {
    if (membersList.length > 0) {
      const defaultMember = membersList.find(
        (member) => member.CURRENTMEMBER === 1
      );
      if (defaultMember) {
        setSelectedCardID(defaultMember.ID);
        setSelectedTab(
          membersList.findIndex((member) => member.ID === defaultMember.ID)
        );
        MembersEnquiryData(defaultMember.ID);
      }
    }
  }, [membersList]);

  const AfterEnquiryCreationListLoad = () => {
    setAfterLoadingCommon(true);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const Id = params.get("id");
    axios
      .post(config.apiLink + "Alucast/EnquiryCreationLoad", {
        MembershipId: Id,
      })
      .then(
        (response) => {
          // debugger;
          var Temp = response.data;
          setEnquiryList(Temp["EnquiryList"]);
          if (Temp["EnquiryList"].length > 0) {
            setLeadBy(Temp["EnquiryList"][0]["LEADBYID"]);
          }
          setMembersList(Temp["MembersDet"]);
          if (Temp["MembersDet"].length > 0) {
            setEnquiryDoneStatus(Temp["MembersDet"][0].ENQUIRYDONE);
          }
          setProductDet(Temp["ProductDet"]);
          setBrandDet(Temp["BrandDet"]);
          setModelDet(Temp["ModelDet"]);
          setAfterLoadingCommon(false);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const apiKey = "AIzaSyBW6wtYv91SN7bOoMEyXK4Nmjv6S_6nLBc"; // Replace with your Google API key

  // Available languages
  const languages = [
    { code: "en-US", label: "English" },
    { code: "hi-IN", label: "Hindi" },
    { code: "te-IN", label: "Telugu" },
    { code: "ta-IN", label: "Tamil" },
    { code: "ml-IN", label: "Malayalam" },
    { code: "kn-IN", label: "Kannada" },
    { code: "ur-IN", label: "Urdu" },
    { code: "zh-TW", label: "Taiwanese Mandarin" },
  ];

  const startRecording = async (language, source) => {
    if (!window.SpeechRecognition && !window.webkitSpeechRecognition) {
      alert("Your browser does not support Speech Recognition.");
      return;
    }

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();
    recognition.lang = language; // Set language dynamically
    recognition.interimResults = false;
    recognition.continuous = true; // Continue listening until stopped

    recognition.onstart = () => {
      if (source === "Customer") {
        setIsCustomerRecording(true);
      } else if (source === "Lead") {
        setIsLeadRecording(true);
      }
    };

    recognition.onresult = (event) => {
      let transcript = "";
      for (let i = event.resultIndex; i < event.results.length; i++) {
        transcript += event.results[i][0].transcript + " ";
      }
      translateText(transcript.trim(), source); // Pass source to translation
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
    };

    recognition.onend = () => {
      if (source === "Customer") {
        setIsCustomerRecording(false);
      } else if (source === "Lead") {
        setIsLeadRecording(false);
      }
    };

    recognition.start();

    // Record audio as well
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const recorder = new MediaRecorder(stream);
    setMediaRecorder(recorder); // Store the media recorder instance

    const chunks = [];
    recorder.ondataavailable = (e) => chunks.push(e.data);
    recorder.onstop = () => {
      const blob = new Blob(chunks, { type: "audio/wav" });
      const audioUrl = URL.createObjectURL(blob); // Create URL for the audio blob
      const newAudioFile = {
        url: audioUrl,
        id: Date.now(), // Unique ID for each file
        translatedText: "", // Initial empty translated text
      };

      // Add to respective audio file list
      if (source === "Customer") {
        setCustomerAudioFiles((prevFiles) => [...prevFiles, newAudioFile]);
      } else if (source === "Lead") {
        setLeadAudioFiles((prevFiles) => [...prevFiles, newAudioFile]);
      }
    };
    recorder.start();
  };

  const stopRecording = (source) => {
    if (mediaRecorder) {
      mediaRecorder.stop(); // Stop the media recorder
    }
    if (source === "Customer") {
      setIsCustomerRecording(false);
    } else if (source === "Lead") {
      setIsLeadRecording(false);
    }
  };

  // Translation Function
  const translateText = async (text, source) => {
    const url = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          q: text,
          target: "en", // Translate to English
          format: "text",
        }),
      });

      if (response.ok) {
        const data = await response.json();
        const translated = data.data.translations[0].translatedText;

        if (source === "Customer") {
          setCustomerAudioFiles((prevFiles) => {
            const updatedFiles = [...prevFiles];
            updatedFiles[updatedFiles.length - 1].translatedText = translated;

            const combinedText = updatedFiles
              .map((file) => file.translatedText)
              .join("\n"); // Adding newline for each entry
            setCustomerText(combinedText);
            return updatedFiles;
          });
        } else if (source === "Lead") {
          setLeadAudioFiles((prevFiles) => {
            const updatedFiles = [...prevFiles];
            updatedFiles[updatedFiles.length - 1].translatedText = translated;

            const combinedText = updatedFiles
              .map((file) => file.translatedText)
              .join("\n"); // Adding newline for each entry
            setLeadText(combinedText);
            return updatedFiles;
          });
        }
      } else {
        console.error("Translation API Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Delete an audio file
  const deleteAudioFile = (id, source) => {
    if (source === "Customer") {
      setCustomerAudioFiles((prevFiles) =>
        prevFiles.filter((file) => file.id !== id)
      );
    } else if (source === "Lead") {
      setLeadAudioFiles((prevFiles) =>
        prevFiles.filter((file) => file.id !== id)
      );
    }
  };

  const handleDateChange = (newValue) => {
    setSelectedDateTime(dayjs(newValue));
  };

  const onValidation = () => {
    let val = true;
    if (product === null) {
      val = false;
      setProductErr(true);
    }
    if (selectedDateTime === null) {
      val = false;
      setSelectedDateTimeErr(true);
    }
    if (selectedMember.length === 0) {
      val = false;
      setApplicableToErr(true);
    }
    return val;
  };
  const handleCreateEnquiry = (data) => {
    console.log(selectedBrands);
    console.log(selectedProducts);
    console.log(selectedModels);
    // debugger;
    // if (onValidation()) {
    setAfterLoadingCommon(true);
    CreateEnquirySave(data);
    // }
  };

  const CreateEnquirySave = (data) => {
    debugger;
    const customerVoiceData = customerAudioFiles.map((files) => {
      return {
        FileId: files.id,
        FileUrl: files.url,
        FileText: files.translatedText,
      };
    });
    const selectedbranddata = combinedArray.map((brand) => {
      return {
        BrandId: brand.BRANDID,
        ProductId: brand.PRODUCTID,
        ModelId: brand.MODELID,
        ModelRemarks: brand.REMARKS,
        ProductComments: brand.PRODUCT_REMARKS
      };
    });

    const leadVoiceData = leadAudioFiles.map((files) => {
      return {
        FileId: files.id,
        FileUrl: files.url,
        FileText: files.translatedText,
      };
    });
    const applicableToData = selectedMember.map((member) => {
      return {
        Id: member.ID,
        Name: member.label,
      };
    });
    var param = {
      Brand: selectedbranddata,
      CustomerVoice: customerVoiceData,
      CustomerRemarks: customerText,
      LeadVoice: leadVoiceData,
      LeadRemarks: leadText,
      NextDiscussionDate: selectedDateTime,
      ApplicableTo: applicableToData,
      LeadById: leadBy,
      EnquiryCardId: data.ENQUIRYCARDID,
      EnquiryCardMemberId: data.ID,
      CreatedBy:EmpID,
    };
    axios.post(config.apiLink + "Alucast/EnquiryCreationSave", param).then(
      (response) => {
        // debugger;
        var Temp = response.data;
        AfterEnquiryCreationListLoad();
        MembersEnquiryData(data.ID);
        setSelectedBrands([]);
        setSelectedProducts([]);
        setSelectedModels([]);
        setCustomerText("");
        setLeadText("");
        setSelectedDateTime(null);
        setApplicableTo([]);
        setCustomerAudioFiles([]);
        setLeadAudioFiles([]);
        window.location.reload();
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const handleMemberCardData = (data, index) => {
    debugger;
    setEnquiryDoneStatus(data.ENQUIRYDONE);
    setSelectedTab(index);
    MembersEnquiryData(data.ID);
  };

  const handleSelectedMemberDelete = (chipToDelete) => () => {
    setSelectedMember((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const toggleSelection = (id, selectedList, setSelectedList) => {
    setSelectedList(
      (prevSelected) =>
        prevSelected.includes(id)
          ? prevSelected.filter((selectedId) => selectedId !== id) // Remove if already selected
          : [...prevSelected, id] // Add if not selected
    );
  };

  // Filtered Products based on Selected Brands
  const filteredProducts = useMemo(() => {
    return productDet.filter((product) =>
      selectedBrands.includes(product.BRANDID)
    );
  }, [selectedBrands, productDet]);

  // Filtered Models based on Selected Brands and Products
  const filteredModels = useMemo(() => {
    return modelDet.filter(
      (model) =>
        selectedBrands.includes(model.BRANDID) &&
        (selectedProducts.includes(model.PRODUCTID) || model.PRODUCTID === null)
    );
  }, [selectedBrands, selectedProducts, modelDet]);

  const groupedModels = useMemo(() => {
    return filteredModels.reduce((acc, model) => {
      const associatedBrand =
        brandDet.find((brand) => brand.BRANDID === model.BRANDID)?.BRANDNAME ||
        "Unknown Brand";
      const associatedProduct =
        productDet.find((product) => product.PRODUCTID === model.PRODUCTID)
          ?.PRODUCTNAME || "Unknown Product";
      const key = `${associatedBrand} | Product: ${associatedProduct}`;

      if (!acc[key]) {
        acc[key] = { associatedBrand, associatedProduct, models: [] };
      }
      acc[key].models.push(model);
      return acc;
    }, {});
  }, [filteredModels, brandDet, productDet]);

  const combinedArray = useMemo(() => {
    return selectedBrands.flatMap((brandId) => {
      const relatedProducts = selectedProducts.filter((productId) =>
        filteredProducts.some(
          (product) =>
            product.PRODUCTID === productId && product.BRANDID === brandId
        )
      );

      if (relatedProducts.length === 0) {
        const relatedModels = selectedModels.filter((modelId) =>
          filteredModels.some(
            (model) =>
              model.MODELID === modelId &&
              model.BRANDID === brandId &&
              (model.PRODUCTID === null || model.PRODUCTID === 0)
          )
        );

        if (relatedModels.length > 0) {
          return relatedModels.map((modelId) => ({
            BRANDID: brandId,
            PRODUCTID: 0,
            MODELID: modelId,
            REMARKS: modelNotes[modelId] || "",
            PRODUCT_REMARKS: "",
          }));
        }

        return [{ BRANDID: brandId, PRODUCTID: 0, MODELID: 0, REMARKS: "", PRODUCT_REMARKS: "" }];
      }

      return relatedProducts.flatMap((productId) => {
        const relatedModels = selectedModels.filter((modelId) =>
          filteredModels.some(
            (model) =>
              model.MODELID === modelId &&
              model.PRODUCTID === productId &&
              model.BRANDID === brandId
          )
        );

        const productRemarks = productNotes[productId] || "";

        if (relatedModels.length === 0) {
          return [
            {
              BRANDID: brandId,
              PRODUCTID: productId,
              MODELID: 0,
              REMARKS: "",
              PRODUCT_REMARKS: productRemarks,
            },
          ];
        }

        return relatedModels.map((modelId) => ({
          BRANDID: brandId,
          PRODUCTID: productId,
          MODELID: modelId,
          REMARKS: modelNotes[modelId] || "",
          PRODUCT_REMARKS: productRemarks,
        }));
      });
    });
  }, [
    selectedBrands,
    selectedProducts,
    selectedModels,
    filteredProducts,
    filteredModels,
    modelNotes,
    productNotes, // Add dependency
  ]);


  return (
    <div className="EnquiryCreation_container">
      {!loadingCommon && (
        <div className="body_margin_Enquiry_Creation">
          <Card
            className="login_card"
            sx={{
              padding: "32px 24px 24px",
              borderRadius: "10px",
              border: "1px solid #d4e6e6",
              boxShadow: 0,
              mt: 1,
              mb: 2,
            }}
          >
            {enquiryList.map((elem, index) => (
              <Grid container alignItems={"center"} justifyContent={"center"}>
                <Grid item xs={6} sm={6} md={2} lg={2}>
                  <div className="gridStart">
                    <Typography
                      align="left"
                      className="InterRegular font_size16 color-fdd41c"
                      gutterBottom
                    >
                      Lot No
                    </Typography>
                    <Typography
                      align="left"
                      className="InterMedium font_size18 color-ffffff"
                    >
                      {elem.LOTNO}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={2} lg={2}>
                  <div className="gridStart">
                    <Typography
                      align="left"
                      className="InterRegular font_size16 color-fdd41c"
                      gutterBottom
                    >
                      Lead By
                    </Typography>
                    <Typography
                      align="left"
                      className="InterMedium font_size18 color-ffffff"
                    >
                      {elem.LEADBYNAME}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={2} lg={2}>
                  <div className="gridStart">
                    <Typography
                      align="left"
                      className="InterRegular font_size16 color-fdd41c"
                      gutterBottom
                    >
                      Card/Print
                    </Typography>
                    <Typography
                      align="left"
                      className="InterMedium font_size18 color-ffffff"
                    >
                      {elem.TOTALCARDS}/{elem.PRINTEDCARDS}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={2} lg={2}>
                  <div className="gridStart">
                    <Typography
                      align="left"
                      className="InterRegular font_size16 color-fdd41c"
                      gutterBottom
                    >
                      Taken By
                    </Typography>

                    <Typography
                      align="left"
                      className="InterMedium font_size18 color-ffffff"
                    >
                      {elem.TAKENBY}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <div className="gridStart">
                    <Typography
                      align="left"
                      className="InterRegular font_size16 color-fdd41c"
                      gutterBottom
                    >
                      Enquiry Time
                    </Typography>
                    <Typography
                      align="left"
                      className="InterMedium font_size18 color-ffffff"
                    >
                      {moment(elem.CREATEDTIME).format("DD MMM hh:mm a")}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            ))}
          </Card>
          <Box>
            <Grid container spacing={2}>
              {membersList.map((member, index) => (
                <Grid item xs={4} sm={4} md={4} lg={4} key={member.ID}>
                  <Box
                    sx={{ m: 2, cursor: "pointer" }} // Disable cursor for unselected cards
                    onClick={() =>
                      member.ENQUIRYDONE === 1
                        ? handleMemberCardData(member, index)
                        : handleCardClick(index, member)
                    } // Only allow click on selected cards
                  >
                    <Card
                      className="flexCenterCenterHeight login_card"
                      sx={{
                        borderTop: "1px solid #d4e6e6",
                        borderLeft: "1px solid #d4e6e6",
                        borderRight: "1px solid #d4e6e6",
                        borderRadius: "10px 10px 0px 0px",
                        boxShadow: 0,
                        p: 1,
                        bgcolor: selectedTab === index ? "#d4e6e6" : "#ffffff", // Highlight selected card
                        opacity: selectedTab === index ? 1 : 0.5, // Dim non-selected cards
                        ...(isMobile && {
                          height: "70px",
                          width: "70px",
                        }), // Apply heigh
                      }}
                      fullWidth
                    >
                      <AccountBoxOutlinedIcon
                        sx={{ fontSize: !isMobile ? "100px" : "70px" }}
                      />
                    </Card>
                    <Card
                      className="flexCenterCenterHeight"
                      sx={{
                        borderBottom: "1px solid #d4e6e6",
                        borderLeft: "1px solid #d4e6e6",
                        borderRight: "1px solid #d4e6e6",
                        borderRadius: "0px 0px 10px 10px",
                        boxShadow: 0,
                        bgcolor:
                          member.ENQUIRYDONE === 1
                            ? "#4CAF50"
                            : selectedTab === index
                              ? "#FFEB3B"
                              : "red",
                        // Highlight based on CURRENTMEMBER
                        pointerEvents: selectedTab === index ? "auto" : "none", // Disable interaction with non-selected cards
                        ...(isMobile && {
                          height: "70px",
                          width: "87px",
                        }), // Apply heigh
                      }}
                      fullWidth
                    >
                      <Typography
                        align="center"
                        className={`${isMobile
                            ? "InterBold font_size10 color-616161"
                            : "InterBold font_size18 color-616161"
                          }`}
                        sx={{ p: 1 }}
                      >
                        {member.CONTACTNAME}
                      </Typography>
                    </Card>
                  </Box>
                  <Box
                    sx={{ m: 2, cursor: "pointer" }} // Disable cursor for unselected cards
                  >
                    <Card
                      className="login_card"
                      sx={{
                        borderBottom: "1px solid #d4e6e6",
                        borderLeft: "1px solid #d4e6e6",
                        borderRight: "1px solid #d4e6e6",
                        borderRadius: "0px 0px 10px 10px",
                        boxShadow: 0,
                        // Disable interaction with non-selected cards
                        // Apply heigh
                      }}
                      fullWidth
                    >
                      <Typography
                        align="center"
                        className={`${isMobile
                            ? "InterBold font_size10 color-fdd41c"
                            : "InterBold font_size18 color-fdd41c"
                          }`}
                        sx={{ p: 1 }}
                      >
                        Member Card No
                      </Typography>
                      <Typography
                        align="center"
                        className={`${isMobile
                            ? "InterBold font_size10 color-ffffff"
                            : "InterBold font_size18 color-ffffff"
                          }`}
                      // sx={{ p: 1 }}
                      >
                        {member.MEMBERSCARDNO}
                      </Typography>
                    </Card>
                  </Box>
                </Grid>
              ))}
            </Grid>

            {membersList.map((mem, index) => (
              <>
                {EnquiryDoneStatus === 1 && selectedTab === index && (
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Card
                        className="login_card"
                        // data-aos="fade-up"
                        sx={{
                          padding: "32px 24px 24px",
                          borderRadius: "10px",
                          border: "1px solid #d4e6e6",
                          boxShadow: 0,
                          mt: 1,
                          mb: 2,
                        }}
                      >
                        <Grid container>
                          <Grid container>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              {memberSelecteddProduct.length !== 0 && (
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-fdd41c"
                                    gutterBottom
                                  >
                                    Brand
                                  </Typography>
                                  {memberSelecteddProduct.map((elem, index) => (
                                    <Typography
                                      align="left"
                                      className="InterMedium font_size18 color-ffffff"
                                      key={index}
                                    >
                                      {index + 1}.{elem.BRANDNAME}-
                                      {elem.PRODUCTNAME}-{elem.MODELNO}-
                                      {elem.MODELREMARKS}
                                    </Typography>
                                  ))}
                                </div>
                              )}
                            </Grid>
                            <Grid item xs={6} sm={6} md={2} lg={2}>
                              {discussApplicable.map((discuss, index) => (
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-fdd41c"
                                    gutterBottom
                                  >
                                    Next Discussion Date
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-ffffff"
                                  >
                                    {discuss.NEXTDISCUSSIONDATE}
                                  </Typography>
                                </div>
                              ))}
                            </Grid>
                            <Grid item xs={6} sm={6} md={2} lg={2}>
                              {discussApplicable.map((discuss, index) => (
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-fdd41c"
                                    gutterBottom
                                  >
                                    ApplicableTo
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-ffffff"
                                  >
                                    {discuss.APPLICABLETO}
                                  </Typography>
                                </div>
                              ))}
                            </Grid>
                            <Grid item xs={6} sm={6} md={2} lg={2}>
                              {discussApplicable.map((discuss, index) => (
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-fdd41c"
                                    gutterBottom
                                  >
                                    Customer Name
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-ffffff"
                                  >
                                    {discuss.CUSTOMERNAME}
                                  </Typography>
                                </div>
                              ))}
                            </Grid>
                            <Grid item xs={6} sm={6} md={2} lg={2}>
                              {discussApplicable.map((discuss, index) => (
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-fdd41c"
                                    gutterBottom
                                  >
                                    Company Name
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-ffffff"
                                  >
                                    {discuss.COMPANYNAME}
                                  </Typography>
                                </div>
                              ))}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              {discussApplicable.map((discuss, index) => (
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-fdd41c"
                                    gutterBottom
                                  >
                                    Customer Remarks
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-ffffff"
                                  >
                                    {discuss.CUSTOMERREMARKS
                                      ? discuss.CUSTOMERREMARKS
                                      : "N/A"}
                                  </Typography>
                                </div>
                              ))}
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              {discussApplicable.map((discuss, index) => (
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-fdd41c"
                                    gutterBottom
                                  >
                                    Lead Remarks
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-ffffff"
                                  >
                                    {discuss.LEADREMARKS
                                      ? discuss.LEADREMARKS
                                      : "N/A"}
                                  </Typography>
                                </div>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    {enquiryImages.length > 0 ? (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Card
                          className="login_card"
                          // data-aos="fade-up"
                          sx={{
                            padding: "32px 24px 24px",
                            borderRadius: "10px",
                            border: "1px solid #d4e6e6",
                            boxShadow: 0,
                            mt: 1,
                            mb: 2,
                          }}
                        >
                          <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <div className="gridStart">
                                <Typography
                                  align="left"
                                  className="InterRegular font_size16 color-fdd41c"
                                  gutterBottom
                                >
                                  Uploaded images
                                </Typography>

                                <Grid container>
                                  {enquiryImages.map((images, index) => (
                                    <Grid
                                      item
                                      xs={4}
                                      sm={4}
                                      md={2}
                                      lg={2}
                                      key={index}
                                    >
                                      <Typography
                                        align="left"
                                        className="InterMedium font_size18 color-ffffff"
                                      >
                                        <div style={{ position: "relative" }}>
                                          <img
                                            src={images.IMAGEURL}
                                            alt={`Captured or uploaded ${index}`}
                                            style={{
                                              width: "90px", // Takes the full width of its container
                                              height: "100px", // Ensure consistent height
                                              objectFit: "cover", // Crop images to fill the space
                                              borderRadius: "8px",
                                            }}
                                            onClick={() =>
                                              handleImageClick(images)
                                            }
                                          />
                                        </div>
                                      </Typography>
                                    </Grid>
                                  ))}
                                </Grid>
                              </div>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Card
                          className="login_card"
                          // data-aos="fade-up"
                          sx={{
                            padding: "32px 24px 24px",
                            borderRadius: "10px",
                            border: "1px solid #d4e6e6",
                            boxShadow: 0,
                            mt: 1,
                            mb: 2,
                          }}
                        >
                          {/* <SelfieUploader EnquiryId={enquiryId} /> */}
                          <PhotoPicker
                            EnquiryId={enquiryId}
                            onSuccess={handleMembersDetLoad}
                          />
                        </Card>
                      </Grid>
                    )}
                  </Grid>
                )}
                {EnquiryDoneStatus === 2 && selectedTab === index && (
                  <Box sx={{ mt: 3 }}>
                    <Grid
                      container
                      alignItems={"center"}
                      justifyContent={"center"}
                      style={{ marginTop: "20px" }}
                    >
                      <Grid
                        container
                        alignItems={"center"}
                        justifyContent={"center"}
                        style={{ marginTop: "20px" }}
                      >
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              <Typography
                                align="left"
                                className="InterMedium font_size18 color-fdd41c margin_left10"
                              >
                                Brand
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                              <div>
                                {brandDet.map((brand) => (
                                  <Button
                                    key={brand.BRANDID}
                                    style={{
                                      backgroundColor: selectedBrands.includes(
                                        brand.BRANDID
                                      )
                                        ? brandhovered === brand.BRANDID
                                          ? "darkred" // Hover effect
                                          : "red"
                                        : brandhovered === brand.BRANDID
                                          ? "darkgray" // Hover effect
                                          : "gray",
                                      margin: "5px",
                                      color: "white",
                                    }}
                                    onClick={() =>
                                      toggleSelection(
                                        brand.BRANDID,
                                        selectedBrands,
                                        setSelectedBrands
                                      )
                                    }
                                    onMouseEnter={() =>
                                      setBrandHovered(brand.BRANDID)
                                    }
                                    onMouseLeave={() => setBrandHovered(null)}
                                  >
                                    {brand.BRANDNAME}
                                  </Button>
                                ))}
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* Product Selection */}
                      {selectedBrands.length > 0 && (
                        <Grid
                          container
                          alignItems={"center"}
                          justifyContent={"center"}
                          style={{ marginTop: "20px" }}
                        >
                         <Grid item xs={12} sm={12} md={8} lg={8}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Typography
                            align="left"
                            className="InterMedium font_size18 color-fdd41c margin_left10"
                          >
                            Products
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                          <div>
                            {filteredProducts.map((product) => (
                              <div key={product.PRODUCTID} style={{ marginBottom: "10px" }}>
                                {/* Product Button */}
                                <Button
                                  style={{
                                    backgroundColor: selectedProducts.includes(product.PRODUCTID)
                                      ? producthovered === product.PRODUCTID
                                        ? "darkblue" // Hover effect
                                        : "blue"
                                      : producthovered === product.PRODUCTID
                                        ? "darkgray" // Hover effect
                                        : "gray",
                                    margin: "5px",
                                    color: "white",
                                  }}
                                  onClick={() =>
                                    toggleSelection(
                                      product.PRODUCTID,
                                      selectedProducts,
                                      setSelectedProducts
                                    )
                                  }
                                  onMouseEnter={() => setProductHovered(product.PRODUCTID)}
                                  onMouseLeave={() => setProductHovered(null)}
                                >
                                  {product.PRODUCTNAME}
                                </Button>

                                {/* Product TextField for Remarks */}
                                {selectedProducts.includes(product.PRODUCTID) && (
                                  <TextField
                                  multiline
                                  rows={2}
                                  variant="outlined"
                                  fullWidth
                                  placeholder={`Enter comments for ${product.PRODUCTNAME}`}
                                    value={productNotes[product.PRODUCTID] || ""}
                                    onChange={(e) =>
                                      setProductNotes((prev) => ({
                                        ...prev,
                                        [product.PRODUCTID]: e.target.value,
                                      }))
                                    }
                                    InputProps={{
                                      sx: {
                                        color: "white", // Text color inside the input
                                      },
                                    }}
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: "#ffffff",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: "#ffffff",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: "#ffffff",
                                        },
                                        backgroundColor:
                                          "transparent",
                                      },
                                    }}
                                  />
                                )}
                              </div>
                            ))}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>

                        </Grid>
                      )}

                      {/* Model Selection */}
                      {selectedBrands.length > 0 &&
                        Object.keys(groupedModels).length > 0 && (
                          <Grid
                            container
                            alignItems={"center"}
                            justifyContent={"center"}
                            style={{ marginTop: "20px" }}
                          >
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-fdd41c margin_left10"
                                  >
                                    Models
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                  {Object.entries(groupedModels).map(
                                    ([groupLabel, groupData]) => (
                                      <div
                                        key={groupLabel}
                                        style={{ marginBottom: "20px" }}
                                      >
                                        {/* Shared Label */}
                                        <Typography
                                          variant="body2"
                                          style={{
                                            fontWeight: "bold",
                                            marginBottom: "10px",
                                            color: "white",
                                          }}
                                        >
                                          Brand: {groupData.associatedBrand} |
                                          Product: {groupData.associatedProduct}
                                        </Typography>

                                        {/* Buttons for Models in the Group */}
                                        {groupData.models.map((model) => (
                                          <div
                                            key={model.MODELID}
                                            style={{ marginBottom: "15px" }}
                                          >
                                            <Button
                                              style={{
                                                backgroundColor:
                                                  selectedModels.includes(
                                                    model.MODELID
                                                  )
                                                    ? modelhovered ===
                                                      model.MODELID
                                                      ? "darkgreen" // Hover effect
                                                      : "green"
                                                    : modelhovered ===
                                                      model.MODELID
                                                      ? "darkgray" // Hover effect
                                                      : "gray",
                                                margin: "5px",
                                                color: "white",
                                              }}
                                              onClick={() =>
                                                toggleSelection(
                                                  model.MODELID,
                                                  selectedModels,
                                                  setSelectedModels
                                                )
                                              }
                                              onMouseEnter={() =>
                                                setModelHovered(model.MODELID)
                                              }
                                              onMouseLeave={() =>
                                                setModelHovered(null)
                                              }
                                            >
                                              {model.MODELNO}
                                            </Button>

                                            {/* TextField for Selected Model */}
                                            {selectedModels.includes(
                                              model.MODELID
                                            ) && (
                                                <TextField
                                                  multiline
                                                  rows={2}
                                                  variant="outlined"
                                                  fullWidth
                                                  placeholder={`Enter notes for ${model.MODELNO}`}
                                                  value={
                                                    modelNotes[model.MODELID] ||
                                                    ""
                                                  }
                                                  onChange={(e) =>
                                                    handleNoteChange(
                                                      model.MODELID,
                                                      e.target.value
                                                    )
                                                  }
                                                  InputProps={{
                                                    sx: {
                                                      color: "white", // Text color inside the input
                                                    },
                                                  }}
                                                  sx={{
                                                    "& .MuiOutlinedInput-root": {
                                                      "& fieldset": {
                                                        borderColor: "#ffffff",
                                                      },
                                                      "&:hover fieldset": {
                                                        borderColor: "#ffffff",
                                                      },
                                                      "&.Mui-focused fieldset": {
                                                        borderColor: "#ffffff",
                                                      },
                                                      backgroundColor:
                                                        "transparent",
                                                    },
                                                  }}
                                                />
                                              )}
                                          </div>
                                        ))}
                                      </div>
                                    )
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      <Grid
                        container
                        alignItems={"center"}
                        justifyContent={"center"}
                        style={{ marginTop: "20px" }}
                      >
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                          <Grid container>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              <Typography
                                align="left"
                                className="InterMedium font_size18 color-fdd41c margin_left10"
                              >
                                Customer Remarks
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              <FormControl
                                fullWidth
                                style={{ marginTop: isMobile ? "15px" : "" }}
                              >
                                {/* <InputLabel style={{ color: "#ecf0f1" }}>
                                                                    Select Language
                                                                </InputLabel> */}
                                {/* <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    label="select language"
                                                                    value={customerLanguage}
                                                                    onChange={(e) => setCustomerLanguage(e.target.value)}
                                                                    sx={{
                                                                        color: 'white', // Selected text color

                                                                    }}

                                                                >
                                                                    {languages.map((lang) => (
                                                                        <MenuItem key={lang.code} value={lang.code}>
                                                                            {lang.label}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select> */}
                                <TextField
                                  multiline
                                  rows={2}
                                  value={customerText}
                                  onChange={(e) =>
                                    setCustomerText(e.target.value)
                                  } // Allow manual editing of the text
                                  // placeholder="Your combined translated text will appear here"
                                  className="margin_top20"
                                  InputProps={{
                                    sx: {
                                      color: "white", // Text color inside the input
                                    },
                                  }}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: "#ffffff",
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "#ffffff",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#ffffff",
                                      },
                                      backgroundColor: "transparent",
                                    },
                                  }}
                                />

                                {/* Record/Stop Button */}
                                {/* <Button
                                                                    onClick={() =>
                                                                        isCustomerRecording
                                                                            ? stopRecording("Customer")
                                                                            : startRecording(leadLanguage, "Customer")
                                                                    }
                                                                    style={{
                                                                        backgroundColor: isCustomerRecording ? "red" : "green",
                                                                        color: "white",
                                                                        margin: "10px 0",
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    {isCustomerRecording ? "Stop Recording" : "Start Recording"}
                                                                </Button> */}
                                {customerAudioFiles.length > 0 &&
                                  customerAudioFiles.map((file) => (
                                    <div>
                                      <Typography
                                        variant="h6"
                                        style={{ color: "white" }}
                                      >
                                        Customer Audio Files
                                      </Typography>
                                      <ul
                                        style={{
                                          listStyleType: "none",
                                          padding: 0,
                                        }}
                                      >
                                        <li key={file.id}>
                                          <audio
                                            controls
                                            src={file.url}
                                          ></audio>
                                          <Typography
                                            variant="body2"
                                            style={{ color: "white" }}
                                          >
                                            {file.translatedText}
                                          </Typography>
                                          <Button
                                            style={{
                                              backgroundColor: "green",
                                              color: "white",
                                              margin: "10px 0",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              deleteAudioFile(
                                                file.id,
                                                "Customer"
                                              )
                                            }
                                            color="secondary"
                                          >
                                            Delete
                                          </Button>
                                        </li>
                                      </ul>
                                    </div>
                                  ))}
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems={"center"}
                        justifyContent={"center"}
                        style={{ marginTop: "20px" }}
                      >
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                          <Grid container>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              <Typography
                                align="left"
                                className="InterMedium font_size18 color-fdd41c margin_left10"
                              >
                                Lead Remarks
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              <FormControl
                                fullWidth
                                style={{ marginTop: isMobile ? "15px" : "" }}
                              >
                                {/* <InputLabel style={{ color: "#ecf0f1" }}>
                                                                    Select Language
                                                                </InputLabel> */}
                                {/* <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    label="select language"
                                                                    value={leadLanguage}
                                                                    onChange={(e) => setLeadLanguage(e.target.value)}
                                                                    sx={{
                                                                        color: 'white', // Selected text color

                                                                    }}
                                                                >
                                                                    {languages.map((lang) => (
                                                                        <MenuItem key={lang.code} value={lang.code}>
                                                                            {lang.label}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select> */}
                                <TextField
                                  multiline
                                  rows={2}
                                  value={leadText}
                                  onChange={(e) => setLeadText(e.target.value)} // Allow manual editing of the text
                                  // placeholder="Your combined translated text will appear here"
                                  className="margin_top20"
                                  InputProps={{
                                    sx: {
                                      color: "white", // Text color inside the input
                                    },
                                  }}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: "#ffffff",
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "#ffffff",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#ffffff",
                                      },
                                      backgroundColor: "transparent",
                                    },
                                  }}
                                />

                                {/* Record/Stop Button */}
                                {/* <Button
                                                                    onClick={() =>
                                                                        isLeadRecording
                                                                            ? stopRecording("Lead")
                                                                            : startRecording(leadLanguage, "Lead")
                                                                    }
                                                                    style={{
                                                                        backgroundColor: isLeadRecording ? "red" : "green",
                                                                        color: "white",
                                                                        margin: "10px 0",
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    {isLeadRecording ? "Stop Recording" : "Start Recording"}
                                                                </Button> */}
                                {leadAudioFiles.map((file) => (
                                  <div>
                                    <Typography
                                      variant="h6"
                                      style={{ color: "white" }}
                                    >
                                      Lead Audio Files
                                    </Typography>
                                    <ul
                                      style={{
                                        listStyleType: "none",
                                        padding: 0,
                                      }}
                                    >
                                      <li key={file.id}>
                                        <audio controls src={file.url}></audio>
                                        <Typography
                                          variant="body2"
                                          style={{ color: "white" }}
                                        >
                                          {file.translatedText}
                                        </Typography>
                                        <Button
                                          style={{
                                            backgroundColor: "green",
                                            color: "white",
                                            margin: "10px 0",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            deleteAudioFile(file.id, "Lead")
                                          }
                                        >
                                          Delete
                                        </Button>
                                      </li>
                                    </ul>
                                  </div>
                                ))}
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems={"center"}
                        justifyContent={"center"}
                        style={{ marginTop: "20px" }}
                      >
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                          <Grid container>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              <Typography
                                align="left"
                                className="InterMedium font_size18 color-fdd41c margin_left10"
                              >
                                Next Discussion Date
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  // label="Select Date & Time"
                                  value={selectedDateTime}
                                  minDate={dayjs()}
                                  onChange={handleDateChange}
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      InputProps: {
                                        sx: {
                                          border: "1px solid #ffffff",
                                          backgroundColor: "transparent",
                                          color: "#ffffff", // Change text color here
                                        },
                                      },
                                    },
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  style={{
                                    marginTop: isMobile ? "15px" : "",
                                  }}
                                />
                              </LocalizationProvider>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems={"center"}
                        justifyContent={"center"}
                        style={{ marginTop: "20px" }}
                      >
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                          <Grid container>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              <Typography
                                align="left"
                                className="InterMedium font_size18 color-fdd41c margin_left10"
                              >
                                Enquiry Applicable To
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                              <FormControl
                                fullWidth
                                style={{ marginTop: isMobile ? "15px" : "" }}
                              >
                                <Autocomplete
                                  options={membersList.filter(
                                    (member) =>
                                      !selectedMember.some(
                                        (selected) => selected.ID === member.ID
                                      )
                                  )}
                                  getOptionLabel={(option) =>
                                    option.CONTACTNAME || ""
                                  }
                                  value={applicableTo}
                                  onChange={(event, newValue) => {
                                    setApplicableTo(
                                      newValue ? newValue.CONTACTNAME : ""
                                    );
                                    if (newValue) {
                                      var demoArr = [...selectedMember];
                                      demoArr.push({
                                        key: demoArr.length,
                                        label: newValue.CONTACTNAME,
                                        ID: newValue.ID,
                                      });
                                      setSelectedMember(demoArr);
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      placeholder="Enter user name"
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          "& fieldset": {
                                            borderColor: "#c2d7e8",
                                          },
                                          "&:hover fieldset": {
                                            borderColor: "#3576ba",
                                          },
                                          "&.Mui-focused fieldset": {
                                            borderColor: "#254e71",
                                          },
                                          backgroundColor: "#f0f0f0",
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>

                              {selectedMember.length !== 0 && (
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <Paper
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      flexWrap: "wrap",
                                      listStyle: "none",
                                      p: 0.5,
                                      ml: "11px",
                                    }}
                                    component="ul"
                                  >
                                    {selectedMember.map((data) => {
                                      let icon;

                                      return (
                                        <ListItem
                                          key={data.key}
                                          style={{ width: "auto" }}
                                        >
                                          <Chip
                                            icon={icon}
                                            label={data.label}
                                            onDelete={handleSelectedMemberDelete(
                                              data
                                            )}
                                          />
                                        </ListItem>
                                      );
                                    })}
                                  </Paper>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems={"center"}
                        justifyContent={"center"}
                        style={{ marginTop: "20px" }}
                      >
                        <div>
                          <Button
                            onClick={() =>
                              handleCreateEnquiry(membersList[selectedTab])
                            }
                            style={{
                              backgroundColor: "#054b4b",
                              color: "white",
                              margin: "10px 0",
                              cursor: "pointer",
                            }}
                          >
                            Create Enquiry
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </>
            ))}

            <Dialog open={!!selectedImage} onClose={handleDialogClose}>
              <DialogTitle>
                Image preview
                <IconButton
                  style={{ position: "absolute", top: 8, right: 8 }}
                  onClick={handleDialogClose}
                >
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <img
                  src={selectedImage}
                  alt="Selected"
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "400px",
                    objectFit: "contain",
                    borderRadius: "8px",
                  }}
                />
              </DialogContent>
              {/* <DialogActions>
                            <Button
                                onClick={() => handleRemoveImage(selectedImage)}
                                color="secondary"
                                variant="contained"
                                style={{
                                    backgroundColor: "#054b4b",
                                    color: "white",
                                    margin: "10px 0",
                                    cursor: "pointer",
                                }}
                                startIcon={<Delete />}
                            >
                                Delete
                            </Button>
                        </DialogActions> */}
            </Dialog>
          </Box>
        </div>
      )}
      <Backdrop className="useStylesBackDrop" open={loadingCommon}>
        <CustomSpinner />
      </Backdrop>
      <Backdrop className="useStylesBackDrop" open={afterLoadingCommon}>
        <CustomSpinner />
      </Backdrop>
    </div>
  );
};

export default EnquiryCreation;
