import React from "react";
import { styled } from "@mui/material/styles";

const Container = styled("div")({
  display: "flex",
  alignItems: "center",
});

const Border = styled("div")({
  borderBottom: "2px solid lightgray",
  width: "100%",
});

const Content = styled("span")(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: "6px",
  paddingRight: theme.spacing(2),
  fontWeight: 500,
  fontSize: 18,
  color: "black",
}));

const DividerWithText = ({ children }) => {
  return (
    <Container>
      <Content>{children}</Content>
      <Border />
    </Container>
  );
};

export default DividerWithText;
