import {
  Backdrop,
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import DividerWithText from "./Components/DividerWithText";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CircleIcon from "@mui/icons-material/Circle";
import ImageWithBorders from "./Components/ImageWithBorders";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import axios from "axios";  // For sending API requests
import {TextField } from "@mui/material"; // Material UI Components
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr"; 
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

import config from "../Common/config";
import CustomSpinner from "../Common/CustomSpinner";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import EditEnquiryCardMember from "./Components/EditEnquiryCardMember";
import AddBusinessCard from "./Components/AddBusinessCard";
import RDLCPreview from "./Components/RDLCPreview";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    borderRadius: "20px", // This changes the border radius of the dialog paper
  },
}));

function EnquiryFromCardDetails() {
  var EmpID = localStorage.getItem("EMPID");
  const location = useLocation();
  const history = useNavigate();
  const [IDCardIndex, setIDCardIndex] = useState("");
  const [IDCardStatus, setIDCardStatus] = useState("");
  const [FirstIDCardIndex, setFirstIDCardIndex] = useState(0);
  const [previousCompanyName, setPreviousCompanyName] = useState(0);
  const [currentCompanyName, setCurrentCompanyName] = useState(0);
  const [loadingCommon, setLoadingCommon] = useState(true);
  const [afterLoadingCommon, setAfterLoadingCommon] = useState(false);
  const [preview, setPreview] = useState(false);
  const [enquiryCardDet, setEnquiryCardDet] = useState([]);
  const [message, setMessage] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [enquiryCardEditDet, setEnquiryCardEditDet] = useState({});
  const [enquiryCardEditDialog, setEnquiryCardEditDialog] = useState(false);
  const [addBusinessCardDialog, setAddBusinessCardDialog] = useState(false);
  const [previewDet, setPreviewDet] = useState("");
  const [alreadyPickedDialog, setAlreadyPickedDialog] = useState(false);
  const [printPreview, setPrintPreview] = useState(false);
  const [connection, setConnection] = useState(null);
  const [enquiryCards, setEnquiryCards] = useState([]);
  const [membersCardNo, setMembersCardNo] = useState("");
  const [membersCardHoldStatus, setMembersCardHoldStatus] = useState(null);

  var LoadDetStatus = "";
  if (location.state.TabValue === 1) {
    LoadDetStatus = "1,2";
  } else if (location.state.TabValue === 2) {
    LoadDetStatus = "3";
  } else if (location.state.TabValue === 3) {
    LoadDetStatus = "5";
  }

  

  React.useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-in-out", // Easing function
      once: true, // Whether animation should happen only once or every time you scroll
      offset: 100, // Trigger animation when 200px from the element
    });
  }, []);

  const handleIDCardState = (param) => {
    AfterEnquiryCardDetailsLoad(
      location.state.params.ENQUIRYID,
      param.ENQUIRYCARDMEMBERSID,
      LoadDetStatus
    );
    setIDCardIndex(param.ENQUIRYCARDMEMBERSID);
    setIDCardStatus(param.STATUS);
    setPreviousCompanyName(currentCompanyName);
    setCurrentCompanyName(param.COMPANYNAME);
  };
  const startConnection = async (newConnection) => {
    try {
      await newConnection.start();
      setConnection(newConnection);
      console.log("SignalR connection established.");
    } catch (err) {
      console.error("SignalR connection failed: ", err);
    }
  };
  useEffect(() => {
    
    // Initialize SignalR connection
    const newConnection = new HubConnectionBuilder()
      .withUrl(config.apiLink + "notificationHub")  // Assuming the backend API is hosted at this URL
      .configureLogging(LogLevel.Information)
      .build();

    newConnection.onclose(() => {
      console.log("SignalR connection closed. Reconnecting...");
      startConnection(newConnection);  // Retry connection
    });

    startConnection(newConnection);
    
  
    newConnection.on("ReceiveNotification", (receivedMessage) => {
      setNotifications((prev) => [...prev, receivedMessage]);
      showAlertNotification(receivedMessage);
    });
   
  
    // Function to show an alert notification
    const showAlertNotification = (message) => {
      alert(`New Notification: ${message}`);
    };
    // Cleanup the connection on component unmount
    return () => {
      if (newConnection) {
        newConnection.stop();
      }
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top-left corner
  }, []);

  const EnquiryCardDetailsLoad = useCallback(
    (ID, EnquiryCardMemeberIDParam, LoadDetStatusParam) => {
      debugger;
      var CardDetailsLoadAPIName = "";
      if (location.state.TabValue === 2) {
        CardDetailsLoadAPIName = "Alucast/EnquiryReprintCardDetailsLoad";
      } else {
        CardDetailsLoadAPIName = "Alucast/EnquiryCardDetailsLoad";
      }
      axios
        .post(config.apiLink + CardDetailsLoadAPIName, {
          EnquiryCardID: ID,
          EnquiryCardMemberID: EnquiryCardMemeberIDParam,
          Status: LoadDetStatusParam,
        })
        .then(
          (response) => {
            debugger;
            var Temp = response.data;
            setEnquiryCardDet(Temp);
            if (Temp.length !== 0) {
              setIDCardIndex(Temp[0]["OriginalData"].ENQUIRYCARDMEMBERSID);
              setIDCardStatus(Temp[0]["OriginalData"].STATUS);
              setFirstIDCardIndex(Temp[0]["OriginalData"].ENQUIRYCARDMEMBERSID);
              setPreviousCompanyName(Temp[0]["OriginalData"].COMPANYNAME);
              setCurrentCompanyName(Temp[0]["OriginalData"].COMPANYNAME);
            }
            setLoadingCommon(false);
          },
          (error) => {
            console.log(error);
          }
        );
    },
    []
  );

  const EnquiryCardsLoad = useCallback(
    (ID) => {
      axios
        .post(config.apiLink + "Alucast/EnquiryCardsLoad", {
          EnquiryCardID: ID,
          Status: LoadDetStatus,
        })
        .then(
          (response) => {
            var Temp = response.data;
            setEnquiryCards(Temp["EnquiryCards"]);
            EnquiryCardDetailsLoad(
              ID,
              Temp["EnquiryCards"][0].ENQUIRYCARDMEMBERSID,
              LoadDetStatus
            );
          },
          (error) => {
            console.log(error);
          }
        );
    },
    [LoadDetStatus, EnquiryCardDetailsLoad]
  );

  useEffect(() => {
    EnquiryCardsLoad(location.state.params.ENQUIRYID);
  }, [history, EnquiryCardsLoad, location.state]);

  const AfterEnquiryCardDetailsLoad = (
    ID,
    EnquiryCardMemeberIDParam,
    LoadDetStatusParam
  ) => {
    var CardDetailsLoadAPIName = "";
    if (location.state.TabValue === 2) {
      CardDetailsLoadAPIName = "Alucast/EnquiryReprintCardDetailsLoad";
    } else {
      CardDetailsLoadAPIName = "Alucast/EnquiryCardDetailsLoad";
    }
    setAfterLoadingCommon(true);
    axios
      .post(config.apiLink + CardDetailsLoadAPIName, {
        EnquiryCardID: ID,
        EnquiryCardMemberID: EnquiryCardMemeberIDParam,
        Status: LoadDetStatusParam,
      })
      .then(
        (response) => {
          var Temp = response.data;
          setEnquiryCardDet(Temp);
          if (Temp.length !== 0) {
            setIDCardIndex(Temp[0]["OriginalData"].ENQUIRYCARDMEMBERSID);
            setIDCardStatus(Temp[0]["OriginalData"].STATUS);
            setFirstIDCardIndex(Temp[0]["OriginalData"].ENQUIRYCARDMEMBERSID);
            setPreviousCompanyName(Temp[0]["OriginalData"].COMPANYNAME);
            setCurrentCompanyName(Temp[0]["OriginalData"].COMPANYNAME);
          }
          setAfterLoadingCommon(false);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const EnquiryCardProcessedByUpdate = (ID) => {
    axios
      .post(config.apiLink + "Alucast/EnquiryCardProcessedByUpdate", {
        EnquiryCardID: ID,
        EmpID: EmpID,
      })
      .then(
        (response) => {
          var Temp = response.data;
          if (Temp === "S") {
            history("/EnquiryFromCardList");
          } else if (Temp === "F") {
            setAlreadyPickedDialog(true);
            setAfterLoadingCommon(false);
          }
        },
        (error) => {
          console.log(error);
          setAfterLoadingCommon(false);
        }
      );
  };

  const handlePickIt = (ID) => {
    setAfterLoadingCommon(true);
    EnquiryCardProcessedByUpdate(ID);
  };

  const VisitingCart = (param) => {
    setAfterLoadingCommon(true);
    // https://api7.tvenka.com/api/Byte/VisitingCart?MembersCardNo=M0001
    axios
      .post(
        `https://spareidentification-rdlc.tvenka.com/api/Byte/VisitingCartName?MembersShipCardId=${param}`
      )
      .then(
        (response) => {
          var Temp = response.data;
          setPreviewDet(Temp);
          setPreview(true);
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 100);
          setAfterLoadingCommon(false);
        },
        (error) => {
          console.log(error);
          setAfterLoadingCommon(false);
        }
      );
  };

  // const MemberURLQRcodeGenerate = (Data) => {
  //   setAfterLoadingCommon(false);
  //   axios
  //     .post(config.apiLink + "Alucast/MemberURLQRcodeGenerate", {
  //       MemberCardsNo: Data.MEMBERSCARDNO,
  //       URL: Data.URL,
  //     })
  //     .then(
  //       (response) => {
  //         var Temp = response.data;
  //         if(Temp === "Success"){
  //           history("/EnquiryFromCardList");
  //         }
  //         setAfterLoadingCommon(false);
  //       },
  //       (error) => {
  //         console.log(error);
  //       }
  //     );
  // };

  // const handleMembershipCardQRcodeGenerate = (data) => {
  //     MemberURLQRcodeGenerate(data);
  // };

  const handleMembershipCardPreview = (data) => {
    if (preview === false) {
      VisitingCart(data.MEMBERSHIPCARDID);
      setMembersCardNo(data.MEMBERSCARDNO);
      setMembersCardHoldStatus(data.HOLD);
    } else {
      setPreview(false);
    }
  };

  const handleMembershipCardDetailsEdit = (data) => {
    setEnquiryCardEditDet(data);
    setEnquiryCardEditDialog(true);
  };

  const handleEnquiryCardEditDialogClose = () => {
    setEnquiryCardEditDialog(false);
    setAddBusinessCardDialog(false);
    setTimeout(() => {
      setEnquiryCardEditDet({});
    }, 1000);
  };

  const handleEnquiryCardEditResponse = () => {
    setEnquiryCardEditDialog(false);
    setAddBusinessCardDialog(false);
    AfterEnquiryCardDetailsLoad(
      location.state.params.ENQUIRYID,
      IDCardIndex,
      LoadDetStatus
    );
    setTimeout(() => {
      setEnquiryCardEditDet({});
    }, 1000);
  };

  const handleExtract = (data) => {
    setAfterLoadingCommon(true);
    EnquiryCardDataExtract(
      data.FRONTSIDE,
      data.ENQUIRYCARDMEDIAID,
      data.MEDIALINKID
    );
  };

  const handleAddManually = (data) => {
    setEnquiryCardEditDet(data);
    setEnquiryCardEditDialog(true);
  };

  const ExtractedDataSave = (Data, MediaLinkIDParam) => {
    axios
      .post(config.apiLink + "Alucast/ExtractedDataSave", {
        Name: Data.ContactName,
        // CompanyName:Data.CompanyName,
        CompanyName: "",
        Email: Data.Email,
        Address: Data.Address,
        Designation: Data.JobTitle,
        Mobilenumber: Data.WorkPhone,
        Website: Data.Website,
        ImageId: MediaLinkIDParam,
      })
      .then(
        (response) => {
          var Temp = response.data;
          if (Temp === "Success") {
            AfterEnquiryCardDetailsLoad(
              location.state.params.ENQUIRYID,
              IDCardIndex,
              LoadDetStatus
            );
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const EnquiryCardDataExtract = (
    FilenameParam,
    CardMediaIDParam,
    MediaLinkIDParam
  ) => {
    axios
      .post(config.apiLink + "Alucast/EnquiryCardDataExtract", {
        FileName: FilenameParam,
        Id: CardMediaIDParam,
      })
      .then(
        (response) => {
          var Temp = response.data;
          ExtractedDataSave(Temp["ExtractedData"][0], MediaLinkIDParam);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleAddBusinessCard = () => {
    setAddBusinessCardDialog(true);
  };

  const SplitAsNewEnquirySave = () => {
    axios
      .post(config.apiLink + "Alucast/SplitAsNewEnquirySave", {
        EnquiryCardID: location.state.params.ENQUIRYID,
        EnquiryCardMemebersID: IDCardIndex,
        EmpID: EmpID,
      })
      .then(
        (response) => {
          var Temp = response.data;
          if (Temp === "Success") {
            AfterEnquiryCardDetailsLoad(
              location.state.params.ENQUIRYID,
              IDCardIndex,
              LoadDetStatus
            );
          }
        },
        (error) => {
          console.log(error);
          setAfterLoadingCommon(false);
        }
      );
  };

  const handleSplitAsNewEnquiry = () => {
    setAfterLoadingCommon(true);
    SplitAsNewEnquirySave();
  };

  const handleCreateEnquiry = () => {
    window.open(`${config.enquiryLink}EnquiryCreation?id=${IDCardIndex}`);
  };

  const EnquiryCardMemberSendForPrintSave = () => {
    axios
      .post(config.apiLink + "Alucast/EnquiryCardMemberSendForPrintSave", {
        EnquiryCardMemebersID: IDCardIndex,
        EmpID: EmpID,
      })
      .then(
        (response) => {
          var Temp = response.data;
          if (Temp === "Success") {
            history("/EnquiryFromCardList");
          }
        },
        (error) => {
          console.log(error);
          setAfterLoadingCommon(false);
        }
      );
  };

  const handleSendForPrint = () => {
    if (IDCardStatus === "2") {
      // var message="new data avaiable for print"
      // try {
      //    axios.post( config.apiLink+"Login/sendNotification", { message });
      //    // Clear the input after sending the message
      // } catch (error) {
      //   console.error("Error sending notification:", error);
      // }
      setAfterLoadingCommon(true);
      EnquiryCardMemberSendForPrintSave();
    } else if (IDCardStatus === "3") {
      setPrintPreview(true);
      // Call the function with the binary data
      printPreviewFile(previewDet);
    }
  };

  const EnquiryCardPrintUpdate = (ID) => {
    axios
      .post(config.apiLink + "Alucast/EnquiryCardPrintUpdate", {
        EnquiryCardMembersID: ID,
        EmpID: EmpID,
      })
      .then(
        (response) => {
          var Temp = response.data;
          if (Temp === "Success") {
            // history("/EnquiryFromCardList");
          }
        },
        (error) => {
          console.log(error);
          setAfterLoadingCommon(false);
        }
      );
  };

  function printPreviewFile(base64Data) {
    if (!base64Data) {
      console.error("PreviewFileURL is missing or invalid");
      return;
    }

    // Decode Base64 string and create a Blob
    const byteCharacters = atob(base64Data);
    const byteNumbers = Array.from(byteCharacters).map((char) =>
      char.charCodeAt(0)
    );
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    // Create an object URL
    const blobUrl = URL.createObjectURL(blob);

    // Open the Blob URL in a new tab
    const newTab = window.open(blobUrl, "_blank");

    // Let the user print manually if new tab opens
    if (newTab) {
      EnquiryCardPrintUpdate(IDCardIndex);
      newTab.onload = () => URL.revokeObjectURL(blobUrl);
    } else {
      console.error("Failed to open new tab. Ensure pop-ups are allowed.");
    }
  }

  const handleAlreadyPickedDialogClose = () => {
    setAlreadyPickedDialog(false);
  };

  const handlePrintPreviewDialogClose = () => {
    history("/EnquiryFromCardList");
  };

  return (
    <div className="body_margin_Enquiry">
      {!loadingCommon && (
        <>
          <Card
            // data-aos="fade-up"
            sx={{
              padding: "32px 24px 24px",
              borderRadius: "10px",
              border: "1px solid #d4e6e6",
              boxShadow: 0,
              mt: 1,
              mb: 2,
            }}
          >
            <Grid container alignItems={"center"}>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Lot No
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {location.state.params.LOTNO}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Lead By
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {location.state.params.LEADBYNAME}
                  </Typography>
                </div>
              </Grid>
              {/* <Grid item xs={2} sm={2} md={2} lg={2}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Taken By
                  </Typography>
                  {location.state.params.TAKENBY !== null && (
                    <Typography
                      align="left"
                      className="InterMedium font_size18 color-161616"
                    >
                      {location.state.params.TAKENBY}
                    </Typography>
                  )}
                  {location.state.params.TAKENBY === null && (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        pl: 2,
                        pr: 2,
                        textTransform: "capitalize",
                        bgcolor: "#fab005",
                        border: "1px solid #df9d05",
                        animation: "blink 1s infinite", // Add animation to the button
                        "&:hover": {
                          bgcolor: "#e6a504", // Slightly darker shade
                          opacity: 0.8, // Decrease opacity
                          animation: "none", // Disable blink on hover for better UX
                        },
                        "@keyframes blink": {
                          "0%": { opacity: 1 },
                          "50%": { opacity: 0.5 },
                          "100%": { opacity: 1 },
                        },
                      }}
                      onClick={() =>
                        handlePickIt(location.state.params.ENQUIRYID)
                      }
                    >
                      Pick It
                    </Button>
                  )}
                </div>
              </Grid> */}
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Enquiry Time
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {moment(location.state.params.CREATEDTIME).format(
                      "DD MMM hh:mm a"
                    )}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Cards/Validated/Printed
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {location.state.params.TOTALCARDS}&nbsp;/&nbsp;
                    {location.state.params.VALIDATEDCARDS}&nbsp;/&nbsp;
                    {location.state.params.PRINTEDCARDS}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 1 }}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Member Names
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {location.state.params.MEMBERNAMES}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 1 }}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Member Company Names
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {location.state.params.MEMBERCOMPANYNAMES}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Card>
          <DividerWithText>Business&nbsp;Card&nbsp;Details</DividerWithText>
          <Grid container sx={{ mt: 2 }}>
            {enquiryCardDet.length !== 0 && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="flexEndCenterHeight">
                  <div className="flexCenterCenterHeight margin_right10">
                    <CircleIcon
                      sx={{
                        mr: 0.5,
                        color: "#4CAF50",
                        border: "1px solid #636363",
                        borderRadius: "50%", // Ensures the border is perfectly round
                        boxSizing: "content-box", // Ensures the border doesn't add extra space
                      }}
                    />
                    <Typography
                      align="left"
                      className="InterRegular font_size16 color-636363"
                    >
                      Printed
                    </Typography>
                  </div>
                  <div className="flexCenterCenterHeight margin_right10">
                    <CircleIcon
                      sx={{
                        mr: 0.5,
                        color: "#FFEB3B",
                        border: "1px solid #636363",
                        borderRadius: "50%", // Ensures the border is perfectly round
                        boxSizing: "content-box", // Ensures the border doesn't add extra space
                      }}
                    />
                    <Typography
                      align="left"
                      className="InterRegular font_size16 color-636363"
                    >
                      Validated
                    </Typography>
                  </div>
                  <div className="flexCenterCenterHeight">
                    <CircleIcon
                      sx={{
                        mr: 0.5,
                        color: "#F44336",
                        border: "1px solid #636363",
                        borderRadius: "50%", // Ensures the border is perfectly round
                        boxSizing: "content-box", // Ensures the border doesn't add extra space
                      }}
                    />
                    <Typography
                      align="left"
                      className="InterRegular font_size16 color-636363"
                    >
                      Pending
                    </Typography>
                  </div>
                </div>
              </Grid>
            )}
            {enquiryCards.map((elem, ind) => (
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <Box
                  data-aos="fade-down"
                  sx={{ m: 2, cursor: "pointer" }}
                  onClick={() => handleIDCardState(elem)}
                >
                  <Card
                    className="flexCenterCenterHeight"
                    sx={{
                      borderTop: "1px solid #d4e6e6",
                      borderLeft: "1px solid #d4e6e6",
                      borderRight: "1px solid #d4e6e6",
                      borderRadius: "10px 10px 0px 0px",
                      boxShadow: 0,
                      p: 1,
                      bgcolor:
                        Number(IDCardIndex) ===
                        Number(elem.ENQUIRYCARDMEMBERSID)
                          ? "#d4e6e6"
                          : "#ffffff",
                    }}
                  >
                    <AccountBoxOutlinedIcon sx={{ fontSize: "100px" }} />
                  </Card>
                  <Card
                    className="flexCenterCenterHeight"
                    sx={{
                      borderBottom: "1px solid #d4e6e6",
                      borderLeft: "1px solid #d4e6e6",
                      borderRight: "1px solid #d4e6e6",
                      borderRadius: "0px 0px 10px 10px",
                      boxShadow: 0,
                      bgcolor:
                        elem.STATUS === 3
                          ? "#FFEB3B"
                          : elem.STATUS === 1 || elem.STATUS === 2
                          ? "#F44336"
                          : "#4CAF50",
                    }}
                  >
                    <Typography
                      align="left"
                      className="InterBold font_size18 color-616161"
                      sx={{ p: 1 }}
                    >
                      Card {ind + 1}
                    </Typography>
                  </Card>
                </Box>
              </Grid>
            ))}
            {/* <Grid item xs={12} sm={12} md={2} lg={2}>
              <Box
                sx={{ m: 2, cursor: "pointer" }}
                onClick={() => handleIDCardState(1)}
              >
                <Card
                  className="flexCenterCenterHeight"
                  sx={{
                    borderTop: "1px solid #d4e6e6",
                    borderLeft: "1px solid #d4e6e6",
                    borderRight: "1px solid #d4e6e6",
                    borderRadius: "10px 10px 0px 0px",
                    boxShadow: 0,
                    p: 1,
                    bgcolor: IDCardIndex === 1 ? "#d4e6e6" : "#ffffff",
                  }}
                >
                  <AccountBoxOutlinedIcon sx={{ fontSize: "100px" }} />
                </Card>
                <Card
                  className="flexCenterCenterHeight"
                  sx={{
                    borderBottom: "1px solid #d4e6e6",
                    borderLeft: "1px solid #d4e6e6",
                    borderRight: "1px solid #d4e6e6",
                    borderRadius: "0px 0px 10px 10px",
                    boxShadow: 0,
                    bgcolor: "#FFEB3B",
                  }}
                >
                  <Typography
                    align="left"
                    className="InterBold font_size18 color-616161"
                    sx={{ p: 1 }}
                  >
                    Card 2
                  </Typography>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <Box
                sx={{ m: 2, cursor: "pointer" }}
                onClick={() => handleIDCardState(2)}
              >
                <Card
                  className="flexCenterCenterHeight"
                  sx={{
                    borderTop: "1px solid #d4e6e6",
                    borderLeft: "1px solid #d4e6e6",
                    borderRight: "1px solid #d4e6e6",
                    borderRadius: "10px 10px 0px 0px",
                    boxShadow: 0,
                    p: 1,
                    bgcolor: IDCardIndex === 2 ? "#d4e6e6" : "#ffffff",
                  }}
                >
                  <AccountBoxOutlinedIcon sx={{ fontSize: "100px" }} />
                </Card>
                <Card
                  className="flexCenterCenterHeight"
                  sx={{
                    borderBottom: "1px solid #d4e6e6",
                    borderLeft: "1px solid #d4e6e6",
                    borderRight: "1px solid #d4e6e6",
                    borderRadius: "0px 0px 10px 10px",
                    boxShadow: 0,
                    bgcolor: "#F44336",
                  }}
                >
                  <Typography
                    align="left"
                    className="InterBold font_size18 color-616161"
                    sx={{ p: 1 }}
                  >
                    Card 3
                  </Typography>
                </Card>
              </Box>
            </Grid> */}
            {(IDCardStatus === "1" ||
              IDCardStatus === "2" ||
              enquiryCardDet.length === 0) && (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <div className="flexCenterCenterHeight">
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      pl: 2,
                      pr: 2,
                      textTransform: "capitalize",
                      bgcolor: "#fab005",
                      border: "1px solid #df9d05",
                      "&:hover": {
                        bgcolor: "#e6a504", // Slightly darker shade
                        opacity: 0.8, // Decrease opacity
                      },
                    }}
                    onClick={handleAddBusinessCard}
                  >
                    <PersonAddAltOutlinedIcon className="color-161616" />
                    <Typography
                      align="left"
                      className="InterMedium font_size18 color-161616 margin_left10"
                    >
                      Add Business Card
                    </Typography>
                  </Button>
                </div>
              </Grid>
            )}
          </Grid>
          {IDCardStatus !== "5" && (
            <Box sx={{ ml: 2, mr: 2, mt: 3 }}>
              <Grid container justifyContent={"center"}>
                {enquiryCardDet
                  .filter(
                    (item) =>
                      item["OriginalData"].ENQUIRYCARDMEMBERSID === IDCardIndex
                  ) // Filter items with the target ID
                  .map((item) => (
                    <>
                      {item.FrontSideImage !== null && (
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Typography
                            align="left"
                            className="InterBold font_size18 color-616161"
                            sx={{ mb: 2 }}
                          >
                            Front Side
                          </Typography>
                          <div data-aos="flip-down">
                            <ImageWithBorders FileURL={item.FrontSideImage} />
                          </div>
                        </Grid>
                      )}
                    </>
                  ))}
                {enquiryCardDet
                  .filter(
                    (item) =>
                      item["OriginalData"].ENQUIRYCARDMEMBERSID === IDCardIndex
                  ) // Filter items with the target ID
                  .map((item) => (
                    <>
                      {item.BackSideImage !== null && (
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Typography
                            align="left"
                            className="InterBold font_size18 color-616161"
                            sx={{ mb: 2 }}
                          >
                            Back Side
                          </Typography>
                          <div data-aos="flip-down">
                            <ImageWithBorders FileURL={item.BackSideImage} />
                          </div>
                        </Grid>
                      )}
                    </>
                  ))}
                {/* {IDCardStatus === "5" && (
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <div className="flexCenterCenterHeight">
                      <Button
                        variant="contained"
                        size="large"
                        sx={{
                          pl: 2,
                          pr: 2,
                          textTransform: "capitalize",
                          bgcolor: "#fab005",
                          border: "1px solid #df9d05",
                          "&:hover": {
                            bgcolor: "#e6a504", // Slightly darker shade
                            opacity: 0.8, // Decrease opacity
                          },
                        }}
                        onClick={handleCreateEnquiry}
                      >
                        <NoteAddIcon className="color-161616" />
                        <Typography
                          align="left"
                          className="InterMedium font_size18 color-161616 margin_left10"
                        >
                          Create Enquiry
                        </Typography>
                      </Button>
                    </div>
                  </Grid>
                )} */}
                {enquiryCardDet.length > 1 &&
                  FirstIDCardIndex !== IDCardIndex &&
                  currentCompanyName !== previousCompanyName &&
                  (IDCardStatus === "1" || IDCardStatus === "2") && (
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <div className="flexCenterCenterHeight">
                        <Button
                          variant="contained"
                          size="large"
                          sx={{
                            pl: 2,
                            pr: 2,
                            textTransform: "capitalize",
                            bgcolor: "#fab005",
                            border: "1px solid #df9d05",
                            "&:hover": {
                              bgcolor: "#e6a504", // Slightly darker shade
                              opacity: 0.8, // Decrease opacity
                            },
                          }}
                          onClick={handleSplitAsNewEnquiry}
                        >
                          <SplitscreenIcon className="color-161616" />
                          <Typography
                            align="left"
                            className="InterMedium font_size18 color-161616 margin_left10"
                          >
                            Split As New Enquiry
                          </Typography>
                        </Button>
                      </div>
                    </Grid>
                  )}
              </Grid>
            </Box>
          )}
          <Box sx={{ ml: 2, mr: 2, mt: IDCardStatus !== "5" ? 5 : 0 }}>
            <Grid container justifyContent={"center"}>
              {enquiryCardDet
                .filter(
                  (item) =>
                    item["OriginalData"].ENQUIRYCARDMEMBERSID === IDCardIndex
                ) // Filter items with the target ID
                .map((item) => (
                  <>
                    {item["OriginalData"].STATUS === "1" && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div className="flexEndCenterHeight">
                          <Button
                            variant="contained"
                            size="small"
                            sx={{
                              pl: 2,
                              pr: 2,
                              mr: 1,
                              textTransform: "capitalize",
                              bgcolor: "#fab005",
                              border: "1px solid #df9d05",
                              "&:hover": {
                                bgcolor: "#e6a504", // Slightly darker shade
                                opacity: 0.8, // Decrease opacity
                              },
                            }}
                            onClick={() => handleExtract(item["OriginalData"])}
                          >
                            <LoginOutlinedIcon sx={{ mr: 0.5 }} /> Extract
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            sx={{
                              pl: 2,
                              pr: 2,
                              mr: 1,
                              textTransform: "capitalize",
                              bgcolor: "#fab005",
                              border: "1px solid #df9d05",
                              "&:hover": {
                                bgcolor: "#e6a504", // Slightly darker shade
                                opacity: 0.8, // Decrease opacity
                              },
                            }}
                            onClick={() => handleAddManually(item)}
                          >
                            <LoginOutlinedIcon sx={{ mr: 0.5 }} /> Add Manually
                          </Button>
                        </div>
                      </Grid>
                    )}
                  </>
                ))}
              {enquiryCardDet
                .filter(
                  (item) =>
                    item["OriginalData"].ENQUIRYCARDMEMBERSID === IDCardIndex
                ) // Filter items with the target ID
                .map((item) => (
                  <>
                    {(item["OriginalData"].STATUS === "2" ||
                      item["OriginalData"].STATUS === "3" ||
                      item["OriginalData"].STATUS === "5") && (
                      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                        <Card
                          sx={{
                            padding: "8px 16px",
                            bgcolor: "#fefefe",
                            border: "2px solid #005251",
                            borderRadius: "10px",
                            boxShadow:
                              "0px 1px 8px rgba(0, 255, 255, 0.6)" /* Adjust the color to match */,
                          }}
                          className="flexCenterCenterHeight"
                        >
                          <Grid container>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                              <div className="gridStart">
                                <Typography
                                  align="left"
                                  className="InterRegular font_size16 color-636363"
                                  gutterBottom
                                >
                                  Name
                                </Typography>
                                <Typography
                                  align="left"
                                  className="InterMedium font_size18 color-161616"
                                >
                                  {location.state.TabValue === 2
                                    ? item["OriginalData"].NAME
                                    : item["OriginalData"].CONTACTNAME}
                                </Typography>
                              </div>
                            </Grid>
                            {Number(IDCardStatus) === 3 && (
                              <Grid item xs={12} sm={12} md={2} lg={2}>
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Memebership Card No
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {item["OriginalData"].MEMBERSCARDNO}
                                  </Typography>
                                </div>
                              </Grid>
                            )}
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                              <div className="gridStart">
                                <Typography
                                  align="left"
                                  className="InterRegular font_size16 color-636363"
                                  gutterBottom
                                >
                                  Designation
                                </Typography>
                                <Typography
                                  align="left"
                                  className="InterMedium font_size18 color-161616"
                                >
                                  {item["OriginalData"].DESIGNATION}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                              <div className="gridStart">
                                <Typography
                                  align="left"
                                  className="InterRegular font_size16 color-636363"
                                  gutterBottom
                                >
                                  Company
                                </Typography>
                                <Typography
                                  align="left"
                                  className="InterMedium font_size18 color-161616"
                                >
                                  {item["OriginalData"].COMPANYNAME}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={2} lg={2}>
                              <div className="gridStart">
                                <Typography
                                  align="left"
                                  className="InterRegular font_size16 color-636363"
                                  gutterBottom
                                >
                                  Mobile No
                                </Typography>
                                <Typography
                                  align="left"
                                  className="InterMedium font_size18 color-161616"
                                >
                                  {item["OriginalData"].MOBILENO}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={3}
                              lg={3}
                              sx={{ mt: 2 }}
                            >
                              <div className="gridStart">
                                <Typography
                                  align="left"
                                  className="InterRegular font_size16 color-636363"
                                  gutterBottom
                                >
                                  Email
                                </Typography>
                                <Typography
                                  align="left"
                                  className="InterMedium font_size18 color-161616"
                                >
                                  {item["OriginalData"].EMAIL}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={3}
                              lg={3}
                              sx={{ mt: 2 }}
                            >
                              <div className="gridStart">
                                <Typography
                                  align="left"
                                  className="InterRegular font_size16 color-636363"
                                  gutterBottom
                                >
                                  Website
                                </Typography>
                                <Typography
                                  align="left"
                                  className="InterMedium font_size18 color-161616"
                                >
                                  {item["OriginalData"].WEBSITE}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              lg={6}
                              sx={{ mt: 2 }}
                            >
                              <div className="gridStart">
                                <Typography
                                  align="left"
                                  className="InterRegular font_size16 color-636363"
                                  gutterBottom
                                >
                                  Address
                                </Typography>
                                <Typography
                                  align="left"
                                  className="InterMedium font_size18 color-161616"
                                >
                                  {item["OriginalData"].ADDRESS}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={Number(IDCardStatus) === 3 ? 12 : 3}
                              lg={Number(IDCardStatus) === 3 ? 12 : 3}
                              sx={{ mt: 2 }}
                            >
                              <div
                                className={
                                  Number(IDCardStatus) === 3
                                    ? "flexEndCenterHeight"
                                    : "flexStartCenterHeight"
                                }
                              >
                                {(item["OriginalData"].STATUS === "2" ||
                                  item["OriginalData"].STATUS === "3") && (
                                  <Button
                                    variant="contained"
                                    size="small"
                                    sx={{
                                      pl: 2,
                                      pr: 2,
                                      mr: 1,
                                      textTransform: "capitalize",
                                      bgcolor: "#fab005",
                                      border: "1px solid #df9d05",
                                      "&:hover": {
                                        bgcolor: "#e6a504", // Slightly darker shade
                                        opacity: 0.8, // Decrease opacity
                                      },
                                    }}
                                    onClick={() =>
                                      handleMembershipCardDetailsEdit(item)
                                    }
                                  >
                                    <EditNoteOutlinedIcon sx={{ mr: 0.5 }} />{" "}
                                    Edit
                                  </Button>
                                )}
                                {item["OriginalData"].STATUS === "3" && (
                                  <Button
                                    variant="contained"
                                    size="small"
                                    sx={{
                                      pl: 2,
                                      pr: 2,
                                      ml: 1,
                                      textTransform: "capitalize",
                                      bgcolor: "#fab005",
                                      border: "1px solid #df9d05",
                                      "&:hover": {
                                        bgcolor: "#e6a504", // Slightly darker shade
                                        opacity: 0.8, // Decrease opacity
                                      },
                                    }}
                                    onClick={() =>
                                      handleMembershipCardPreview(
                                        item["OriginalData"]
                                      )
                                    }
                                  >
                                    {!preview && (
                                      <>
                                        <VisibilityIcon sx={{ mr: 0.5 }} />{" "}
                                        Preview
                                      </>
                                    )}
                                    {preview && (
                                      <>
                                        <VisibilityOffIcon sx={{ mr: 0.5 }} />{" "}
                                        Hide Preview
                                      </>
                                    )}
                                  </Button>
                                )}
                                {/* {item["OriginalData"].STATUS === "5" && (
                                  <Button
                                    variant="contained"
                                    size="small"
                                    sx={{
                                      pl: 2,
                                      pr: 2,
                                      ml: 1,
                                      textTransform: "capitalize",
                                      bgcolor: "#fab005",
                                      border: "1px solid #df9d05",
                                      "&:hover": {
                                        bgcolor: "#e6a504", // Slightly darker shade
                                        opacity: 0.8, // Decrease opacity
                                      },
                                    }}
                                    onClick={() =>
                                      handleMembershipCardQRcodeGenerate(
                                        item["OriginalData"]
                                      )
                                    }
                                  >
                                    {!preview && (
                                      <>
                                        <VisibilityIcon sx={{ mr: 0.5 }} />{" "}
                                        Generate QRcode
                                      </>
                                    )}
                                    {preview && (
                                      <>
                                        <VisibilityOffIcon sx={{ mr: 0.5 }} />{" "}
                                        Hide Preview
                                      </>
                                    )}
                                  </Button>
                                )} */}
                              </div>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    )}
                  </>
                ))}
            </Grid>
          </Box>
          {preview && (
            <Box sx={{ ml: 2, mr: 2, mt: 6 }} data-aos="zoom-in-down">
              <Grid container justifyContent={"center"}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className="gridCenter">
                    <Typography
                      align="center"
                      className="InterBold font_size18 color-616161"
                      sx={{ mb: 4 }}
                    >
                      Membership Card Preview - (Card No : {membersCardNo})
                    </Typography>
                    <RDLCPreview FileURL={previewDet} />
                    {(membersCardHoldStatus ==="1") ?(
                    <Typography
                      align="center"
                      className="InterBold font_size25"
                      sx={{ mb: 4,color:"red" }}
                    >
                     HOLD - Yes
                    </Typography>
                    ):(
                      <Typography
                      align="center"
                      className="InterBold font_size25 color-616161"
                      sx={{ mb: 4 }}
                    >
                     HOLD - N/A
                    </Typography>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Box>
          )}
          {enquiryCardDet.length !== 0 &&
            (IDCardStatus === "2" ||
              (IDCardStatus === "3" && previewDet !== "")) && (
              <Box sx={{ ml: 2, mr: 2, mt: 6 }}>
                <Grid container justifyContent={"center"}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className="flexCenterCenterHeight">
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          pl: 5,
                          pr: 5,
                          textTransform: "capitalize",
                          bgcolor: "#fab005",
                          border: "1px solid #df9d05",
                          "&:hover": {
                            bgcolor: "#e6a504", // Slightly darker shade
                            opacity: 0.8, // Decrease opacity
                          },
                        }}
                        onClick={handleSendForPrint}
                      >
                        <PrintOutlinedIcon sx={{ mr: 0.5 }} />{" "}
                        {IDCardStatus === "1" || IDCardStatus === "2"
                          ? "Send For Print"
                          : "Print"}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            )}
        </>
      )}
      {enquiryCardEditDialog && (
        <EditEnquiryCardMember
          BusinessCardDetails={enquiryCardEditDet}
          onClose={handleEnquiryCardEditDialogClose}
          onOk={handleEnquiryCardEditResponse}
          PageName={Number(IDCardStatus) === 3 ? "Reprint" : ""}
        />
      )}
      {addBusinessCardDialog && (
        <AddBusinessCard
          EnquiryCardID={location.state.params.ENQUIRYID}
          onClose={handleEnquiryCardEditDialogClose}
          onOk={handleEnquiryCardEditResponse}
        />
      )}

      <BootstrapDialog
        onClose={handleAlreadyPickedDialogClose}
        aria-labelledby="customized-dialog-title"
        open={alreadyPickedDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent style={{ padding: "0px" }}>
          <Card
            sx={{
              border: "2px solid #bee3ff",
              bgcolor: "#f0f8ff",
              padding: "32px 16px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  align="center"
                  className="InterMedium font_size18 color-161616"
                >
                  This enquiry has been picked already.<br></br> Try another
                  unpicked enquiry.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                <div className="flexCenterCenterHeight">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleAlreadyPickedDialogClose}
                    sx={{
                      pl: 5,
                      pr: 5,
                      textTransform: "capitalize",
                      bgcolor: "#fab005",
                      border: "1px solid #df9d05",
                      "&:hover": {
                        bgcolor: "#e6a504", // Slightly darker shade
                        opacity: 0.8, // Decrease opacity
                      },
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Card>
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        onClose={handlePrintPreviewDialogClose}
        aria-labelledby="customized-dialog-title"
        open={printPreview}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent style={{ padding: "0px" }}>
          <Card
            sx={{
              border: "2px solid #bee3ff",
              bgcolor: "#f0f8ff",
              padding: "32px 16px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2, mb: 2 }}>
                {enquiryCardDet
                  .filter(
                    (item) =>
                      item["OriginalData"].ENQUIRYCARDMEMBERSID === IDCardIndex
                  ) // Filter items with the target ID
                  .map((item) => (
                    <>
                      {(item["OriginalData"].STATUS === "2" ||
                        item["OriginalData"].STATUS === "3" ||
                        item["OriginalData"].STATUS === "5") && (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          sx={{ mt: 2 }}
                        >
                          <Card
                            sx={{
                              padding: "8px 16px",
                              bgcolor: "#fefefe",
                              border: "2px solid #005251",
                              borderRadius: "10px",
                              boxShadow:
                                "0px 1px 8px rgba(0, 255, 255, 0.6)" /* Adjust the color to match */,
                            }}
                            className="flexCenterCenterHeight"
                          >
                            <Grid container>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Name
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {location.state.TabValue === 2
                                      ? item["OriginalData"].NAME
                                      : item["OriginalData"].CONTACTNAME}
                                  </Typography>
                                </div>
                              </Grid>
                              {Number(IDCardStatus) === 3 && (
                                <Grid item xs={12} sm={12} md={2} lg={2}>
                                  <div className="gridStart">
                                    <Typography
                                      align="left"
                                      className="InterRegular font_size16 color-636363"
                                      gutterBottom
                                    >
                                      Memebership Card No
                                    </Typography>
                                    <Typography
                                      align="left"
                                      className="InterMedium font_size18 color-161616"
                                    >
                                      {item["OriginalData"].MEMBERSCARDNO}
                                    </Typography>
                                  </div>
                                </Grid>
                              )}
                              <Grid item xs={12} sm={12} md={2} lg={2}>
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Designation
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {item["OriginalData"].DESIGNATION}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={2}>
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Company
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {item["OriginalData"].COMPANYNAME}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={2}>
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Mobile No
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {item["OriginalData"].MOBILENO}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                lg={3}
                                sx={{ mt: 2 }}
                              >
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Email
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {item["OriginalData"].EMAIL}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={4}
                                lg={4}
                                sx={{ mt: 2 }}
                              >
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Website
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {item["OriginalData"].WEBSITE}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={5}
                                lg={5}
                                sx={{ mt: 2 }}
                              >
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Address
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {item["OriginalData"].ADDRESS}
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      )}
                    </>
                  ))}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                <div className="flexCenterCenterHeight">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handlePrintPreviewDialogClose}
                    sx={{
                      pl: 5,
                      pr: 5,
                      textTransform: "capitalize",
                      bgcolor: "#fab005",
                      border: "1px solid #df9d05",
                      "&:hover": {
                        bgcolor: "#e6a504", // Slightly darker shade
                        opacity: 0.8, // Decrease opacity
                      },
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Card>
        </DialogContent>
      </BootstrapDialog>
      <Backdrop className="useStylesBackDrop" open={loadingCommon}>
        <CustomSpinner />
      </Backdrop>
      <Backdrop className="useStylesBackDrop" open={afterLoadingCommon}>
        <CustomSpinner />
      </Backdrop>
    </div>
  );
}

export default EnquiryFromCardDetails;
