import { Card, Typography } from "@mui/material";
import React from "react";

function NoData() {
  return (
    <div>
      <Card className="no_data_card">
        <Typography variant="h6" align="center" color="primary">
          No data found
        </Typography>
      </Card>
    </div>
  );
}

export default NoData;
