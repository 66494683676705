import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import {
  Grid,
  Backdrop,
  Typography,
  Tooltip,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  Button,
  TextField,
  MenuItem,
  Card,
  CardContent,
} from "@mui/material";
import ReactLoading from "react-loading";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import config from "../../Common/config";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Scrollbars } from "react-custom-scrollbars";
import { ConsoleView } from "react-device-detect";
import NoData from "./NoData";

export default function Category() {
  const [ddlMake, setddlMake] = useState("");
  const [ddlMakeList, setddlMakeList] = useState([]);
  const [MandatoryType, setMandatoryType] = useState("");
  const [MandatoryTypeList, setMandatoryTypeList] = useState([]);
  const [ddlProduct, setddlProduct] = useState("");
  const [ddlProductList, setddlProductList] = useState([]);
  const [ddlTypeMaster, setddlTypeMaster] = useState("");
  const [txtOrderList, settxtOrderList] = useState("");
  const [SalMasterList, setSalMasterList] = useState([]);
  const [ddlStatus, setddlStatus] = useState(1);
  const [ddlStatusList, setddlStatusList] = useState([]);
  const [btnSavetext, setbtnSavetext] = useState("Save");
  const [LoadingCommon, setLoadingCommon] = useState(false);
  const [MasterId, setMasterId] = useState("");
  const [txtCategoryNo, settxtCategoryNo] = useState("");
  const [txtCategoryName, settxtCategoryName] = useState("");
  const [ddlTypeList, setddlTypeList] = useState([]);
  const [ddlType, setddlType] = useState("");
  const [ddlfilfterProd, setddlfilfterProd] = useState([]);
  const [CATEGORYID, setCATEGORYID] = useState("");

  const LoadSalesmastertype = () => {
    axios.post(config.apiLink + "Sales/CategoryLoad", {}).then(
      (response) => {
        debugger;
        var Temp = response.data;
        setddlMakeList(Temp["Table5"]);
        setddlfilfterProd(Temp["Table"]);
        setddlStatusList(Temp["Table1"]);
        // Temp["Table2"].forEach(function (item, index) {
        //   Object.assign(item, {
        //     RowNo: "",
        //   });
        // });
        // var newarr = [...Temp["Table2"]];
        // let inx;
        // newarr.forEach(function (item, index) {
        //   if (item.STATUSID === 0) {
        //     inx = 0;
        //   } else {
        //     inx++;
        //   }
        //   item.RowNo = inx;
        // });
        // setSalMasterList(newarr);

        //console.log(newarr);
        setMandatoryTypeList(Temp["Table4"]);
        setLoadingCommon(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const LoadProduct = () => {
    axios
      .post(config.apiLink + "Sales/ProductLoad", {
        BrandId: "2",
      })
      .then((response) => {
        debugger;
        var Temp = response.data;
        setddlProductList(Temp["Table"]);
        setLoadingCommon(false);
      });
  };

  const ddlMandatoryType = (e) => {
    setMandatoryType(e.target.value);
  };

  const ddlchgProduct = (e) => {
    debugger;
    setddlProduct(e.target.value);
    LoadcategoryList(e.target.value);
    const Data = ddlfilfterProd.filter(
      (list) => list.USERID === e.target.value
    );
    setddlTypeList(Data);
  };

  function LoadcategoryList(ProductId) {
    axios
      .post(config.apiLink + "Sales/CategoryProductList", {
        ProductId: ProductId,
      })
      .then((response) => {
        debugger;
        var Temp = response.data;
        Temp["Table"].forEach(function (item, index) {
          Object.assign(item, {
            RowNo: "",
          });
        });
        var newarr = [...Temp["Table"]];
        let inx;
        newarr.forEach(function (item, index) {
          if (item.STATUSID === 0) {
            inx = 0;
          } else {
            inx++;
          }
          item.RowNo = inx;
        });
        setSalMasterList(newarr);
      });
  }

  const ddlchgMake = (e) => {
    setddlMake(e.target.value);
  };

  function clearData() {
    setbtnSavetext("Save");
    setddlMake("");
    setddlProduct("");
    setddlTypeMaster("");
    settxtOrderList("");
    setMasterId("");
    settxtCategoryName("");
    settxtCategoryNo("");
    setddlType("");
    setMandatoryType("");
  }

  const btnclkcancel = () => {
    clearData();
  };
  const ddlchgStatus = (e) => {
    setddlStatus(e.target.value);
  };

  const btnclkEdit = (obj, index) => {
    debugger;
    setCATEGORYID(obj.CATEGORYID);
    setddlTypeList(ddlfilfterProd);
    setbtnSavetext("Update");
    setddlMake(obj.BRANDID);
    setddlProduct(obj.PRODID);
    setddlTypeMaster(obj.MASTERTYPEID);
    settxtOrderList(index + 1);
    setMasterId(obj.MASTERID);
    setddlStatus(1);
    settxtCategoryName(obj.CATEGORY);
    settxtCategoryNo(obj.CATEGORYNO);
    setddlType(obj.MASTERID);
    setMandatoryType(obj.MANDATORYTYPEID);
    window.scrollTo(0, 0);
  };

  const btnclickSave = () => {
    if (btnSavetext === "Save") {
      setLoadingCommon(true);
      axios
        .post(config.apiLink + "Sales/CategorySave", {
          MASTERID: ddlType,
          CATEGORY: txtCategoryName,
          CATEGORYNO: txtCategoryNo,
          ORDERLIST: txtOrderList,
          MANDATORYTYPEID: MandatoryType,
          CREATEDBY: "1",
          PRODUCTID: ddlProduct,
        })
        .then((response) => {
          debugger;
          var Temp = response.data;
          Temp["Table"].forEach(function (item, index) {
            Object.assign(item, {
              RowNo: "",
            });
          });
          var newarr = [...Temp["Table"]];
          let inx;
          newarr.forEach(function (item, index) {
            if (item.STATUSID === 0) {
              inx = 0;
            } else {
              inx++;
            }
            item.RowNo = inx;
          });
          setSalMasterList(newarr);
          setLoadingCommon(false);
          clearData();
        });
    } else if (btnSavetext === "Update") {
      setLoadingCommon(true);
      axios
        .post(config.apiLink + "Sales/CategoryUpdate", {
          CATEGORYID: CATEGORYID,
          MASTERID: ddlType,
          CATEGORY: txtCategoryName,
          CATEGORYNO: txtCategoryNo,
          ORDERLIST: txtOrderList,
          MANDATORYTYPEID: MandatoryType,
          STATUS: ddlStatus,
          PRODUCTID: ddlProduct,
        })
        .then((response) => {
          debugger;
          var Temp = response.data;
          Temp["Table"].forEach(function (item, index) {
            Object.assign(item, {
              RowNo: "",
            });
          });
          var newarr = [...Temp["Table"]];
          let inx;
          newarr.forEach(function (item, index) {
            if (item.STATUSID === 0) {
              inx = 0;
            } else {
              inx++;
            }
            item.RowNo = inx;
          });
          setSalMasterList(newarr);
          setLoadingCommon(false);
          clearData();
        });
    }
  };

  useEffect(() => {
    debugger;
    sessionStorage.setItem("CurrentPageTitle", "Estimate Type");
    LoadSalesmastertype();
    LoadProduct();
  }, []);

  return (
    <div className="body_margin">
      <div>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="flexStartCenterHeight">
              <Tooltip title="Back">
                <IconButton>
                  <ArrowBackIcon style={{ color: "#013d54" }} />
                </IconButton>
              </Tooltip>
              <Typography align="left" className="List_Screen_Heading">
                Category Master
              </Typography>
            </div>
          </Grid>
        </Grid>
        <hr className="List_Screen_Heading_Divider" />
        <Grid className="margin20_0">
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel id="ddMake">Make</InputLabel>
                    <Select
                      labelId="Make1"
                      id="ddMake"
                      value={ddlMake}
                      label="Make"
                      onChange={(e) => {
                        ddlchgMake(e);
                      }}
                    >
                      {ddlMakeList.map((elem) => (
                        <MenuItem value={elem.BRANDID}>{elem.BRAND}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel id="Product1">Product</InputLabel>
                    <Select
                      labelId="Product2"
                      id="Product3"
                      value={ddlProduct}
                      label="Product"
                      onChange={(e) => {
                        ddlchgProduct(e);
                      }}
                    >
                      {ddlProductList.map((elem) => (
                        <MenuItem value={elem.PRODID}>{elem.PRODNAME}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel id="Type1">Type</InputLabel>
                    <Select
                      labelId="Type2"
                      id="Type3"
                      value={ddlType}
                      label="Type"
                      onChange={(e) => {
                        debugger;
                        setddlType(e.target.value);
                      }}
                    >
                      {ddlTypeList.map((elem) => (
                        <MenuItem value={elem.MASTERID}>{elem.MASTER}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <TextField
                    required
                    id="filled-required"
                    label="Category No"
                    variant="outlined"
                    fullWidth
                    value={txtCategoryNo}
                    onChange={(e) => {
                      settxtCategoryNo(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <TextField
                    required
                    id="filled-required"
                    label="Category Name"
                    variant="outlined"
                    fullWidth
                    value={txtCategoryName}
                    onChange={(e) => {
                      settxtCategoryName(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel id="MandatoryType1">Mandatory Type</InputLabel>
                    <Select
                      labelId="MandatoryType2"
                      id="MandatoryType3"
                      value={MandatoryType}
                      label="Mandatory Type"
                      onChange={(e) => {
                        ddlMandatoryType(e);
                      }}
                    >
                      {MandatoryTypeList.map((elem) => (
                        <MenuItem value={elem.MANDATORYTYPEID}>
                          {elem.MANDATORYTYPE}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <TextField
                    required
                    id="filled-required"
                    label="Order List"
                    variant="outlined"
                    fullWidth
                    value={txtOrderList}
                    onChange={(e) => {
                      settxtOrderList(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <Grid container spacing={1}>
                    {btnSavetext === "Update" && (
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <FormControl fullWidth>
                          <InputLabel id="Status1">Status</InputLabel>
                          <Select
                            labelId="Status2"
                            id="Status3"
                            value={ddlStatus}
                            label="Status"
                            onChange={(e) => {
                              ddlchgStatus(e);
                            }}
                          >
                            {ddlStatusList.map((elem) => (
                              <MenuItem value={elem.STATUSID}>
                                {elem.STATUS}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        className="margin_top10"
                        size="medium"
                        onClick={btnclickSave}
                        fullWidth
                      >
                        {btnSavetext}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Button
                        variant="contained"
                        className="margin_top10"
                        color="primary"
                        size="medium"
                        uppercase={false}
                        onClick={btnclkcancel}
                        fullWidth
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid container className="margin20_0">
          <Scrollbars style={{ height: "100vh" }}>
            <Card style={{ widows: "100%" }}>
              <CardContent>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {SalMasterList.length !== 0 &&
                    SalMasterList.map((elem, index) => (
                      <Card className="list_card" key={index}>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              flex: "2%",
                              backgroundColor:
                                elem.RowNo === 0 ? "green" : "#fcb900",
                            }}
                          >
                            <div className="flexCenterStartHeight">
                              <Typography
                                variant="body1"
                                className="list_index"
                              >
                                {elem.RowNo === 0 ? "" : elem.RowNo}
                              </Typography>
                            </div>
                          </div>
                          <div
                            style={{
                              flex: "100%",
                            }}
                          >
                            {elem.STATUSID === 0 && (
                              <Grid
                                container
                                spacing={2}
                                className="list_main_div"
                              >
                                <Grid item xs={12} sm={12} md={2} lg={12}>
                                  <Grid item xs={12} sm={12} md={2} lg={12}>
                                    <Typography
                                      variant="body1"
                                      gutterBottom
                                      align="left"
                                      className="list_title"
                                    >
                                      Category
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      align="left"
                                      className="list_Value"
                                    >
                                      {elem.CATEGORY}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                            {elem.STATUSID === 1 && (
                              <Grid
                                container
                                spacing={2}
                                className="list_main_div"
                              >
                                <Grid item xs={12} sm={12} md={2} lg={2}>
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    align="left"
                                    className="list_title"
                                  >
                                    Category
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    align="left"
                                    className="list_Value"
                                  >
                                    {elem.CATEGORY}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={3}>
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    align="left"
                                    className="list_title"
                                  >
                                    Category No
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    align="left"
                                    className="list_Value"
                                  >
                                    {elem.CATEGORYNO}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={3}>
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    align="left"
                                    className="list_title"
                                  >
                                    Mandatory Type
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    align="left"
                                    className="list_Value"
                                  >
                                    {elem.MANDATORYTYPE}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={2}>
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    align="left"
                                    className="list_title"
                                  >
                                    Order&nbsp;List
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    align="left"
                                    className="list_Value"
                                  >
                                    {elem.ORDERLIST}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={1}>
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    align="left"
                                    className="list_title"
                                  >
                                    Status
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    align="left"
                                    className="list_Value"
                                  >
                                    {elem.STATUS}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={1}>
                                  <div>
                                    <BorderColorIcon
                                      className="margin_top20"
                                      onClick={() => btnclkEdit(elem, index)}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            )}
                          </div>
                        </div>
                      </Card>
                    ))}
                  {SalMasterList.length === 0 && <NoData />}
                </Grid>
              </CardContent>
            </Card>
          </Scrollbars>
        </Grid>
      </div>
      <Backdrop
        style={{ zIndex: 10000 }}
        className="useStylesBackDrop"
        open={LoadingCommon}
      >
        <ReactLoading type="spokes" color="#013d54" className="react_loading" />
      </Backdrop>
    </div>
  );
}
