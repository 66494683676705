import React from "react";
import { styled } from "@mui/material/styles";

const Container = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center", // Center the content horizontally
  gap: "8px", // Add spacing between text and dividers
});

const Border = styled("div")({
  borderBottom: "2px solid lightgray",
  flex: 1, // Allow the divider to take up equal space
});

const Content = styled("span")(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: "6px",
  fontWeight: 500,
  fontSize: 18,
  color: "#b082ba",
}));

const DividerWithTextForMember = ({ children }) => {
  return (
    <Container>
      <Border />
      <Content>{children}</Content>
      <Border />
    </Container>
  );
};

export default DividerWithTextForMember;
