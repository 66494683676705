import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import {
  Grid,
  Backdrop,
  Typography,
  Tooltip,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  TextField,
  MenuItem,
  Autocomplete,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import ReactLoading from "react-loading";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import config from "../../Common/config";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Scrollbars } from "react-custom-scrollbars";
import { Co2Sharp } from "@mui/icons-material";

export default function Accessory() {
  const location = useLocation();
  const [txtAccessoryNO, setAccessory] = useState("");
  const [txtAccessoryName, settxtAccessoryName] = useState();
  const [txtDescription, settxtDescription] = useState("");
  const [txtNotes, settxtNotes] = useState("");
  const [LoadingCommon, setLoadingCommon] = useState("");
  const [ModelTonList, setModelTonList] = useState([]);
  const [ModelsList, setModelsList] = useState([]);
  const [SltModelsList, setSltModelsList] = useState([]);
  const [TagList, setTagList] = useState([]);
  const [TypeList, setTypeList] = useState([]);
  const [SalesAccessoryList, setSalesAccessoryList] = useState([]);
  const [SalesCategoryList, setSalesCategoryList] = useState([]);
  const [btnSavetext, setbtnSavetext] = useState("Save");

  //AutoComplete
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setloading] = useState(false);
  const [Selecttext, setSelecttext] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");

  const LoadAccessoryData = useCallback(() => {
    setLoadingCommon(true);
    axios.post(config.apiLink + "Sales/AccessoryDataLoad", {}).then(
      (response) => {
        var Temp = response.data;
        Temp["MODELTON"].forEach(function (item, index) {
          Object.assign(item, {
            Status: false,
            PRICE: "",
            TECHNICAL: "",
            Old: "",
          });
        });
        setModelTonList(Temp["MODELTON"]);
        Temp["SALESCATEGORY"].forEach(function (item, index) {
          Object.assign(item, {
            Status: false,
          });
        });
        setSalesCategoryList(Temp["SALESCATEGORY"]);
        Temp["TAG"].forEach(function (item, index) {
          Object.assign(item, {
            Status: false,
          });
        });
        setTagList(Temp["TAG"]);
        setTypeList(Temp["TYPE"]);
        setSalesAccessoryList(Temp["SALESACCESSORY"]);
        setLoadingCommon(false);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    debugger;
    sessionStorage.setItem("CurrentPageTitle", "Estimate Type");
    LoadAccessoryData();
  }, [LoadAccessoryData]);

  const onChangeHandle = async (value) => {
    let response = await fetch(config.apiLink + "Sales/AccessoryNameSearch", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Text: value,
      }),
    });
    if (response.ok) {
      var res = await response.json();
      console.log(res["Table"]);
      setOptions(res["Table"]);
      setloading(false);
    } else {
      console.log("HTTP-Error: " + response.status);
    }
  };

  const handleCheckedArr = (e, index, elem) => {
    var Arr = [];
    const updatedList = [...ModelTonList];
    updatedList[index].Status = e.target.checked;
    setModelTonList(updatedList);
    updatedList.forEach(function (item, index) {
      if (item.Status === true) {
        Arr.push({ GROUPID: item.MODELID });
      }
    });
    let GROUPID = Array.prototype.map.call(Arr, (s) => s.GROUPID).toString();
    if (btnSavetext === "Save") {
      loadModel(GROUPID);
    } else if (btnSavetext === "Update") {
      loadModelselect(GROUPID, SltModelsList);
    }
  };

  const hanChkCategory = (e, index, elem) => {
    const updatedList = [...SalesCategoryList];
    updatedList[index].Status = e.target.checked;
    setSalesCategoryList(updatedList);
  };

  const hanChkTags = (e, index, elem) => {
    const ArrTagList = [...TagList];
    ArrTagList[index].Status = e.target.checked;
    setTagList(ArrTagList);
  };

  const loadModel = (GROUPID) => {
    setLoadingCommon(true);
    var Param = {
      GROUPID: GROUPID,
    };
    axios.post(config.apiLink + "Sales/ModelLoad", Param).then(
      (response) => {
        var Temp = response.data;
        Temp["Table"].forEach(function (item, index) {
          Object.assign(item, {
            Type: "",
          });
        });
        setModelsList(Temp["Table"]);
        setLoadingCommon(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const loadModelselect = (GROUPID, selectobj) => {
    setLoadingCommon(true);
    var Param = {
      GROUPID: GROUPID,
    };
    axios.post(config.apiLink + "Sales/ModelLoad", Param).then(
      (response) => {
        var Temp = response.data;
        var objmodel = [...Temp["Table"]];
        objmodel.forEach(function (item, index) {
          Object.assign(item, {
            Type: "",
          });
        });
        objmodel.forEach(function (item, index) {
          selectobj.forEach(function (obj, index) {
            if (item.MODELID === obj.MODELID) {
              item.Type = obj.TYPEID;
            }
          });
        });
        setModelsList(objmodel);
        setLoadingCommon(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const ddlchgType = (e, index) => {
    debugger;
    const updatedList = [...ModelsList];
    updatedList[index].Type = e.target.value;
    setModelsList(updatedList);
  };

  const txtchgPrice = (e, index) => {
    const updatedList = [...ModelTonList];
    updatedList[index].PRICE = e.target.value;
    setModelTonList(updatedList);
  };

  const txtchgtechnical = (e, index) => {
    const updatedList = [...ModelTonList];
    updatedList[index].TECHNICAL = e.target.value;
    setModelTonList(updatedList);
  };

  const btnclickEdit = (ACCESSORYID) => {
    debugger;
    setLoadingCommon(true);
    setSelecttext(options[0]);
    setbtnSavetext("Update");
    var Param = {
      ACCESSORYID: ACCESSORYID,
    };
    axios.post(config.apiLink + "Sales/AccessoryEditDetLoad", Param).then(
      (response) => {
        debugger;
        var Temp = response.data;
        var Accessory = Temp["Accessory"];
        // var obj = [
        //   {
        //     ACCESSORY: Accessory[0].ACCESSORY,
        //     ACCESSORYID: Accessory[0].ACCESSORYID,
        //   },
        // ];
        // setOptions(obj);
        //setSelecttext(obj);
        setInputValue(Accessory[0].ACCESSORY);
        settxtAccessoryName(Accessory[0].ACCESSORY);
        setAccessory(Accessory[0].ACCESSORYNO);
        settxtDescription(Accessory[0].DESCRIPTION);
        settxtNotes(Accessory[0].NOTES);
        var TagDB = Temp["Tag"];
        var categoryDB = Temp["category"];
        var ModelDB = Temp["Model"];
        var TonDB = Temp["Ton"];
        var TonArr = [...ModelTonList];
        TonArr.forEach(function (item, index) {
          TonDB.forEach(function (tonpbj, index) {
            if (item.MODELID === tonpbj.GROUPID) {
              item.PRICE = tonpbj.PRICE;
              item.TECHNICAL = tonpbj.TECHNICAL;
              item.Status = true;
              item.Old = "Y";
            }
          });
        });
        setModelTonList(TonArr);

        var SalesCateArr = [...SalesCategoryList];
        SalesCateArr.forEach(function (item, index) {
          categoryDB.forEach(function (tonpbj, index) {
            if (item.CATEGORYID === tonpbj.CATEGORYID) {
              item.Status = true;
            }
          });
        });
        setSalesCategoryList(SalesCateArr);

        debugger;
        var TagListArr = [...TagList];
        TagListArr.forEach(function (item, index) {
          TagDB.forEach(function (tonpbj, index) {
            if (item.TAGID === tonpbj.TAGID) {
              item.Status = true;
            }
          });
        });
        setTagList(TagListArr);

        var obj = [];
        TonArr.forEach(function (item, index) {
          ModelDB.forEach(function (tonpbj, index) {
            if (item.MODELID === tonpbj.MODELID) {
              obj.push(item);
            }
          });
        });
        debugger;
        setSltModelsList(ModelDB);
        let GROUPID = Array.prototype.map
          .call(ModelDB, (s) => s.MODELID)
          .toString();
        loadModelselect(GROUPID, ModelDB);
        setLoadingCommon(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const btnclickSave = () => {
    if (btnSavetext === "Save") {
      SaveDetails();
    } else {
      //UpdateDetails();
    }
  };

  function SaveDetails() {
    debugger;
    setLoadingCommon(true);
    var SalesAccessTon = [],
      SalesAccessModel = [],
      SalesAccessCategory = [],
      SalesAccessTag = [];
    ModelTonList.forEach(function (item, index) {
      if (item.Status === true) {
        debugger;
        SalesAccessTon.push({
          GROUPID: item.MODELID,
          PRICE: item.PRICE,
          TECHNICAL: item.TECHNICAL,
        });
      }
    });
    ModelsList.forEach(function (item, index) {
      debugger;
      SalesAccessModel.push({
        GROUPID: item.MODELID,
        TYPEID: item.Type,
      });
    });
    TagList.forEach(function (item, index) {
      if (item.Status === true) {
        SalesAccessTag.push({
          TAGID: item.TAGID,
        });
      }
    });
    SalesCategoryList.forEach(function (item, index) {
      if (item.Status === true) {
        SalesAccessCategory.push({
          CATEGORYID: item.CATEGORYID,
        });
      }
    });
    debugger;
    var Param = {
      AccessoryNo: txtAccessoryNO,
      AccessoryName: inputValue,
      Description: txtDescription,
      Notes: txtNotes,
      SalesAccessTon: SalesAccessTon,
      SalesAccessModel: SalesAccessModel,
      SalesAccessCategory: SalesAccessCategory,
      SalesAccessTag: SalesAccessTag,
    };
    console.log(Param);
    axios.post(config.apiLink + "Sales/AccessorySave", Param).then(
      (response) => {
        var Temp = response.data;
        debugger;
        settxtAccessoryName("");
        setAccessory("");
        settxtDescription("");
        settxtNotes("");
        setInputValue("");
        setModelsList([]);
        LoadAccessoryData();
      },
      (error) => {
        console.log(error);
      }
    );
  }
  function UpdateDetails() {
    setLoadingCommon(true);
    debugger;
    var SalesAccessTon = [],
      SalesAccessModel = [],
      SalesAccessCategory = [],
      SalesAccessTag = [];
    ModelTonList.forEach(function (item, index) {
      if (item.Status === true && item.Old === "") {
        debugger;
        SalesAccessTon.push({
          GROUPID: item.MODELID,
          PRICE: item.PRICE,
          TECHNICAL: item.TECHNICAL,
          UPStatus: "1",
        });
      } else if (item.Old === "Y" && item.Status === true) {
        SalesAccessTon.push({
          GROUPID: item.MODELID,
          PRICE: item.PRICE,
          TECHNICAL: item.TECHNICAL,
          UPStatus: "1",
        });
      } else if (item.Old === "Y" && item.Status === false) {
        SalesAccessTon.push({
          GROUPID: item.MODELID,
          PRICE: item.PRICE,
          TECHNICAL: item.TECHNICAL,
          UPStatus: "4",
        });
      }
    });
    console.log(SalesAccessTon);

    ModelsList.forEach(function (item, index) {
      debugger;
      SalesAccessModel.push({
        GROUPID: item.MODELID,
        TYPEID: item.Type,
      });
    });
    TagList.forEach(function (item, index) {
      if (item.Status === true) {
        SalesAccessTag.push({
          TAGID: item.TAGID,
        });
      }
    });
    SalesCategoryList.forEach(function (item, index) {
      if (item.Status === true) {
        SalesAccessCategory.push({
          CATEGORYID: item.CATEGORYID,
        });
      }
    });
    var Param = {
      AccessoryNo: txtAccessoryNO,
      AccessoryName: txtAccessoryName,
      Description: txtDescription,
      Notes: txtNotes,
      SalesAccessTon: SalesAccessTon,
      SalesAccessModel: SalesAccessModel,
      SalesAccessCategory: SalesAccessCategory,
      SalesAccessTag: SalesAccessTag,
    };
    console.log(Param);
    axios.post(config.apiLink + "Sales/AccessoryUpdate", Param).then(
      (response) => {
        var Temp = response.data;
        debugger;
        settxtAccessoryName("");
        setAccessory("");
        settxtDescription("");
        settxtNotes("");
        setModelsList([]);
        LoadAccessoryData();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  return (
    <div className="body_margin">
      <div>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="flexStartCenterHeight">
              <Tooltip title="Back">
                <IconButton>
                  <ArrowBackIcon style={{ color: "#013d54" }} />
                </IconButton>
              </Tooltip>
              <Typography align="left" className="List_Screen_Heading">
                Accessory Master
              </Typography>
            </div>
          </Grid>
        </Grid>
        <hr className="List_Screen_Heading_Divider" />
        <Grid className="margin20_0">
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={2}>
                  <Grid container spacing={2}>
                    <div className="flexStartCenterHeight">
                      <Typography align="left" className="List_Screen_Heading">
                        Basic
                      </Typography>
                    </div>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        required
                        id="txtType"
                        label="Accessory No"
                        variant="outlined"
                        fullWidth
                        value={txtAccessoryNO}
                        onChange={(e) => {
                          setAccessory(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Autocomplete
                        freeSolo
                        id="asynchronous-demo"
                        value={Selecttext}
                        inputValue={inputValue}
                        open={open}
                        onOpen={() => {
                          setOpen(true);
                        }}
                        onClose={() => {
                          setOpen(false);
                        }}
                        getOptionSelected={(option, value) =>
                          option.ACCESSORY === value.ACCESSORY
                        }
                        getOptionLabel={(option) => option.ACCESSORY}
                        options={options}
                        loading={loading}
                        noOptionsText={"No Accessory Found"}
                        onChange={(event, option, reason) => {
                          debugger;
                          if (reason === "clear") {
                            settxtAccessoryName("");
                            setAccessory("");
                            settxtDescription("");
                            settxtNotes("");
                            setModelsList([]);
                            setInputValue("");
                            LoadAccessoryData();
                          } else {
                            if (option !== null) {
                              var SelectOtion = option;
                              settxtAccessoryName(SelectOtion.ACCESSORY);
                              btnclickEdit(SelectOtion.ACCESSORYID);
                            } else {
                              setloading(false);
                              setOpen(false);
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Accsssory Name"
                            variant="outlined"
                            onChange={(ev) => {
                              // dont fire API if the user delete or not entered anything
                              debugger;
                              if (
                                ev.target.value !== "" ||
                                ev.target.value !== null
                              ) {
                                // onChangeHandle(ev.target.value);
                                if (ev.target.value.length > 1) {
                                  setloading(true);
                                  onChangeHandle(ev.target.value);
                                } else {
                                  setloading(false);
                                  setOpen(false);
                                }
                              }
                              setInputValue(ev.target.value);
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />

                      {/* <TextField
                        required
                        id="txtAccessoryNOName"
                        label="Accessory Name"
                        variant="outlined"
                        fullWidth
                        value={txtAccessoryName}
                        onChange={(e) => {
                          settxtAccessoryName(e.target.value);
                        }}
                      /> */}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        required
                        id="Description"
                        label="Description"
                        variant="outlined"
                        fullWidth
                        value={txtDescription}
                        onChange={(e) => {
                          settxtDescription(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        required
                        id="txtNotes"
                        label="Notes"
                        variant="outlined"
                        fullWidth
                        value={txtNotes}
                        onChange={(e) => {
                          settxtNotes(e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={7}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className="flexStartCenterHeight">
                      <Typography align="left" className="List_Screen_Heading">
                        Tonnage
                      </Typography>
                    </div>
                    <div
                      style={{
                        overflowY: "auto",
                        height: "300px",
                      }}
                    >
                      {ModelTonList.length !== 0 &&
                        ModelTonList.map((elem, index) => (
                          <Card className="list_card" key={index}>
                            <Grid
                              container
                              spacing={2}
                              className="list_main_div"
                            >
                              <Grid item xs={12} sm={12} md={2} lg={2}>
                                <Typography
                                  variant="body1"
                                  gutterBottom
                                  align="left"
                                  className="list_title"
                                >
                                  SNo
                                </Typography>
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className="list_Value"
                                >
                                  {index + 1}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={2}>
                                <Typography
                                  variant="body1"
                                  gutterBottom
                                  align="left"
                                  className="list_title"
                                >
                                  Tonnage
                                </Typography>
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className="list_Value"
                                >
                                  {elem.MODEL}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={2}>
                                <Checkbox
                                  checked={elem.Status}
                                  onClick={(e) =>
                                    handleCheckedArr(e, index, elem)
                                  }
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={3}>
                                <TextField
                                  required
                                  id="txtPRICE"
                                  label="Price"
                                  variant="outlined"
                                  fullWidth
                                  value={elem.PRICE}
                                  onChange={(e) => {
                                    txtchgPrice(e, index);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={3}>
                                <TextField
                                  required
                                  id="txtTechnical"
                                  label="Technical"
                                  variant="outlined"
                                  fullWidth
                                  value={elem.TECHNICAL}
                                  onChange={(e) => {
                                    txtchgtechnical(e, index);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Card>
                        ))}
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className="flexStartCenterHeight">
                      <Typography align="left" className="List_Screen_Heading">
                        Model
                      </Typography>
                    </div>
                    <div
                      style={{
                        overflowY: "auto",
                        height: "300px",
                      }}
                    >
                      {ModelsList.length !== 0 &&
                        ModelsList.map((elem, index) => (
                          <Card className="list_card" key={index}>
                            <Grid
                              container
                              spacing={2}
                              className="list_main_div"
                            >
                              <Grid item xs={12} sm={12} md={2} lg={2}>
                                <Typography
                                  variant="body1"
                                  gutterBottom
                                  align="left"
                                  className="list_title"
                                >
                                  SNo
                                </Typography>
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className="list_Value"
                                >
                                  {index + 1}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={5}>
                                <Typography
                                  variant="body1"
                                  gutterBottom
                                  align="left"
                                  className="list_title"
                                >
                                  Model
                                </Typography>
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className="list_Value"
                                >
                                  {elem.DESCRIPTION}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={5}>
                                <FormControl fullWidth>
                                  <InputLabel id="ddMake">Type</InputLabel>
                                  <Select
                                    labelId="Make1"
                                    id="ddMake"
                                    value={elem.Type}
                                    label="Make"
                                    onChange={(e) => {
                                      ddlchgType(e, index);
                                    }}
                                  >
                                    {TypeList.map((elem) => (
                                      <MenuItem value={elem.TYPEID}>
                                        {elem.TYPE}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                          </Card>
                        ))}
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={2}></Grid>
                <Grid item xs={12} sm={12} md={4} lg={7}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className="flexStartCenterHeight">
                      <Typography align="left" className="List_Screen_Heading">
                        Sales Category List
                      </Typography>
                    </div>
                    <div
                      style={{
                        overflowY: "auto",
                        height: "300px",
                      }}
                    >
                      {SalesCategoryList.length !== 0 &&
                        SalesCategoryList.map((elem, index) => (
                          <Card className="list_card" key={index}>
                            <Grid
                              container
                              spacing={2}
                              className="list_main_div"
                            >
                              <Grid item xs={12} sm={12} md={2} lg={2}>
                                <Typography
                                  variant="body1"
                                  gutterBottom
                                  align="left"
                                  className="list_title"
                                >
                                  SNo
                                </Typography>
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className="list_Value"
                                >
                                  {index + 1}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={8}>
                                <Typography
                                  variant="body1"
                                  gutterBottom
                                  align="left"
                                  className="list_title"
                                >
                                  Category
                                </Typography>
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className="list_Value"
                                >
                                  {elem.CATEGORY}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={2}>
                                <Checkbox
                                  checked={elem.Status}
                                  onClick={(e) =>
                                    hanChkCategory(e, index, elem)
                                  }
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Card>
                        ))}
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className="flexStartCenterHeight">
                      <Typography align="left" className="List_Screen_Heading">
                        Tag
                      </Typography>
                    </div>
                    <div
                      style={{
                        overflowY: "auto",
                        height: "300px",
                      }}
                    >
                      {TagList.length !== 0 &&
                        TagList.map((elem, index) => (
                          <Card className="list_card" key={index}>
                            <Grid
                              container
                              spacing={2}
                              className="list_main_div"
                            >
                              <Grid item xs={12} sm={12} md={2} lg={2}>
                                <Typography
                                  variant="body1"
                                  gutterBottom
                                  align="left"
                                  className="list_title"
                                >
                                  SNo
                                </Typography>
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className="list_Value"
                                >
                                  {index + 1}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={8}>
                                <Typography
                                  variant="body1"
                                  gutterBottom
                                  align="left"
                                  className="list_title"
                                >
                                  Tag
                                </Typography>
                                <Typography
                                  variant="body1"
                                  align="left"
                                  className="list_Value"
                                >
                                  {elem.DESCRIPTION}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={2}>
                                <Checkbox
                                  checked={elem.Status}
                                  onClick={(e) => hanChkTags(e, index, elem)}
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Card>
                        ))}
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={12}>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      className="margin_top10"
                      size="medium"
                      onClick={btnclickSave}
                    >
                      {btnSavetext}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="flexStartCenterHeight">
              <Typography align="left" className="List_Screen_Heading">
                Sales Accessory
              </Typography>
            </div>
            <div
              style={{
                overflowY: "auto",
                height: "300px",
              }}
            >
              {SalesAccessoryList.length !== 0 &&
                SalesAccessoryList.map((elem, index) => (
                  <Card className="list_card" key={index}>
                    <Grid container spacing={2} className="list_main_div">
                      <Grid item xs={12} sm={12} md={2} lg={1}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          align="left"
                          className="list_title"
                        >
                          SNo
                        </Typography>
                        <Typography
                          variant="body1"
                          align="left"
                          className="list_Value"
                        >
                          {index + 1}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          align="left"
                          className="list_title"
                        >
                          Accessory No
                        </Typography>
                        <Typography
                          variant="body1"
                          align="left"
                          className="list_Value"
                        >
                          {elem.ACCESSORYNO}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={3}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          align="left"
                          className="list_title"
                        >
                          Accessory Name
                        </Typography>
                        <Typography
                          variant="body1"
                          align="left"
                          className="list_Value"
                        >
                          {elem.ACCESSORY}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={3}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          align="left"
                          className="list_title"
                        >
                          Description
                        </Typography>
                        <Typography
                          variant="body1"
                          align="left"
                          className="list_Value"
                        >
                          {elem.DESCRIPTION}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={3}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          align="left"
                          className="list_title"
                        >
                          Notes
                        </Typography>
                        <Typography
                          variant="body1"
                          align="left"
                          className="list_Value"
                        >
                          {elem.NOTES}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={3}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          align="left"
                          className="list_title"
                        >
                          Tonnage
                        </Typography>
                        <Typography
                          variant="body1"
                          align="left"
                          className="list_Value"
                        >
                          {elem.TON}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={3}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          align="left"
                          className="list_title"
                        >
                          Created Date
                        </Typography>
                        <Typography
                          variant="body1"
                          align="left"
                          className="list_Value"
                        >
                          {elem.CREATEDDATE}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={3}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          align="left"
                          className="list_title"
                        >
                          Created By
                        </Typography>
                        <Typography
                          variant="body1"
                          align="left"
                          className="list_Value"
                        >
                          {elem.CREATEDBY}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={3}>
                        <Button
                          variant="contained"
                          color="primary"
                          className="margin_top10"
                          size="medium"
                          onClick={() => btnclickEdit(elem.ACCESSORYID)}
                        >
                          Edit
                        </Button>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
            </div>
          </Grid>
        </Grid>
      </div>
      <Backdrop
        style={{ zIndex: 10000 }}
        className="useStylesBackDrop"
        open={LoadingCommon}
      >
        <ReactLoading type="spokes" color="#013d54" className="react_loading" />
      </Backdrop>
    </div>
  );
}
