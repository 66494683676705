import React, { useCallback, useEffect, useState } from "react";
import EnquiryFromCardListTabs from "./Components/EnquiryFromCardListTabs";
import NewEnquiry from "./Components/NewEnquiry";
import axios from "axios";
import config from "../Common/config";
import { Backdrop } from "@mui/material";
import CustomSpinner from "../Common/CustomSpinner";
import "../Common/SaasStyles.scss";
import ToBePrinted from "./Components/ToBePrinted";
import ToBeMapped from "./Components/ToBeMapped";
import ToBeQuoted from "./Components/ToBeQuoted";
import ViewQuote from "./Components/ViewQuote";
import AllEnquiry from "./Components/AllEnquiry";

function EnquiryFromCardList() {
  var EmpID = localStorage.getItem("EMPID");
  var USERID = localStorage.getItem("USERID");
  const [tabValue, setTabValue] = useState(0);
  const [loadingCommon, setLoadingCommon] = useState(true);
  const [enquiryCardListCountDet, setEnquiryCardListCountDet] = useState({});

  const handleTabchange = (param) => {
    setTabValue(param);
  };

  const EnquiryCardListCountLoad = useCallback(() => {
    axios.post(config.apiLink + "Alucast/EnquiryCardListCountLoad", {
      EmpID: EmpID
    }).then(
      (response) => {
        var Temp = response.data;
        setEnquiryCardListCountDet(Temp["EnquiryCardListCount"][0]);
        setLoadingCommon(false);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const MemberURLStatusUpdate = useCallback(() => {
    axios
      .post(config.apiLink + "Alucast/MemberURLStatusUpdate", {
      })
      .then(
        (response) => {
          // var Temp = response.data;
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  const MemberURLQRcodeGenerate = useCallback(() => {
    axios
      .post(config.apiLink + "Alucast/MemberURLQRcodeGenerate", {
      })
      .then(
        (response) => {
          // var Temp = response.data;
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  useEffect(() => {
      EnquiryCardListCountLoad();
      // MemberURLStatusUpdate();
      // MemberURLQRcodeGenerate();
  }, [EnquiryCardListCountLoad]);

  return (
    <div className="body_margin_Enquiry">
      {!loadingCommon && (
        <EnquiryFromCardListTabs
          Tabchange={handleTabchange}
          EnquiryCount={enquiryCardListCountDet}
        />
      )}

      {tabValue === 0 && !loadingCommon && <AllEnquiry TabValue={tabValue} />}
      {tabValue === 1 && !loadingCommon && <NewEnquiry TabValue={tabValue} />}
      {tabValue === 2 && !loadingCommon && <ToBePrinted TabValue={tabValue} />}
      {tabValue === 3 && !loadingCommon && <ToBeMapped TabValue={tabValue} />}
      {tabValue === 4 && !loadingCommon && <ToBeQuoted TabValue={tabValue} />}
      {tabValue === 5 && !loadingCommon && <ViewQuote TabValue={tabValue} />}

      <Backdrop className="useStylesBackDrop" open={loadingCommon}>
        <CustomSpinner />
      </Backdrop>
    </div>
  );
}

export default EnquiryFromCardList;
