import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import {
  Grid,
  Backdrop,
  Typography,
  Tooltip,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  Button,
  TextField,
  MenuItem,
  Card,
  CardContent,
} from "@mui/material";
import ReactLoading from "react-loading";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import config from "../../Common/config";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Scrollbars } from "react-custom-scrollbars";
import NoData from "./NoData";

export default function Type() {
  const location = useLocation();
  const [ddlMake, setddlMake] = useState("");
  const [ddlMakeList, setddlMakeList] = useState([]);
  const [ddlProduct, setddlProduct] = useState("");
  const [ddlProductList, setddlProductList] = useState([]);
  const [txtType, settxtType] = useState("");
  const [ddlDisplayType, setddlDisplayType] = useState("");
  const [ddlDisplayTypeList, setddlDisplayTypeList] = useState([]);
  const [ddlTypeMaster, setddlTypeMaster] = useState("");
  const [ddlTypeMasterList, setddlTypeMasterList] = useState([]);
  const [ddlPercentage, setddlPercentage] = useState("");
  const [ddlPercentageList, setddlPercentageList] = useState([]);
  const [txtOrderList, settxtOrderList] = useState("");
  const [SalMasterList, setSalMasterList] = useState([]);
  const [ddlStatus, setddlStatus] = useState(1);
  const [showddlStatus, setshowddlStatus] = useState(false);
  const [ddlStatusList, setddlStatusList] = useState([]);
  const [btnSavetext, setbtnSavetext] = useState("Save");
  const [LoadingCommon, setLoadingCommon] = useState(false);
  const [MasterId, setMasterId] = useState("");

  const LoadSalesmastertype = () => {
    axios.post(config.apiLink + "Sales/TypeDropdownLoad", {}).then(
      (response) => {
        debugger;
        var Temp = response.data;
        setddlMakeList(Temp["Table3"]);
        setddlStatusList(Temp["Table1"]);
        setddlDisplayTypeList(Temp["Table4"]);
        setddlTypeMasterList(Temp["Table"]);
        setSalMasterList(Temp["Table2"]);
        setddlPercentageList(Temp["Table5"]);
        setLoadingCommon(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const LoadProduct = () => {
    axios
      .post(config.apiLink + "Sales/ProductLoad", {
        BrandId: 2,
      })
      .then((response) => {
        debugger;
        var Temp = response.data;
        setddlProductList(Temp["Table"]);
        setLoadingCommon(false);
      });
  };

  const ddlchgPercentage = (e) => {
    setddlPercentage(e.target.value);
  };

  const ddlchgTypeMaster = (e) => {
    setddlTypeMaster(e.target.value);
  };

  const ddlchgDisType = (e) => {
    setddlDisplayType(e.target.value);
  };

  const ddlchgProduct = (e) => {
    setddlProduct(e.target.value);
    LoadSalMasterList(e.target.value);
  };

  const LoadSalMasterList = (Id) => {
    setLoadingCommon(true);
    axios
      .post(config.apiLink + "Sales/SalesMasterGet", {
        ProductId: Id,
      })
      .then((response) => {
        debugger;
        var Temp = response.data;
        setSalMasterList(Temp["Table"]);
        setLoadingCommon(false);
      });
  };

  const ddlchgMake = (e) => {
    setddlMake(e.target.value);
  };

  function clearData() {
    setbtnSavetext("Save");
    setddlMake("");
    setddlProduct("");
    settxtType("");
    setddlDisplayType("");
    setddlTypeMaster("");
    setddlPercentage("");
    settxtOrderList("");
    setMasterId("");
    setshowddlStatus(false);
  }

  const btnclkcancel = (e) => {
    clearData();
  };
  const ddlchgStatus = (e) => {
    setddlStatus(e.target.value);
  };

  const btnclkEdit = (obj, index) => {
    debugger;
    window.scrollTo(0, 0);
    setbtnSavetext("Update");
    setddlMake(obj.BRANDID);
    setddlProduct(obj.PRODID);
    settxtType(obj.MASTER);
    setddlDisplayType(obj.DISPLAYTYPEID);
    setddlTypeMaster(obj.MASTERTYPEID);
    setddlPercentage(obj.PERCENTAGE);
    settxtOrderList(obj.OLM);
    setMasterId(obj.MASTERID);
    setddlStatus(1);
  };

  const btnclickSave = () => {
    if (btnSavetext === "Save") {
      setLoadingCommon(true);
      axios
        .post(config.apiLink + "Sales/SalesMasterSave", {
          MASTER: txtType,
          MASTERTYPEID: ddlTypeMaster,
          DISPLAYTYPEID: ddlDisplayType,
          PRODID: ddlProduct,
          ORDERLIST: txtOrderList,
          PERCENTAGE: ddlPercentage,
        })
        .then((response) => {
          debugger;
          var Temp = response.data;
          setSalMasterList(Temp["Table"]);
          setLoadingCommon(false);
          clearData();
        });
    } else if (btnSavetext === "Update") {
      setLoadingCommon(true);
      axios
        .post(config.apiLink + "Sales/SalesMasterUpdate", {
          MASTERID: MasterId,
          MASTER: txtType,
          MASTERTYPEID: ddlTypeMaster,
          DISPLAYTYPEID: ddlDisplayType,
          PRODID: ddlProduct,
          ORDERLIST: txtOrderList,
          PERCENTAGE: ddlPercentage,
          STATUS: ddlStatus,
        })
        .then((response) => {
          debugger;
          var Temp = response.data;
          setSalMasterList(Temp["Table"]);
          setLoadingCommon(false);
          clearData();
        });
    }
  };

  useEffect(() => {
    debugger;
    sessionStorage.setItem("CurrentPageTitle", "Estimate Type");
    LoadSalesmastertype();
    LoadProduct();
  }, []);

  return (
    <div className="body_margin">
      <div>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="flexStartCenterHeight">
              <Tooltip title="Back">
                <IconButton>
                  <ArrowBackIcon style={{ color: "#013d54" }} />
                </IconButton>
              </Tooltip>
              <Typography align="left" className="List_Screen_Heading">
                Type Master
              </Typography>
            </div>
          </Grid>
        </Grid>
        <hr className="List_Screen_Heading_Divider" />
        <Grid className="margin20_0">
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel id="ddMake">Make</InputLabel>
                    <Select
                      labelId="Make1"
                      id="ddMake"
                      value={ddlMake}
                      label="Make"
                      onChange={(e) => {
                        ddlchgMake(e);
                      }}
                    >
                      {ddlMakeList.map((elem) => (
                        <MenuItem value={elem.BRANDID}>{elem.BRAND}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel id="Product1">Product</InputLabel>
                    <Select
                      labelId="Product2"
                      id="Product3"
                      value={ddlProduct}
                      label="Product"
                      onChange={(e) => {
                        ddlchgProduct(e);
                      }}
                    >
                      {ddlProductList.map((elem) => (
                        <MenuItem value={elem.PRODID}>{elem.PRODNAME}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <TextField
                    required
                    id="txtType"
                    label="Type"
                    variant="outlined"
                    fullWidth
                    value={txtType}
                    onChange={(e) => {
                      settxtType(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel id="ddlDisplayType1">Display Type</InputLabel>
                    <Select
                      labelId="ddlDisplayType2"
                      id="ddlDisplayType3"
                      value={ddlDisplayType}
                      label="Display Type"
                      onChange={(e) => {
                        ddlchgDisType(e);
                      }}
                    >
                      {ddlDisplayTypeList.map((elem) => (
                        <MenuItem value={elem.DISPLAYTYPEID}>
                          {elem.DISPLAYTYPE}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel id="ddlTypeMaster1">Type Master</InputLabel>
                    <Select
                      labelId="ddlTypeMaster2"
                      id="ddlTypeMaster3"
                      value={ddlTypeMaster}
                      label="Display Type"
                      onChange={(e) => {
                        ddlchgTypeMaster(e);
                      }}
                    >
                      {ddlTypeMasterList.map((elem) => (
                        <MenuItem value={elem.MASTERTYPEID}>
                          {elem.MASTERTYPE}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel id="ddlPercentage1">Percentage</InputLabel>
                    <Select
                      labelId="ddlPercentage2"
                      id="ddlPercentage3"
                      value={ddlPercentage}
                      label="Display Type"
                      onChange={(e) => {
                        ddlchgPercentage(e);
                      }}
                    >
                      {ddlPercentageList.map((elem) => (
                        <MenuItem value={elem.VALUE}>{elem.VALUE}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <TextField
                    required
                    id="filled-required"
                    label="Order List"
                    variant="outlined"
                    fullWidth
                    value={txtOrderList}
                    onChange={(e) => {
                      settxtOrderList(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                  <Grid container spacing={1}>
                    {btnSavetext === "Update" && (
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <FormControl fullWidth>
                          <InputLabel id="Status1">Status</InputLabel>
                          <Select
                            labelId="Status2"
                            id="Status3"
                            value={ddlStatus}
                            label="Status"
                            onChange={(e) => {
                              ddlchgStatus(e);
                            }}
                          >
                            {ddlStatusList.map((elem) => (
                              <MenuItem value={elem.STATUSID}>
                                {elem.STATUS}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        className="margin_top10"
                        size="medium"
                        onClick={btnclickSave}
                        fullWidth
                      >
                        {btnSavetext}
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <Button
                        variant="contained"
                        className="margin_top10"
                        color="primary"
                        size="medium"
                        onClick={btnclkcancel}
                        fullWidth
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid container className="margin20_0">
          <Scrollbars style={{ height: "100vh" }}>
            <Card style={{ widows: "100%" }}>
              <CardContent>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {SalMasterList.length !== 0 &&
                    SalMasterList.map((elem, index) => (
                      <Card className="list_card" key={index}>
                        {elem.TYPEID === "1" && (
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{ flex: "2%", backgroundColor: "#976e00" }}
                            >
                              <div className="flexCenterStartHeight"></div>
                            </div>
                            <div
                              style={{
                                flex: "100%",
                              }}
                            >
                              <Grid
                                container
                                spacing={2}
                                className="list_main_div"
                              >
                                <Grid item xs={12} sm={12} md={2} lg={12}>
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    align="left"
                                    className="list_title"
                                  >
                                    Master
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    align="left"
                                    className="list_Value"
                                  >
                                    {elem.MASTER}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        )}
                        {elem.TYPEID === "2" && (
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                flex: "2%",
                                backgroundColor: "#b08100 ",
                              }}
                            >
                              <div className="flexCenterStartHeight"></div>
                            </div>
                            <div
                              style={{
                                flex: "100%",
                              }}
                            >
                              <Grid
                                container
                                spacing={2}
                                className="list_main_div"
                              >
                                <Grid item xs={12} sm={12} md={2} lg={6}>
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    align="left"
                                    className="list_title"
                                  >
                                    Master
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    align="left"
                                    className="list_Value"
                                  >
                                    {elem.MASTER}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={6}>
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    align="left"
                                    className="list_title"
                                  >
                                    Master Type
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    align="left"
                                    className="list_Value"
                                  >
                                    {elem.MASTERTYPE}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        )}
                        {elem.TYPEID === "3" && (
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{ flex: "2%", backgroundColor: "#fcb900" }}
                            >
                              <div className="flexCenterStartHeight">
                                <Typography
                                  variant="body1"
                                  className="list_index"
                                >
                                  {index - 1}
                                </Typography>
                              </div>
                            </div>
                            <div
                              style={{
                                flex: "100%",
                              }}
                            >
                              <Grid
                                container
                                spacing={2}
                                className="list_main_div"
                              >
                                <Grid item xs={12} sm={12} md={2} lg={6}>
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    align="left"
                                    className="list_title"
                                  >
                                    Type
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    align="left"
                                    className="list_Value"
                                  >
                                    {elem.MASTER}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={1}>
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    align="left"
                                    className="list_title"
                                  >
                                    Type&nbsp;Master
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    align="left"
                                    className="list_Value"
                                  >
                                    {elem.MASTERTYPE}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={1}>
                                  {elem.STATUS !== "0" && (
                                    <>
                                      <Typography
                                        variant="body1"
                                        gutterBottom
                                        align="left"
                                        className="list_title"
                                      >
                                        Percentage
                                      </Typography>
                                      <Typography
                                        variant="body1"
                                        align="left"
                                        className="list_Value"
                                      >
                                        {elem.PERCENTAGE}
                                      </Typography>
                                    </>
                                  )}
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={1}>
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    align="left"
                                    className="list_title"
                                  >
                                    Display&nbsp;Type
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    align="left"
                                    className="list_Value"
                                  >
                                    {elem.DISPLAYTYPE}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={1}>
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    align="left"
                                    className="list_title"
                                  >
                                    Order&nbsp;List
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    align="left"
                                    className="list_Value"
                                  >
                                    {elem.OLM}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={1}>
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    align="left"
                                    className="list_title"
                                  >
                                    Status
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    align="left"
                                    className="list_Value"
                                  >
                                    {elem.STATUS}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={2} lg={1}>
                                  <BorderColorIcon
                                    className="margin_top20"
                                    onClick={(e) => btnclkEdit(elem, index)}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        )}
                      </Card>
                    ))}
                  {SalMasterList.length === 0 && <NoData />}
                </Grid>
              </CardContent>
            </Card>
          </Scrollbars>
        </Grid>
      </div>
      <Backdrop
        style={{ zIndex: 10000 }}
        className="useStylesBackDrop"
        open={LoadingCommon}
      >
        <ReactLoading type="spokes" color="#013d54" className="react_loading" />
      </Backdrop>
    </div>
  );
}
