import {
  Card,
  CardContent,
  Typography,
  Avatar,
  responsiveFontSizes,
} from "@mui/material";
import Grid from "@mui/material/Grid";

import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { styled } from "@mui/material/styles";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import axios from "axios";
import config from '../../Common/config'

import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import AddNewContact from "../../Components/Mailer/AddNewContact";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const AlertSelectTagBox = (props) => {

  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [region, setregion] = useState([]);
  const [openin, setopenin] = useState(false);
  const [StateGet, setStateGet] = useState([]);
  const [prevDeletedcus, setPrevDeletedcus] = useState([]);
  const [CusGet, setCusGet] = useState([]);
  const [Tagname, setTagname] = useState("");
  const [TAGID, setTAGID] = useState("");
  const [cusName, setcusName] = useState([]);
  //   const [CusFirstname, setCusFirstname] = useState("");
  const [Cusid, setCusid] = useState([]);
  const [CusStateid, setCusStateid] = useState([]);
  // const [emails, setEmails] = useState([]);
  const [CustomerDeatils, setCustomerDetails] = useState([]);
  const [Firstname, setFirstname] = useState([]);
  // const [moblie, setmoblie] = useState([]);
  // const [dept, setdept] = useState([]);
  // const [desgn, setdesgn] = useState([]);
  const [SelectedFirstNames, setSelectedFirstNames] = useState([]);
  const [addopen, setaddOpen] = useState(false);
  const [selectedaddcustomer, setselectedaddcustomer] = useState([]);
  const [NewAddCustomer, setNewAddCustomer] = useState(false);
  useEffect(() => {
    debugger;
    setTagname(props.Data);
    setStateGet(props.Data1);
    setCusGet(props.Data2);
    setTAGID(props.TagID);

    // setCusFirstname(props.Data2[0].CUSTOMERNAME)
    const customerIds = props.Data2.map((data) => data.CUSTOMERID);
    debugger;

    var demp = [];

    debugger;
    customerIds.map((customer) => demp.push({ customerid: customer }));

    customerdetails(demp, props.TagID);
  }, []);

  const customerdetails = (customerIds, TagIDParam) => {
    debugger;
    // Loop through each customer ID and make separate API calls

    axios
      .post(config.apiLink + "Dashboard/CustomerDetailPopup", {
        CusidList: customerIds,
        TagID: TagIDParam,
      })
      .then((response) => {
        debugger;
        console.log(response.data);
        var temp = response.data;


        setCustomerDetails(temp["CustomerDetails"]);

        const allname = temp["CustomerDetails"].map(
          (detail) => detail["FIRSTNAME"]
        );
        const uniqueNames = Array.from(new Set(allname));
        setFirstname(uniqueNames);

    
       
      })
      .catch((error) => {
        // Handle errors here
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  

  const filterCustomerDetails = () => {
    debugger;
    // If no value selected, return all customer details
    if (SelectedFirstNames.length === 0) {
      return CustomerDeatils;
    } else {
      const filteredDetails = [];

      CustomerDeatils.forEach((customer) => {
        // Check if the first name of the customer is included in the selected first names
        const customerFirstName = customer.FIRSTNAME;

        const isMatch = SelectedFirstNames.includes(customerFirstName);

        if (isMatch) {
          filteredDetails.push(customer);
        }
      });
      return filteredDetails;
    }
  };


  const OpenPopupAddCustomer = () => {
    debugger;
    setNewAddCustomer(true);
  };
  const handleDelete = (e, index, CUST_CONTACTID) => {
    debugger;
    // Create a copy of the CustomerDeatils array
    var newarr = [...CustomerDeatils];
    
    // Update the CheckboxStatus of the item at the given index
    newarr[index].CheckboxStatus = e.target.checked;
  
    // Update the state with the new array
    setCustomerDetails(newarr);
  
    // Optionally, update the prevDeletedcus state if needed
    setPrevDeletedcus((prevDeletedcus) => [...prevDeletedcus, CUST_CONTACTID]);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
  const handletosavecustomerdetails = () => {
    debugger;
    console.log("Received data in AlertSelectTagBox:", prevDeletedcus);

    console.log(TAGID);

    axios
      .post(config.apiLink + "Dashboard/CustomerDetailsPopupAddDelete", {
        CustomerIdsToDelete: prevDeletedcus,
        Tagid: TAGID,
      })
      .then((response) => {
        debugger;
        console.log(response.data);
      });
  };

  return (
    <div>
      <div>
     
        <Dialog
          open={props.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="lg"
        >
          <div
            className="flexCenterCenter"
            style={{ width: "100%", height: "50px", background: "blue" }}
          >
            <h3>Customer_Contacts</h3>
          </div>
          <Grid container lg={{ padding: "10px" }}>
            <Grid item lg={12} style={{ width: "100%", height: "100%" }}>
              <h3
                style={{ width: "65%", margin: "10px auto", display: "flex" }}
              >
                Tagname:{Tagname}
              </h3>
              <div
                style={{ width: "65%", margin: "10px auto", display: "flex" }}
              >
                <Autocomplete
                  multiple
                  limitTags={3}
                  id="multiple-limit-tags"
                  value={SelectedFirstNames}
                  onChange={(event, newValue) => {
                    setSelectedFirstNames(newValue);
                  }}
                  options={Firstname}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="FIRSTNAME"
                      placeholder="FIRSTNAME"
                    />
                  )}
                  sx={{ width: "900px" }}
                />
                {/*onClick={loadCustomer} */}
                <div onClick={OpenPopupAddCustomer} className="gridCenter">
                  <ControlPointIcon
                    style={{ fontSize: 25, color: "blue", cursor: "pointer" }}
                  />
                </div>
              </div>

             
              <Card
                style={{
                  borderRadius: "10px",
                  margin: "0px auto",
                  boxShadow: "gray 0px 0px 4px",
                  padding: "10px",
                  margin: "10px",
               
                  maxHeight: "300px"
                }}
              >
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
                  <Table  stickyHeader aria-label="sticky table" >
                    <TableHead> 
                      <TableRow>
                        <StyledTableCell>CUSTOMERNAME</StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Mobile</StyledTableCell>
                        <StyledTableCell>Email</StyledTableCell>
                        <StyledTableCell>Department</StyledTableCell>
                        <StyledTableCell>Designation</StyledTableCell>
                        <StyledTableCell>Edit</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filterCustomerDetails(Firstname).map(
                        (contact, index) => (
                          <StyledTableRow  key={index}>
                            <StyledTableCell  align="centre" scope="row">
                              {contact.FIRSTNAME}
                            </StyledTableCell>
                            <StyledTableCell align="centre">
                              {contact.CONTACTNAME}
                            </StyledTableCell>
                            <StyledTableCell align="centre">
                              {contact.CONTACTMOBILE}
                            </StyledTableCell>
                            <StyledTableCell align="centre">
                              {contact.CONTACTEMAIL}
                            </StyledTableCell>
                            <StyledTableCell align="centre">
                              {contact.CONTACTDEPT}
                            </StyledTableCell>
                            <StyledTableCell align="centre">
                              {contact.CONTACTDESGN}
                            </StyledTableCell>
                            <StyledTableCell align="centre">
                            
                              <FormControlLabel
                                required
                                control={<Checkbox checked={contact.CheckboxStatus} />}
                                label="delete"
                                onClick={(e) => handleDelete(e,index,contact.CUST_CONTACTID)}
                              />
                            </StyledTableCell>
                          </StyledTableRow >
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                </Paper>
               
              </Card>
              <div style={{ display: "flex" }}>
                <Button
                  style={{ display: "flex", width: "30%", margin: "10px auto" }}
                  variant="contained"
                  onClick={handletosavecustomerdetails}
                >
                  Save
                </Button>
              </div>
            </Grid>

            {/* <Grid item lg={6}   style={{ width: "100%", height: "50px", background: "green" }}>
             e
            </Grid> */}
          </Grid>
        </Dialog>
      </div>
      <div>
        {NewAddCustomer && (
          <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="lg"
          >
            <AddNewContact addTagname={Tagname} addTagID={TAGID} cusdetails={CustomerDeatils}    onClose={handleClose} />
          </Dialog>
        )}
      </div>
    </div>
  );
};
export default AlertSelectTagBox;
