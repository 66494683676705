import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import config from '../../Common/config';
import { Card, Grid, Typography } from '@mui/material';
import moment from 'moment';

const ProductCommentsList = (props) => {
  debugger;
  const { ProductId } = props;
  const [cmtDet, setCmtDet] = useState([]);
  const [prodDet, setProdDet] = useState([]);

  const CommentListLoad = useCallback((Id) => {
    debugger;
    axios
      .post(config.apiLink + "Alucast/GetCommentDetails", {
        ProductId: Id,
      })
      .then(
        (response) => {
          debugger;
          var Temp = response.data;
          setCmtDet(Temp["CommentsDet"])
          setProdDet(Temp["ProductDet"])
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  useEffect(() => {
    CommentListLoad(ProductId);
  }, [CommentListLoad, ProductId]);

  return (
    <div className="margin_top20">

      {prodDet.map((prod, ind) => (
        <Grid container>
          <Grid item xs={12} sm={12} md={4} lg={4} >
            <div className="gridStart">
              <Typography
                align="left"
                className="InterRegular font_size16 color-636363"
                gutterBottom
              >
                Brand
              </Typography>
              <Typography
                align="left"
                className="InterMedium font_size18 color-161616"
              >
                {prod.BRANDNAME}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} >
            <div className="gridStart">
              <Typography
                align="left"
                className="InterRegular font_size16 color-636363"
                gutterBottom
              >
                Product Name
              </Typography>
              <Typography
                align="left"
                className="InterMedium font_size18 color-161616"
              >
                {prod.PRODUCTNAME}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} >
            <div className="gridStart">
              <Typography
                align="left"
                className="InterRegular font_size16 color-636363"
                gutterBottom
              >
                ModelNo
              </Typography>
              <Typography
                align="left"
                className="InterMedium font_size18 color-161616"
              >
                {prod.MODELNO}
              </Typography>
            </div>
          </Grid>
        </Grid>
      ))}

      {cmtDet.length > 0 && cmtDet.map((cmt, ind) => (

        <Card
          sx={{
            mb: 2,
            mt: 2,
            padding: "8px 16px",
            bgcolor: "#fefefe",
            border: "2px solid #005251",
            borderRadius: "10px",
            boxShadow:
              "0px 1px 8px rgba(0, 255, 255, 0.6)" /* Adjust the color to match */,
          }}
          className="flexCenterCenterHeight"
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={1} lg={1}>
            <div className="gridStart">
                <Typography
                  align="left"
                  className="InterRegular font_size12 color-636363"
                  gutterBottom
                >
                  Sl.No
                </Typography>
                <Typography
                  align="left"
                  className="InterMedium font_size15 color-161616"
                >
                  {ind+1}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <div className="gridStart">
                <Typography
                  align="left"
                  className="InterRegular font_size12 color-636363"
                  gutterBottom
                >
                  Product Comment
                </Typography>
                <Typography
                  align="left"
                  className="InterMedium font_size15 color-161616"
                >
                  {cmt.COMMENTS}
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <div className="gridStart">
                <Typography
                  align="left"
                  className="InterRegular font_size12 color-636363"
                  gutterBottom
                >
                  Commented By
                </Typography>
                <Typography
                  align="left"
                  className="InterMedium font_size15 color-161616"
                >
                  {cmt.EMPLOYEENAME}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <div className="gridStart">
                <Typography
                  align="left"
                  className="InterRegular font_size12 color-636363"
                  gutterBottom
                >
                  Commented On
                </Typography>
                <Typography
                  align="left"
                  className="InterMedium font_size15 color-161616"
                >
                  {moment(cmt.CREATEDTIME).format(
                    "DD MMM YYYY hh:mm a"
                  )}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Card>
      ))}

    </div>
  )
}

export default ProductCommentsList