import {
  Backdrop,
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import DividerWithText from "./Components/DividerWithText";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CircleIcon from "@mui/icons-material/Circle";
import ImageWithBorders from "./Components/ImageWithBorders";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import config from "../Common/config";
import CustomSpinner from "../Common/CustomSpinner";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import EditEnquiryCardMember from "./Components/EditEnquiryCardMember";
import AddBusinessCard from "./Components/AddBusinessCard";
import EnquiryCardProductCustomerDisplay from "./Components/EnquiryCardProductCustomerDisplay";
import NoData from "../Features/EstimateMaster/NoData";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    borderRadius: "20px", // This changes the border radius of the dialog paper
  },
}));

function EnquiryFromCardDetailsRePrint() {
  var EmpID = localStorage.getItem("EMPID");
  const location = useLocation();
  const history = useNavigate();
  const [IDCardIndex, setIDCardIndex] = useState("");
  const [IDCardStatus, setIDCardStatus] = useState("");
  const [FirstIDCardIndex, setFirstIDCardIndex] = useState(0);
  const [previousCompanyName, setPreviousCompanyName] = useState(0);
  const [currentCompanyName, setCurrentCompanyName] = useState(0);
  const [loadingCommon, setLoadingCommon] = useState(true);
  const [afterLoadingCommon, setAfterLoadingCommon] = useState(false);
  const [enquiryCommentsDet, setEnquiryCommentsDet] = useState([]);
  const [enquiryCommentCount, setEnquiryCommentCount] = useState(0);
  const [enquiryCardDet, setEnquiryCardDet] = useState([]);
  const [enquiryCardEditDet, setEnquiryCardEditDet] = useState({});
  const [enquiryCardEditDialog, setEnquiryCardEditDialog] = useState(false);
  const [addBusinessCardDialog, setAddBusinessCardDialog] = useState(false);
  const [enquiryComment, setEnquiryComment] = useState("");
  const [alreadyPickedDialog, setAlreadyPickedDialog] = useState(false);
  const [enquiryCards, setEnquiryCards] = useState([]);
  const [reprintMemberDetails, setReprintMemberDetails] = useState([]);
  const [printPreview, setPrintPreview] = useState(false);
  const [viewCommentDialog, setViewCommentDialog] = useState(false);
  const [addCommentDialog, setAddCommentDialog] = useState(false);
  const [courtesyVisit, setCourtesyVisit] = useState(false);
  const [addEnqCommentMemberID, setAddEnqCommentMemberID] = useState(0);
  const [enqiryCardMembersId, setEnqiryCardMembersId] = useState(0);

  React.useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-in-out", // Easing function
      once: true, // Whether animation should happen only once or every time you scroll
      offset: 100, // Trigger animation when 200px from the element
    });
  }, []);

  const handleIDCardState = (param) => {
    AfterEnquiryCardDetailsLoad(
      location.state.params.ENQUIRYID,
      param.ENQUIRYCARDMEMBERSID,
      0
    );

    setIDCardIndex(param.ENQUIRYCARDMEMBERSID);
    setIDCardStatus(param.STATUS);
    setPreviousCompanyName(currentCompanyName);
    setCurrentCompanyName(param.COMPANYNAME);
  };

  const handleChange = (e, Id) => {
    debugger;
    setEnqiryCardMembersId(Id);
    if (e.target.checked === false) {
      setCourtesyVisit(e.target.checked);
    } else {
      setCourtesyVisit(e.target.checked)
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top-left corner
  }, []);

  const GetCommonCommentDetails = useCallback((ID) => {
    axios
      .post(config.apiLink + "Alucast/GetCommonCommentDetails", {
        EnquiryCardMembersId: ID,
      })
      .then(
        (response) => {
          debugger;
          var Temp = response.data;
          setEnquiryCommentCount(Temp["CommentCount"][0].COMMONCOMMENTCOUNT);
          setEnquiryCommentsDet(Temp["CommentsDet"]);
          setLoadingCommon(false);
        },
        (error) => {
          console.log(error);
          setAfterLoadingCommon(false);
        }
      );
  }, []);

  const EnquiryCardDetailsLoad = useCallback(
    (ID, EnquiryCardMemeberIDParam, LoadDetStatusParam) => {
      axios
        .post(config.apiLink + "Alucast/EnquiryReprintCardDetailsLoad", {
          EnquiryCardID: ID,
          EnquiryCardMemberID: EnquiryCardMemeberIDParam,
          Status: LoadDetStatusParam,
        })
        .then(
          (response) => {
            debugger;
            var Temp = response.data;
            setEnquiryCardDet(Temp);
            if (Temp.length !== 0) {
              setIDCardIndex(Temp[0]["OriginalData"].ENQUIRYCARDMEMBERSID);
              setIDCardStatus(Temp[0]["OriginalData"].STATUS);
              setFirstIDCardIndex(Temp[0]["OriginalData"].ENQUIRYCARDMEMBERSID);
              setPreviousCompanyName(Temp[0]["OriginalData"].COMPANYNAME);
              setCurrentCompanyName(Temp[0]["OriginalData"].COMPANYNAME);
            }
            GetCommonCommentDetails(EnquiryCardMemeberIDParam);
            // setLoadingCommon(false);
          },
          (error) => {
            console.log(error);
          }
        );
    },
    [GetCommonCommentDetails]
  );

  const EnquiryCardsLoad = useCallback(
    (ID, LoadDetStatusParam) => {
      axios
        .post(config.apiLink + "Alucast/EnquiryCardsLoad", {
          EnquiryCardID: ID,
          Status: LoadDetStatusParam,
        })
        .then(
          (response) => {
            var Temp = response.data;
            setEnquiryCards(Temp["EnquiryCards"]);
            EnquiryCardDetailsLoad(
              ID,
              Temp["EnquiryCards"][0].ENQUIRYCARDMEMBERSID,
              LoadDetStatusParam
            );
          },
          (error) => {
            console.log(error);
          }
        );
    },
    [EnquiryCardDetailsLoad]
  );

  useEffect(() => {
    EnquiryCardsLoad(location.state.params.ENQUIRYID, 0);
  }, [history, EnquiryCardsLoad, location.state]);

  const AfterGetCommonCommentDetails = (ID) => {
    axios
      .post(config.apiLink + "Alucast/GetCommonCommentDetails", {
        EnquiryCardMembersId: ID,
      })
      .then(
        (response) => {
          var Temp = response.data;
          setEnquiryCommentCount(Temp["CommentCount"][0].COMMONCOMMENTCOUNT);
          setEnquiryCommentsDet(Temp["CommentsDet"]);
          setAfterLoadingCommon(false);
        },
        (error) => {
          console.log(error);
          setAfterLoadingCommon(false);
        }
      );
  };

  const AfterEnquiryCardDetailsLoad = (
    ID,
    EnquiryCardMemeberIDParam,
    LoadDetStatusParam
  ) => {
    setAfterLoadingCommon(true);
    axios
      .post(config.apiLink + "Alucast/EnquiryReprintCardDetailsLoad", {
        EnquiryCardID: ID,
        EnquiryCardMemberID: EnquiryCardMemeberIDParam,
        Status: LoadDetStatusParam,
      })
      .then(
        (response) => {
          var Temp = response.data;
          setEnquiryCardDet(Temp);
          if (Temp.length !== 0) {
            setIDCardIndex(Temp[0]["OriginalData"].ENQUIRYCARDMEMBERSID);
            setIDCardStatus(Temp[0]["OriginalData"].STATUS);
            setFirstIDCardIndex(Temp[0]["OriginalData"].ENQUIRYCARDMEMBERSID);
            setPreviousCompanyName(Temp[0]["OriginalData"].COMPANYNAME);
            setCurrentCompanyName(Temp[0]["OriginalData"].COMPANYNAME);
          }
          // setAfterLoadingCommon(false);
          AfterGetCommonCommentDetails(EnquiryCardMemeberIDParam);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const EnquiryCardProcessedByUpdate = (ID) => {
    axios
      .post(config.apiLink + "Alucast/EnquiryCardProcessedByUpdate", {
        EnquiryCardID: ID,
        EmpID: EmpID,
      })
      .then(
        (response) => {
          var Temp = response.data;
          if (Temp === "S") {
            history("/EnquiryFromCardList");
          } else if (Temp === "F") {
            setAlreadyPickedDialog(true);
            setAfterLoadingCommon(false);
          }
        },
        (error) => {
          console.log(error);
          setAfterLoadingCommon(false);
        }
      );
  };

  const handlePickIt = (ID) => {
    setAfterLoadingCommon(true);
    EnquiryCardProcessedByUpdate(ID);
  };

  const VisitingCart = (param) => {
    setAfterLoadingCommon(true);
    // https://api7.tvenka.com/api/Byte/VisitingCart?MembersCardNo=M0001
    axios
      .post(
        `https://spareidentification-rdlc.tvenka.com/api/Byte/VisitingCartName?MembersShipCardId=${param}`
      )
      .then(
        (response) => {
          var Temp = response.data;
          // setPreviewDet(Temp);
          printPreviewFile(Temp);
          // setPreview(true);
          // setTimeout(() => {
          //   window.scrollTo(0, document.body.scrollHeight);
          // }, 100);
          // setAfterLoadingCommon(false);
        },
        (error) => {
          console.log(error);
          setAfterLoadingCommon(false);
        }
      );
  };

  const handleContactDet = (newContact) => {
    // debugger;
    // setContactDet((prevContacts) => [...prevContacts, newContact]);
  };

  // const MemberURLQRcodeGenerate = (Data) => {
  //   setAfterLoadingCommon(false);
  //   axios
  //     .post(config.apiLink + "Alucast/MemberURLQRcodeGenerate", {
  //       MemberCardsNo: Data.MEMBERSCARDNO,
  //       URL: Data.URL,
  //     })
  //     .then(
  //       (response) => {
  //         var Temp = response.data;
  //         if(Temp === "Success"){
  //           history("/EnquiryFromCardList");
  //         }
  //         setAfterLoadingCommon(false);
  //       },
  //       (error) => {
  //         console.log(error);
  //       }
  //     );
  // };

  // const handleMembershipCardQRcodeGenerate = (data) => {
  //     MemberURLQRcodeGenerate(data);
  // };

  // const handleMembershipCardPreview = (data) => {
  //   if (preview === false) {
  //     VisitingCart(data.MEMBERSHIPCARDID);
  //   } else {
  //     setPreview(false);
  //   }
  // };

  const handleMembershipCardDetailsEdit = (data) => {
    debugger;
    setEnquiryCardEditDet(data);
    setEnquiryCardEditDialog(true);
  };

  const handleEnquiryCardEditDialogClose = () => {
    setEnquiryCardEditDialog(false);
    setAddBusinessCardDialog(false);
    setTimeout(() => {
      setEnquiryCardEditDet({});
    }, 1000);
  };

  const handleEnquiryCardEditResponse = () => {
    setEnquiryCardEditDialog(false);
    setAddBusinessCardDialog(false);
    AfterEnquiryCardDetailsLoad(
      location.state.params.ENQUIRYID,
      IDCardIndex,
      0
    );
    setTimeout(() => {
      setEnquiryCardEditDet({});
    }, 1000);
  };

  const handleExtract = (data) => {
    setAfterLoadingCommon(true);
    EnquiryCardDataExtract(
      data.FRONTSIDE,
      data.ENQUIRYCARDMEDIAID,
      data.MEDIALINKID
    );
  };

  const handleAddManually = (data) => {
    setEnquiryCardEditDet(data);
    setEnquiryCardEditDialog(true);
  };

  const ExtractedDataSave = (Data, MediaLinkIDParam) => {
    axios
      .post(config.apiLink + "Alucast/ExtractedDataSave", {
        Name: Data.ContactName,
        // CompanyName:Data.CompanyName,
        CompanyName: "",
        Email: Data.Email,
        Address: Data.Address,
        Designation: Data.JobTitle,
        Mobilenumber: Data.WorkPhone,
        Website: Data.Website,
        ImageId: MediaLinkIDParam,
      })
      .then(
        (response) => {
          var Temp = response.data;
          if (Temp === "Success") {
            AfterEnquiryCardDetailsLoad(
              location.state.params.ENQUIRYID,
              IDCardIndex,
              0
            );
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const EnquiryCardDataExtract = (
    FilenameParam,
    CardMediaIDParam,
    MediaLinkIDParam
  ) => {
    axios
      .post(config.apiLink + "Alucast/EnquiryCardDataExtract", {
        FileName: FilenameParam,
        Id: CardMediaIDParam,
      })
      .then(
        (response) => {
          var Temp = response.data;
          ExtractedDataSave(Temp["ExtractedData"][0], MediaLinkIDParam);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleAddBusinessCard = () => {
    setAddBusinessCardDialog(true);
  };

  const SplitAsNewEnquirySave = () => {
    axios
      .post(config.apiLink + "Alucast/SplitAsNewEnquirySave", {
        EnquiryCardID: location.state.params.ENQUIRYID,
        EnquiryCardMemebersID: IDCardIndex,
        EmpID: EmpID,
      })
      .then(
        (response) => {
          var Temp = response.data;
          if (Temp === "Success") {
            AfterEnquiryCardDetailsLoad(
              location.state.params.ENQUIRYID,
              IDCardIndex,
              0
            );
          }
        },
        (error) => {
          console.log(error);
          setAfterLoadingCommon(false);
        }
      );
  };

  const handleSplitAsNewEnquiry = () => {
    setAfterLoadingCommon(true);
    SplitAsNewEnquirySave();
  };

  const handleCreateEnquiry = (ID) => {
    // debugger;
    window.open(`${config.enquiryLink}EnquiryCreation?id=${ID}`);
  };

  const EnquiryCardMemberSendForPrintSave = () => {
    axios
      .post(config.apiLink + "Alucast/EnquiryCardMemberSendForPrintSave", {
        EnquiryCardMemebersID: IDCardIndex,
        EmpID: EmpID,
      })
      .then(
        (response) => {
          var Temp = response.data;
          if (Temp === "Success") {
            history("/EnquiryFromCardList");
          }
        },
        (error) => {
          console.log(error);
          setAfterLoadingCommon(false);
        }
      );
  };

  const handleSendForPrint = () => {
    var MembershipCardID = enquiryCardDet.filter((item) => {
      return item["OriginalData"].ENQUIRYCARDMEMBERSID === IDCardIndex;
    });

    // Call the function with the binary data
    VisitingCart(MembershipCardID[0]["OriginalData"].MEMBERSHIPCARDID);
  };

  const EnquiryCardRePrintUpdate = (ID) => {
    axios
      .post(config.apiLink + "Alucast/EnquiryCardRePrintUpdate", {
        EnquiryCardMembersID: ID,
        EmpID: EmpID,
      })
      .then(
        (response) => {
          var Temp = response.data;
          setReprintMemberDetails(Temp["ReprintedMemberDetails"]);
          setPrintPreview(true);
          setAfterLoadingCommon(false);
        },
        (error) => {
          console.log(error);
          setAfterLoadingCommon(false);
        }
      );
  };

  function printPreviewFile(base64Data) {
    if (!base64Data) {
      console.error("PreviewFileURL is missing or invalid");
      return;
    }

    // Decode Base64 string and create a Blob
    const byteCharacters = atob(base64Data);
    const byteNumbers = Array.from(byteCharacters).map((char) =>
      char.charCodeAt(0)
    );
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    // Create an object URL
    const blobUrl = URL.createObjectURL(blob);

    // Open the Blob URL in a new tab
    const newTab = window.open(blobUrl, "_blank");

    // Let the user print manually if new tab opens
    if (newTab) {
      EnquiryCardRePrintUpdate(IDCardIndex);
      newTab.onload = () => URL.revokeObjectURL(blobUrl);
    } else {
      console.error("Failed to open new tab. Ensure pop-ups are allowed.");
    }
  }

  const handleAlreadyPickedDialogClose = () => {
    setAlreadyPickedDialog(false);
  };

  const handlePrintPreviewDialogClose = () => {
    history("/EnquiryFromCardList");
  };

  const handleViewCommentDialog = () => {
    setViewCommentDialog(true);
  };

  const handleAddCommentDialog = (ID) => {
    setAddCommentDialog(true);
    setAddEnqCommentMemberID(ID);
  };

  const handleViewCommentDialogClose = () => {
    setViewCommentDialog(false);
    setAddCommentDialog(false);
  };

  const AddCommonComments = () => {
    axios
      .post(config.apiLink + "Alucast/AddCommonComments", {
        EnquiryCardMembersId: addEnqCommentMemberID,
        CreatedBy: EmpID,
        Comment: enquiryComment,
      })
      .then(
        (response) => {
          // var Temp = response.data;
          setAddCommentDialog(false);
          AfterEnquiryCardDetailsLoad(
            location.state.params.ENQUIRYID,
            IDCardIndex,
            0
          );
          // setAfterLoadingCommon(false);
        },
        (error) => {
          console.log(error);
          setAfterLoadingCommon(false);
        }
      );
  };

  const handleAddEnquirySave = () => {
    setAfterLoadingCommon(true);
    AddCommonComments();
  }
  const handleCourtesyDialogClose = () => {
    setCourtesyVisit(false);
  }
  const handleCourtesyVisitUpdate = () => {
    axios.post(config.apiLink + "Alucast/CourtesyVisitUpdate",
      {
        EnquiryCardMembersID: enqiryCardMembersId,
        CreatedBy: EmpID,
      }).then
      (
        (response) => {
          var Temp = response.data;
          setCourtesyVisit(false);
          AfterEnquiryCardDetailsLoad(
            location.state.params.ENQUIRYID,
            IDCardIndex,
            0
          );
        },
        (error) => {
          console.log(error)
        }
      )
  }

  return (
    <div className="body_margin_Enquiry">
      {!loadingCommon && (
        <>
          <Card
            // data-aos="fade-up"
            sx={{
              padding: "32px 24px 24px",
              borderRadius: "10px",
              border: "1px solid #d4e6e6",
              boxShadow: 0,
              mt: 1,
              mb: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Lot No
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {location.state.params.LOTNO}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2} sm={2} md={3} lg={3}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Lead By
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {location.state.params.LEADBYNAME}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Cards / Validated / Printed / CustomerMapped / Quoted
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {location.state.params.TOTALCARDS}&nbsp;/&nbsp;{location.state.params.VALIDATEDCARDS}
                    &nbsp;/&nbsp;{location.state.params.PRINTEDCARDS}
                    &nbsp;/&nbsp;{location.state.params.CUSTOMERMAPPED}
                    &nbsp;/&nbsp;{location.state.params.QUOTED}
                  </Typography>
                </div>
              </Grid>
              {/* <Grid item xs={2} sm={2} md={3} lg={3} sx={{ mt: 1 }}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Taken By
                  </Typography>
                  {location.state.params.TAKENBY !== null && (
                    <Typography
                      align="left"
                      className="InterMedium font_size18 color-161616"
                    >
                      {location.state.params.TAKENBY}
                    </Typography>
                  )}
                  {location.state.params.TAKENBY === null && (
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        pl: 2,
                        pr: 2,
                        textTransform: "capitalize",
                        bgcolor: "#fab005",
                        border: "1px solid #df9d05",
                        animation: "blink 1s infinite", // Add animation to the button
                        "&:hover": {
                          bgcolor: "#e6a504", // Slightly darker shade
                          opacity: 0.8, // Decrease opacity
                          animation: "none", // Disable blink on hover for better UX
                        },
                        "@keyframes blink": {
                          "0%": { opacity: 1 },
                          "50%": { opacity: 0.5 },
                          "100%": { opacity: 1 },
                        },
                      }}
                      onClick={() => handlePickIt(location.state.params.ENQUIRYID)}
                    >
                      Pick It
                    </Button>
                  )}
                </div>
              </Grid> */}
              <Grid item xs={2} sm={2} md={3} lg={3} sx={{ mt: 1 }}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Enquiry Time
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {moment(location.state.params.CREATEDTIME).format("DD MMM hh:mm a")}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 1 }}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Contact Names
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {location.state.params.MEMBERNAMES}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 1 }}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Company
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {location.state.params.MEMBERCOMPANYNAMES}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} sx={{ mt: 1 }}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Product Count
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {location.state.params.PRODUCTCOUNT}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} sx={{ mt: 1 }}>
                <div className="flexStartCenterHeight">
                  {/* <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Hot Lead
                  </Typography> */}
                  <div className={`${location.state.params.LATESTHOTLEADBY === 1 ? 'glow-effect' : ''}`}>
                    <Typography
                      align="left"
                      className={`InterMedium font_size18 ${location.state.params.LATESTHOTLEADBY === 1 ? 'blink-effect' : ''}`}
                    >
                      {location.state.params.LATESTHOTLEADBY === 1 ? "Hot Lead" : ""}
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} sx={{ mt: 1 }}>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 1 }}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Latest Comment
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {location.state.params.LATESTCOMMENT ? location.state.params.LATESTCOMMENT : "N/A"}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 1 }}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Latest Comment By / Time
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {location.state.params.LATESTCOMMENTCREATEDBY ? location.state.params.LATESTCOMMENTCREATEDBY : "N/A"}&nbsp;/&nbsp;{location.state.params.LATESTCOMMENTCREATEDTIME ? moment(location.state.params.LATESTCOMMENTCREATEDTIME).format("DD MMM YYYY hh:mm a") : "N/A"}

                  </Typography>
                </div>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={3} lg={3} sx={{ mt: 1 }}>
                <div className="gridStart">
                  <Typography
                    align="left"
                    className="InterRegular font_size16 color-636363"
                    gutterBottom
                  >
                    Latest Comment Created By
                  </Typography>
                  <Typography
                    align="left"
                    className="InterMedium font_size18 color-161616"
                  >
                    {elem.LATESTCOMMENTCREATEDBY ? elem.LATESTCOMMENTCREATEDBY : "N/A"}
                  </Typography>
                </div>
              </Grid> */}
            </Grid>
          </Card>
          <DividerWithText>Business&nbsp;Card&nbsp;Details</DividerWithText>
          <Grid container sx={{ mt: 2 }}>
            {enquiryCardDet.length !== 0 && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="flexEndCenterHeight">
                  <div className="flexCenterCenterHeight margin_right10">
                    <CircleIcon
                      sx={{
                        mr: 0.5,
                        color: "#4CAF50",
                        border: "1px solid #636363",
                        borderRadius: "50%", // Ensures the border is perfectly round
                        boxSizing: "content-box", // Ensures the border doesn't add extra space
                      }}
                    />
                    <Typography
                      align="left"
                      className="InterRegular font_size16 color-636363"
                    >
                      Printed
                    </Typography>
                  </div>
                  <div className="flexCenterCenterHeight margin_right10">
                    <CircleIcon
                      sx={{
                        mr: 0.5,
                        color: "#FFEB3B",
                        border: "1px solid #636363",
                        borderRadius: "50%", // Ensures the border is perfectly round
                        boxSizing: "content-box", // Ensures the border doesn't add extra space
                      }}
                    />
                    <Typography
                      align="left"
                      className="InterRegular font_size16 color-636363"
                    >
                      Validated
                    </Typography>
                  </div>
                  <div className="flexCenterCenterHeight">
                    <CircleIcon
                      sx={{
                        mr: 0.5,
                        color: "#F44336",
                        border: "1px solid #636363",
                        borderRadius: "50%", // Ensures the border is perfectly round
                        boxSizing: "content-box", // Ensures the border doesn't add extra space
                      }}
                    />
                    <Typography
                      align="left"
                      className="InterRegular font_size16 color-636363"
                    >
                      Pending
                    </Typography>
                  </div>
                </div>
              </Grid>
            )}
            {enquiryCards.map((elem, ind) => (
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <Box
                  data-aos="fade-down"
                  sx={{ m: 2, cursor: "pointer" }}
                  onClick={() => handleIDCardState(elem)}
                >
                  <Card
                    className="flexCenterCenterHeight"
                    sx={{
                      borderTop: "1px solid #d4e6e6",
                      borderLeft: "1px solid #d4e6e6",
                      borderRight: "1px solid #d4e6e6",
                      borderRadius: "10px 10px 0px 0px",
                      boxShadow: 0,
                      p: 1,
                      bgcolor:
                        Number(IDCardIndex) ===
                          Number(elem.ENQUIRYCARDMEMBERSID)
                          ? "#d4e6e6"
                          : "#ffffff",
                    }}
                  >
                    <AccountBoxOutlinedIcon sx={{ fontSize: "100px" }} />
                  </Card>
                  <Card
                    className="flexCenterCenterHeight"
                    sx={{
                      borderBottom: "1px solid #d4e6e6",
                      borderLeft: "1px solid #d4e6e6",
                      borderRight: "1px solid #d4e6e6",
                      borderRadius: "0px 0px 10px 10px",
                      boxShadow: 0,
                      bgcolor:
                        elem.STATUS === 3
                          ? "#FFEB3B"
                          : elem.STATUS === 1 || elem.STATUS === 2
                            ? "#F44336"
                            : "#4CAF50",
                    }}
                  >
                    <Typography
                      align="left"
                      className="InterBold font_size18 color-616161"
                      sx={{ p: 1 }}
                    >
                      Card {ind + 1}
                    </Typography>
                  </Card>
                </Box>
              </Grid>
            ))}
            {/* <Grid item xs={12} sm={12} md={2} lg={2}>
              <Box
                sx={{ m: 2, cursor: "pointer" }}
                onClick={() => handleIDCardState(1)}
              >
                <Card
                  className="flexCenterCenterHeight"
                  sx={{
                    borderTop: "1px solid #d4e6e6",
                    borderLeft: "1px solid #d4e6e6",
                    borderRight: "1px solid #d4e6e6",
                    borderRadius: "10px 10px 0px 0px",
                    boxShadow: 0,
                    p: 1,
                    bgcolor: IDCardIndex === 1 ? "#d4e6e6" : "#ffffff",
                  }}
                >
                  <AccountBoxOutlinedIcon sx={{ fontSize: "100px" }} />
                </Card>
                <Card
                  className="flexCenterCenterHeight"
                  sx={{
                    borderBottom: "1px solid #d4e6e6",
                    borderLeft: "1px solid #d4e6e6",
                    borderRight: "1px solid #d4e6e6",
                    borderRadius: "0px 0px 10px 10px",
                    boxShadow: 0,
                    bgcolor: "#FFEB3B",
                  }}
                >
                  <Typography
                    align="left"
                    className="InterBold font_size18 color-616161"
                    sx={{ p: 1 }}
                  >
                    Card 2
                  </Typography>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <Box
                sx={{ m: 2, cursor: "pointer" }}
                onClick={() => handleIDCardState(2)}
              >
                <Card
                  className="flexCenterCenterHeight"
                  sx={{
                    borderTop: "1px solid #d4e6e6",
                    borderLeft: "1px solid #d4e6e6",
                    borderRight: "1px solid #d4e6e6",
                    borderRadius: "10px 10px 0px 0px",
                    boxShadow: 0,
                    p: 1,
                    bgcolor: IDCardIndex === 2 ? "#d4e6e6" : "#ffffff",
                  }}
                >
                  <AccountBoxOutlinedIcon sx={{ fontSize: "100px" }} />
                </Card>
                <Card
                  className="flexCenterCenterHeight"
                  sx={{
                    borderBottom: "1px solid #d4e6e6",
                    borderLeft: "1px solid #d4e6e6",
                    borderRight: "1px solid #d4e6e6",
                    borderRadius: "0px 0px 10px 10px",
                    boxShadow: 0,
                    bgcolor: "#F44336",
                  }}
                >
                  <Typography
                    align="left"
                    className="InterBold font_size18 color-616161"
                    sx={{ p: 1 }}
                  >
                    Card 3
                  </Typography>
                </Card>
              </Box>
            </Grid> */}
            {/* {(IDCardStatus === "1" || IDCardStatus === "2" || enquiryCardDet.length === 0) && (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <div className="flexCenterCenterHeight">
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      pl: 2,
                      pr: 2,
                      textTransform: "capitalize",
                      bgcolor: "#fab005",
                      border: "1px solid #df9d05",
                      "&:hover": {
                        bgcolor: "#e6a504", // Slightly darker shade
                        opacity: 0.8, // Decrease opacity
                      },
                    }}
                    onClick={handleAddBusinessCard}
                  >
                    <PersonAddAltOutlinedIcon className="color-161616" />
                    <Typography
                      align="left"
                      className="InterMedium font_size18 color-161616 margin_left10"
                    >
                      Add Business Card
                    </Typography>
                  </Button>
                </div>
              </Grid>
            )} */}
          </Grid>
          {/* {IDCardStatus !== "5" && (
            <Box sx={{ ml: 2, mr: 2, mt: 3 }}>
              <Grid container justifyContent={"center"}>
                {enquiryCardDet
                  .filter(
                    (item) =>
                      item["OriginalData"].ENQUIRYCARDMEMBERSID === IDCardIndex
                  ) // Filter items with the target ID
                  .map((item) => (
                    <>
                      {item.FrontSideImage !== null && (
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Typography
                            align="left"
                            className="InterBold font_size18 color-616161"
                            sx={{ mb: 2 }}
                          >
                            Front Side
                          </Typography>
                          <div data-aos="flip-down">
                            <ImageWithBorders FileURL={item.FrontSideImage} />
                          </div>
                        </Grid>
                      )}
                    </>
                  ))}
                {enquiryCardDet
                  .filter(
                    (item) =>
                      item["OriginalData"].ENQUIRYCARDMEMBERSID === IDCardIndex
                  ) // Filter items with the target ID
                  .map((item) => (
                    <>
                      {item.BackSideImage !== null && (
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Typography
                            align="left"
                            className="InterBold font_size18 color-616161"
                            sx={{ mb: 2 }}
                          >
                            Back Side
                          </Typography>
                          <div data-aos="flip-down">
                            <ImageWithBorders FileURL={item.BackSideImage} />
                          </div>
                        </Grid>
                      )}
                    </>
                  ))}
                {enquiryCardDet.length > 1 &&
                  FirstIDCardIndex !== IDCardIndex &&
                  currentCompanyName !== previousCompanyName &&
                  (IDCardStatus === "1" || IDCardStatus === "2") && (
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <div className="flexCenterCenterHeight">
                        <Button
                          variant="contained"
                          size="large"
                          sx={{
                            pl: 2,
                            pr: 2,
                            textTransform: "capitalize",
                            bgcolor: "#fab005",
                            border: "1px solid #df9d05",
                            "&:hover": {
                              bgcolor: "#e6a504", // Slightly darker shade
                              opacity: 0.8, // Decrease opacity
                            },
                          }}
                          onClick={handleSplitAsNewEnquiry}
                        >
                          <SplitscreenIcon className="color-161616" />
                          <Typography
                            align="left"
                            className="InterMedium font_size18 color-161616 margin_left10"
                          >
                            Split As New Enquiry
                          </Typography>
                        </Button>
                      </div>
                    </Grid>
                  )}
              </Grid>
            </Box>
          )} */}
          <Box sx={{ ml: 2, mr: 2, mt: IDCardStatus !== "5" ? 5 : 0 }}>
            <Grid container justifyContent={"center"}>
              {enquiryCardDet
                .filter(
                  (item) =>
                    item["OriginalData"].ENQUIRYCARDMEMBERSID === IDCardIndex
                ) // Filter items with the target ID
                .map((item) => (
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <div className="flexEndCenterHeight">
                        {item["OriginalData"].STATUS === "1" && (
                          <Button
                            variant="contained"
                            size="small"
                            sx={{
                              pl: 2,
                              pr: 2,
                              mr: 1,
                              textTransform: "capitalize",
                              bgcolor: "#fab005",
                              border: "1px solid #df9d05",
                              "&:hover": {
                                bgcolor: "#e6a504", // Slightly darker shade
                                opacity: 0.8, // Decrease opacity
                              },
                            }}
                            onClick={() => handleExtract(item["OriginalData"])}
                          >
                            <LoginOutlinedIcon sx={{ mr: 0.5 }} /> Extract
                          </Button>
                        )}
                        {item["OriginalData"].STATUS === "1" && (
                          <Button
                            variant="contained"
                            size="small"
                            sx={{
                              pl: 2,
                              pr: 2,
                              mr: 1,
                              textTransform: "capitalize",
                              bgcolor: "#fab005",
                              border: "1px solid #df9d05",
                              "&:hover": {
                                bgcolor: "#e6a504", // Slightly darker shade
                                opacity: 0.8, // Decrease opacity
                              },
                            }}
                            onClick={() => handleAddManually(item)}
                          >
                            <LoginOutlinedIcon sx={{ mr: 0.5 }} /> Add manually
                          </Button>
                        )}
                        {(item["OriginalData"].STATUS === "5" ||
                          item["OriginalData"].STATUS === "3") && (
                            <Grid container justifyContent={"flex-end"}>

                              <Grid item xs={12} sm={12} md={4} lg={4}>
                                {item["OriginalData"].COURTESYVISIT === "1" ? (
                                  <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                      <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <div className="flexCenterCenterHeight">
                                          <div className="gridStart margin_right10">
                                            <Typography
                                              align="left"
                                              className="InterRegular font_size16 color-636363"
                                              gutterBottom
                                            >
                                              CourtesyVisit Updated By
                                            </Typography>
                                            <Typography
                                              align="left"
                                              className="InterMedium font_size18 color-161616"
                                            >
                                              {item["OriginalData"].EMPLOYEENAME}
                                            </Typography>
                                          {/* </div>
                                        </div>
                                        <div className="flexStartCenterHeight">
                                          <div className="gridStart margin_right10"> */}
                                            <Typography
                                              align="left"
                                              className="InterRegular font_size16 color-636363"
                                              gutterBottom
                                            >
                                              CourtesyVisit Updated On
                                            </Typography>
                                            <Typography
                                              align="left"
                                              className="InterMedium font_size18 color-161616"
                                            >
                                              {item["OriginalData"].UPDATEDTIME}
                                            </Typography>
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>

                                  </Grid>


                                ) : (

                                  <div className="flexCenterCenterHeight">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          color="primary"
                                          name={"chkbo"}
                                          checked={courtesyVisit}
                                          onChange={(e) => handleChange(e, item["OriginalData"].ENQUIRYCARDMEMBERSID)}
                                        />
                                      }
                                      label={
                                        <Typography
                                          variant="body1"
                                          align="left"
                                          style={{ color: "#1b2b55" }}
                                        >
                                          Courtesy Visit
                                        </Typography>
                                      }
                                    />
                                  </div>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={12} md={4} lg={4}>
                                <div className="flexCenterCenterHeight">
                                  <Button
                                    variant="contained"
                                    size="large"
                                    sx={{
                                      pl: 2,
                                      pr: 2,
                                      ml: 1,
                                      textTransform: "capitalize",
                                      bgcolor: "#fab005",
                                      border: "1px solid #df9d05",
                                      "&:hover": {
                                        bgcolor: "#e6a504", // Slightly darker shade
                                        opacity: 0.8, // Decrease opacity
                                      },
                                    }}
                                    onClick={() => handleAddCommentDialog(item["OriginalData"].ENQUIRYCARDMEMBERSID)}
                                  >
                                    Add Comment
                                  </Button>
                                  <Button
                                    variant="contained"
                                    size="large"
                                    sx={{
                                      pl: 2,
                                      pr: 2,
                                      ml: 1,
                                      textTransform: "capitalize",
                                      bgcolor: "#fab005",
                                      border: "1px solid #df9d05",
                                      "&:hover": {
                                        bgcolor: "#e6a504", // Slightly darker shade
                                        opacity: 0.8, // Decrease opacity
                                      },
                                    }}
                                    onClick={handleViewCommentDialog}
                                  >
                                    View Comment({enquiryCommentCount})
                                  </Button>
                                </div>
                              </Grid>
                              {item["OriginalData"].ISENQUIRYEXISTS === "0" && (
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                  <div className="flexEndCenterHeight">
                                    <div className="gridStart margin_right20">
                                      <Typography
                                        align="left"
                                        className="InterRegular font_size16 color-636363"
                                        gutterBottom
                                      >
                                        Enquiry Status
                                      </Typography>
                                      <Typography
                                        align="left"
                                        className="InterMedium font_size18 color-161616"
                                      >
                                        {item["OriginalData"].ENQUIRYCARDSTATUS}
                                      </Typography>
                                    </div>
                                    <Button
                                      variant="contained"
                                      size="large"
                                      sx={{
                                        pl: 2,
                                        pr: 2,
                                        textTransform: "capitalize",
                                        bgcolor: "#fab005",
                                        border: "1px solid #df9d05",
                                        "&:hover": {
                                          bgcolor: "#e6a504", // Slightly darker shade
                                          opacity: 0.8, // Decrease opacity
                                        },
                                      }}
                                      onClick={() =>
                                        handleCreateEnquiry(
                                          item["OriginalData"].MEMBERSHIPCARDID
                                        )
                                      }
                                    >
                                      <NoteAddIcon className="color-ffffff" />
                                      <Typography
                                        align="left"
                                        className="InterMedium font_size18 color-ffffff margin_left10"
                                      >
                                        Create Enquiry
                                      </Typography>
                                    </Button>
                                  </div>
                                </Grid>
                              )}

                              {item["OriginalData"].ISENQUIRYEXISTS === "1" && (
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                  <div className="flexEndCenterHeight">
                                    <div className="gridStart margin_right20">
                                      <Typography
                                        align="left"
                                        className="InterRegular font_size16 color-636363"
                                        gutterBottom
                                      >
                                        Enquiry Status
                                      </Typography>
                                      <Typography
                                        align="left"
                                        className="InterMedium font_size18 color-161616"
                                      >
                                        {item["OriginalData"].ENQUIRYCARDSTATUS}
                                      </Typography>
                                    </div>
                                    <Button
                                      variant="contained"
                                      size="large"
                                      sx={{
                                        pl: 2,
                                        pr: 2,
                                        textTransform: "capitalize",
                                        bgcolor: "#fab005",
                                        border: "1px solid #df9d05",
                                        "&:hover": {
                                          bgcolor: "#e6a504", // Slightly darker shade
                                          opacity: 0.8, // Decrease opacity
                                        },
                                      }}
                                      onClick={() =>
                                        handleCreateEnquiry(
                                          item["OriginalData"].MEMBERSHIPCARDID
                                        )
                                      }
                                    >
                                      <InsertDriveFileIcon className="color-ffffff" />
                                      <Typography
                                        align="left"
                                        className="InterMedium font_size18 color-ffffff margin_left10"
                                      >
                                        View Enquiry
                                      </Typography>
                                    </Button>
                                  </div>
                                </Grid>
                              )}
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <EnquiryCardProductCustomerDisplay
                                  EnquiryID={
                                    item["OriginalData"].CREATEDENQUIRYID
                                  }
                                  EnquiryCardMemberID={
                                    item["OriginalData"].ENQUIRYCARDMEMBERSID
                                  }
                                  ContactDet={handleContactDet}
                                  customerID={""}
                                  Company={""}
                                  ContactDetList={[]}
                                />
                              </Grid>
                            </Grid>
                          )}
                      </div>
                    </Grid>
                  </>
                ))}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {enquiryCardDet.filter((item) => item["OriginalData"].ENQUIRYCARDMEMBERSID === IDCardIndex).map((item) => (
                  <>
                    {(item["OriginalData"].STATUS === "2" ||
                      item["OriginalData"].STATUS === "3" ||
                      item["OriginalData"].STATUS === "5") && (
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                          <Card
                            sx={{
                              padding: "8px 16px",
                              bgcolor: "#fefefe",
                              border: "2px solid #005251",
                              borderRadius: "10px",
                              boxShadow:
                                "0px 1px 8px rgba(0, 255, 255, 0.6)" /* Adjust the color to match */,
                            }}
                            className="flexCenterCenterHeight"
                          >
                            <Grid container>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Name
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {item["OriginalData"].NAME}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={2}>
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Memebership Card No
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {item["OriginalData"].MEMBERSCARDNO}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={2}>
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Designation
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {item["OriginalData"].DESIGNATION}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={2}>
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Company
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {item["OriginalData"].COMPANYNAME}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={12} md={2} lg={2}>
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Mobile No
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {item["OriginalData"].MOBILENO}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={12} md={3} lg={3}>
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Email
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {item["OriginalData"].EMAIL}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={4}
                                lg={4}
                                sx={{ mt: 2 }}
                              >
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Website
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {item["OriginalData"].WEBSITE}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={4}
                                lg={4}
                                sx={{ mt: 2 }}
                              >
                                <div className="gridStart">
                                  <Typography
                                    align="left"
                                    className="InterRegular font_size16 color-636363"
                                    gutterBottom
                                  >
                                    Address
                                  </Typography>
                                  <Typography
                                    align="left"
                                    className="InterMedium font_size18 color-161616"
                                  >
                                    {item["OriginalData"].ADDRESS}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                sx={{ mt: 2 }}
                              >
                                <div className="flexEndCenterHeight">
                                  {item["OriginalData"].STATUS >= "5" && (
                                    <Button
                                      variant="contained"
                                      size="small"
                                      sx={{
                                        pl: 2,
                                        pr: 2,
                                        mr: 1,
                                        textTransform: "capitalize",
                                        bgcolor: "#fab005",
                                        border: "1px solid #df9d05",
                                        "&:hover": {
                                          bgcolor: "#e6a504", // Slightly darker shade
                                          opacity: 0.8, // Decrease opacity
                                        },
                                      }}
                                      onClick={() =>
                                        handleMembershipCardDetailsEdit(item)
                                      }
                                    >
                                      <EditNoteOutlinedIcon sx={{ mr: 0.5 }} />{" "}
                                      Edit
                                    </Button>
                                  )}
                                  {/* {item["OriginalData"].STATUS >= "5" && (
                                  <Button
                                    variant="contained"
                                    size="small"
                                    sx={{
                                      pl: 2,
                                      pr: 2,
                                      ml: 1,
                                      textTransform: "capitalize",
                                      bgcolor: "#fab005",
                                      border: "1px solid #df9d05",
                                      "&:hover": {
                                        bgcolor: "#e6a504", // Slightly darker shade
                                        opacity: 0.8, // Decrease opacity
                                      },
                                    }}
                                    onClick={() =>
                                      handleMembershipCardPreview(
                                        item["OriginalData"]
                                      )
                                    }
                                  >
                                    {!preview && (
                                      <>
                                        <VisibilityIcon sx={{ mr: 0.5 }} />{" "}
                                        Preview
                                      </>
                                    )}
                                    {preview && (
                                      <>
                                        <VisibilityOffIcon sx={{ mr: 0.5 }} />{" "}
                                        Hide Preview
                                      </>
                                    )}
                                  </Button>
                                )} */}
                                  {/* {item["OriginalData"].STATUS === "5" && (
                                  <Button
                                    variant="contained"
                                    size="small"
                                    sx={{
                                      pl: 2,
                                      pr: 2,
                                      ml: 1,
                                      textTransform: "capitalize",
                                      bgcolor: "#fab005",
                                      border: "1px solid #df9d05",
                                      "&:hover": {
                                        bgcolor: "#e6a504", // Slightly darker shade
                                        opacity: 0.8, // Decrease opacity
                                      },
                                    }}
                                    onClick={() =>
                                      handleMembershipCardQRcodeGenerate(
                                        item["OriginalData"]
                                      )
                                    }
                                  >
                                    {!preview && (
                                      <>
                                        <VisibilityIcon sx={{ mr: 0.5 }} />{" "}
                                        Generate QRcode
                                      </>
                                    )}
                                    {preview && (
                                      <>
                                        <VisibilityOffIcon sx={{ mr: 0.5 }} />{" "}
                                        Hide Preview
                                      </>
                                    )}
                                  </Button>
                                )} */}
                                </div>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      )}
                  </>
                ))}
              </Grid>
            </Grid>
          </Box>
          {/* {preview && (
            <Box sx={{ ml: 2, mr: 2, mt: 6 }} data-aos="zoom-in-down">
              <Grid container justifyContent={"center"}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <div className="gridCenter">
                    <Typography
                      align="center"
                      className="InterBold font_size18 color-616161"
                      sx={{ mb: 4 }}
                    >
                      Membership Card Preview
                    </Typography>
                    <ImageWithBorders PreviewFileURL={previewDet} />
                  </div>
                </Grid>
              </Grid>
            </Box>
          )} */}
          {enquiryCardDet.length !== 0 && IDCardStatus >= "5" && (
            <Box sx={{ ml: 2, mr: 2, mt: 6 }}>
              <Grid container justifyContent={"center"}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className="flexCenterCenterHeight">
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        pl: 5,
                        pr: 5,
                        textTransform: "capitalize",
                        bgcolor: "#fab005",
                        border: "1px solid #df9d05",
                        "&:hover": {
                          bgcolor: "#e6a504", // Slightly darker shade
                          opacity: 0.8, // Decrease opacity
                        },
                      }}
                      onClick={handleSendForPrint}
                    >
                      <PrintOutlinedIcon sx={{ mr: 0.5 }} /> {"Re-Print"}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      )}
      {enquiryCardEditDialog && (
        <EditEnquiryCardMember
          BusinessCardDetails={enquiryCardEditDet}
          onClose={handleEnquiryCardEditDialogClose}
          onOk={handleEnquiryCardEditResponse}
          PageName="Reprint"
        />
      )}
      {/* {addBusinessCardDialog && (
        <AddBusinessCard
          EnquiryCardID={location.state.params.ENQUIRYID}
          onClose={handleEnquiryCardEditDialogClose}
          onOk={handleEnquiryCardEditResponse}
        />
      )} */}

      <BootstrapDialog
        onClose={handleAlreadyPickedDialogClose}
        aria-labelledby="customized-dialog-title"
        open={alreadyPickedDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent style={{ padding: "0px" }}>
          <Card
            sx={{
              border: "2px solid #bee3ff",
              bgcolor: "#f0f8ff",
              padding: "32px 16px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  align="center"
                  className="InterMedium font_size18 color-161616"
                >
                  This enquiry has been picked already.<br></br> Try another
                  unpicked enquiry.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                <div className="flexCenterCenterHeight">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleAlreadyPickedDialogClose}
                    sx={{
                      pl: 5,
                      pr: 5,
                      textTransform: "capitalize",
                      bgcolor: "#fab005",
                      border: "1px solid #df9d05",
                      "&:hover": {
                        bgcolor: "#e6a504", // Slightly darker shade
                        opacity: 0.8, // Decrease opacity
                      },
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Card>
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        onClose={handlePrintPreviewDialogClose}
        aria-labelledby="customized-dialog-title"
        open={printPreview}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent style={{ padding: "0px" }}>
          <Card
            sx={{
              border: "2px solid #bee3ff",
              bgcolor: "#f0f8ff",
              padding: "32px 16px",
            }}
          >
            <Grid container spacing={2}>
              {reprintMemberDetails.length !== 0 && (
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                  <Card
                    sx={{
                      padding: "8px 16px",
                      bgcolor: "#fefefe",
                      border: "2px solid #005251",
                      borderRadius: "10px",
                      boxShadow:
                        "0px 1px 8px rgba(0, 255, 255, 0.6)" /* Adjust the color to match */,
                    }}
                    className="flexCenterCenterHeight"
                  >
                    <Grid container>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <div className="gridStart">
                          <Typography
                            align="left"
                            className="InterRegular font_size16 color-636363"
                            gutterBottom
                          >
                            Name
                          </Typography>
                          <Typography
                            align="left"
                            className="InterMedium font_size18 color-161616"
                          >
                            {reprintMemberDetails[0].NAME}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <div className="gridStart">
                          <Typography
                            align="left"
                            className="InterRegular font_size16 color-636363"
                            gutterBottom
                          >
                            Memebership Card No
                          </Typography>
                          <Typography
                            align="left"
                            className="InterMedium font_size18 color-161616"
                          >
                            {reprintMemberDetails[0].MEMBERSCARDNO}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <div className="gridStart">
                          <Typography
                            align="left"
                            className="InterRegular font_size16 color-636363"
                            gutterBottom
                          >
                            Designation
                          </Typography>
                          <Typography
                            align="left"
                            className="InterMedium font_size18 color-161616"
                          >
                            {reprintMemberDetails[0].DESIGNATION}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <div className="gridStart">
                          <Typography
                            align="left"
                            className="InterRegular font_size16 color-636363"
                            gutterBottom
                          >
                            Company
                          </Typography>
                          <Typography
                            align="left"
                            className="InterMedium font_size18 color-161616"
                          >
                            {reprintMemberDetails[0].COMPANYNAME}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <div className="gridStart">
                          <Typography
                            align="left"
                            className="InterRegular font_size16 color-636363"
                            gutterBottom
                          >
                            Mobile No
                          </Typography>
                          <Typography
                            align="left"
                            className="InterMedium font_size18 color-161616"
                          >
                            {reprintMemberDetails[0].MOBILENO}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3} sx={{ mt: 2 }}>
                        <div className="gridStart">
                          <Typography
                            align="left"
                            className="InterRegular font_size16 color-636363"
                            gutterBottom
                          >
                            Email
                          </Typography>
                          <Typography
                            align="left"
                            className="InterMedium font_size18 color-161616"
                          >
                            {reprintMemberDetails[0].EMAIL}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} sx={{ mt: 2 }}>
                        <div className="gridStart">
                          <Typography
                            align="left"
                            className="InterRegular font_size16 color-636363"
                            gutterBottom
                          >
                            Website
                          </Typography>
                          <Typography
                            align="left"
                            className="InterMedium font_size18 color-161616"
                          >
                            {reprintMemberDetails[0].WEBSITE}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={5} lg={5} sx={{ mt: 2 }}>
                        <div className="gridStart">
                          <Typography
                            align="left"
                            className="InterRegular font_size16 color-636363"
                            gutterBottom
                          >
                            Address
                          </Typography>
                          <Typography
                            align="left"
                            className="InterMedium font_size18 color-161616"
                          >
                            {reprintMemberDetails[0].ADDRESS}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                <div className="flexCenterCenterHeight">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handlePrintPreviewDialogClose}
                    sx={{
                      pl: 5,
                      pr: 5,
                      textTransform: "capitalize",
                      bgcolor: "#fab005",
                      border: "1px solid #df9d05",
                      "&:hover": {
                        bgcolor: "#e6a504", // Slightly darker shade
                        opacity: 0.8, // Decrease opacity
                      },
                    }}
                  >
                    Ok
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Card>
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        onClose={handleViewCommentDialogClose}
        aria-labelledby="customized-dialog-title"
        open={viewCommentDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          <Typography
            align="center"
            className="InterBold font_size18 color-616161"
            sx={{ mb: 2, textDecoration: "underline" }}
          >
            Enquiry Comment List ({enquiryCommentCount})
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {enquiryCommentsDet.length !== 0 &&
                enquiryCommentsDet.map((elem, ind) => (
                  <Card
                    sx={{
                      mb: 2,
                      mt: 2,
                      padding: "8px 16px",
                      bgcolor: "#fefefe",
                      border: "2px solid #005251",
                      borderRadius: "10px",
                      boxShadow:
                        "0px 1px 8px rgba(0, 255, 255, 0.6)" /* Adjust the color to match */,
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} sm={12} md={1} lg={1}>
                        <div className="gridStart">
                          <Typography
                            align="left"
                            className="InterRegular font_size16 color-636363"
                            gutterBottom
                          >
                            Sl No
                          </Typography>
                          <Typography
                            align="left"
                            className="InterMedium font_size18 color-161616"
                          >
                            {ind + 1}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={5} lg={5}>
                        <div className="gridStart">
                          <Typography
                            align="left"
                            className="InterRegular font_size16 color-636363"
                            gutterBottom
                          >
                            Enquiry Comment
                          </Typography>
                          <Typography
                            align="left"
                            className="InterMedium font_size18 color-161616"
                          >
                            {elem.COMMENTS}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <div className="gridStart">
                          <Typography
                            align="left"
                            className="InterRegular font_size16 color-636363"
                            gutterBottom
                          >
                            Comment By
                          </Typography>
                          <Typography
                            align="left"
                            className="InterMedium font_size18 color-161616"
                          >
                            {elem.EMPLOYEENAME}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <div className="gridStart">
                          <Typography
                            align="left"
                            className="InterRegular font_size16 color-636363"
                            gutterBottom
                          >
                            Commented On
                          </Typography>
                          <Typography
                            align="left"
                            className="InterMedium font_size18 color-161616"
                          >
                            {moment(elem.CREATEDTIME).format("DD MMM hh:mm a")}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              {enquiryCommentsDet.length === 0 && (
                <Typography
                  align="center"
                  sx={{ mb: 2 }}
                  className="InterMedium font_size18 color-161616"
                >
                  No comments found
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container sx={{ mb: 2, mt: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="flexCenterCenterHeight">
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleViewCommentDialogClose}
                  sx={{
                    pl: 5,
                    pr: 5,
                    textTransform: "capitalize",
                    bgcolor: "#fab005",
                    border: "1px solid #df9d05",
                    "&:hover": {
                      bgcolor: "#e6a504", // Slightly darker shade
                      opacity: 0.8, // Decrease opacity
                    },
                  }}
                >
                  Ok
                </Button>
              </div>
            </Grid>
          </Grid>
        </DialogActions>
      </BootstrapDialog>

      <BootstrapDialog
        onClose={handleViewCommentDialogClose}
        aria-labelledby="customized-dialog-title"
        open={addCommentDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Typography
            align="center"
            className="InterBold font_size18 color-616161"
            sx={{ mb: 2, textDecoration: "underline" }}
          >
            Add Enquiry Comment
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                multiline
                rows={5}
                variant="outlined"
                fullWidth
                placeholder="Enter your enquiry comments"
                value={enquiryComment}
                onChange={(e) => setEnquiryComment(e.target.value)}
                inputProps={{
                  maxLength: 500, // Limit to 500 characters
                }}
                InputProps={{
                  sx: {
                    color: "Black", // Text color inside the input
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#000000",
                    },
                    "&:hover fieldset": {
                      borderColor: "#000000",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#000000",
                    },
                    backgroundColor: "transparent",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 4, mb: 2 }}>
              <div className="flexCenterCenterHeight">
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    pl: 2,
                    pr: 2,
                    ml: 2,
                    textTransform: "capitalize",
                    bgcolor: "#fab005",
                    border: "1px solid #df9d05",
                    "&:hover": {
                      bgcolor: "#e6a504", // Slightly darker shade
                      opacity: 0.8, // Decrease opacity
                    },
                  }}
                  onClick={handleAddEnquirySave}
                >
                  Add Comment
                </Button>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
      <BootstrapDialog
        onClose={handleCourtesyDialogClose}
        aria-labelledby="customized-dialog-title"
        open={courtesyVisit}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Typography
            align="center"
            className="InterBold font_size18 color-616161"
            sx={{ mb: 2, textDecoration: "underline" }}
          >
            Courtesy Visit Update.
          </Typography>
        </DialogTitle>
        <DialogContent>
          Are you sure you want to update this courtesy Visit for this member?.
        </DialogContent>
        <DialogActions>
          <div className="flexCenterCenterHeight">
            <Button variant="contained"
              size="large"
              sx={{
                pl: 2,
                pr: 2,
                ml: 2,
                textTransform: "capitalize",
                bgcolor: "#fab005",
                border: "1px solid #df9d05",
                "&:hover": {
                  bgcolor: "#e6a504", // Slightly darker shade
                  opacity: 0.8, // Decrease opacity
                },
              }}
              onClick={() => handleCourtesyVisitUpdate()}
            >
              Yes
            </Button>
            <Button variant="contained"
              size="large"
              sx={{
                pl: 2,
                pr: 2,
                ml: 2,
                textTransform: "capitalize",
                bgcolor: "#fab005",
                border: "1px solid #df9d05",
                "&:hover": {
                  bgcolor: "#e6a504", // Slightly darker shade
                  opacity: 0.8, // Decrease opacity
                },
              }}
              onClick={() => handleCourtesyDialogClose()}
            >
              No
            </Button>
          </div>
        </DialogActions>
      </BootstrapDialog>

      <Backdrop className="useStylesBackDrop" open={loadingCommon}>
        <CustomSpinner />
      </Backdrop>
      <Backdrop className="useStylesBackDrop" open={afterLoadingCommon}>
        <CustomSpinner />
      </Backdrop>
    </div>
  );
}

export default EnquiryFromCardDetailsRePrint;
